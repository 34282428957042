import {
  Checklist,
  Delete,
  Edit,
  HowToReg,
  KeyboardDoubleArrowDown,
  RemoveRedEye,
  Visibility,
} from "@mui/icons-material";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE_DEFAULT, ROLES } from "../../constants/constants";
import { cancelEvent, validateEvent } from "../../context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import { mapDateToRightFormat } from "../../utils/date";
import { TQueryEvent } from "../../utils/schemas/event";
import Button from "../button/Button";
import { toastMessageError, toastMessageSuccess } from "../common/toastMessage";
import CustomDialog from "../customDialog/CustomDialog";
import DumpResultsDialog from "../dumpResultsDialog/DumpResultsDialog";
import "./event-card.scss";
import StocksTable from "./stocksTable/StocksTable";

type Props = {
  event: TQueryEvent;
  refetch: () => void;
};

const EventCard = ({ event, refetch }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  const showIcon = event.status === "success" || event.status === "closed";
  const [openValidateEvent, setOpenValidateEvent] = useState<boolean>(false);
  const [openResultsDialog, setOpenResultsDialog] = useState<boolean>(false);
  const [openCancelEvent, setOpenCancelEvent] = useState<boolean>(false);

  const handleOpenCancelEvent = () => {
    setOpenCancelEvent(true);
  };

  const handleCloseCancelEvent = () => {
    setOpenCancelEvent(false);
  };

  const handleCloseResultsDialog = () => {
    setOpenResultsDialog(false);
  };

  const eventStatus =
    event.status === "validated"
      ? "validado"
      : event.status === "decline"
      ? "rechazado"
      : event.status === "pending"
      ? "pendiente"
      : "";

  const handleListButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/inscripciones/${event.id}`);
  };
  const handleManageButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/events/${event.id}`);
  };

  const handleSeeButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/event/${event.id}`);
  };

  const handleCancelEvent = async () => {
    const cancelResponse = await dispatch(cancelEvent(event.id));
    if (cancelResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Evento dado de baja correctamente");
      refetch();
    } else if (cancelResponse.type.includes("rejected")) {
      toastMessageError("Error al dar de baja el evento ");
    }
    handleCloseCancelEvent();
  };

  const handleOpenValidateEvent = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenValidateEvent(true);
  };

  const handleCloseValidateEvent = () => {
    setOpenValidateEvent(false);
  };

  const handleValidateEvent = async () => {
    const validateResponse = await dispatch(validateEvent(event.id));
    if (validateResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Evento validado correctamente");
    } else if (validateResponse.type.includes("rejected")) {
      toastMessageSuccess("Error al validar el evento");
    }
    setOpenValidateEvent(false);
    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const inscriptionsPercentage = Math.round(
    (Math.min(event.inscriptions, event.maxCapacity || 0) * 100) / event.maxCapacity || 0
  );

  const canEditEvent = () => {
    if (userInfo?.roles.includes(ROLES.SUPER_ADMIN)) return true;
    const roles = userInfo?.roles.includes(ROLES.CLUB) || userInfo?.roles.includes(ROLES.FEDERATION);
    const isOrganizer = userInfo?.id === event.organizatorId;
    return roles || isOrganizer;
  };

  const canPublishResults = () => {
    if (userInfo?.roles.includes(ROLES.SUPER_ADMIN || ROLES.REFEJYDA_IT)) return true;
    const roles = userInfo?.roles.includes(ROLES.CLUB || ROLES.FEDERATION);
    const isOrganizer = userInfo?.id === event.organizatorId;
    return roles || isOrganizer;
  };

  return (
    <Paper elevation={4} className="eventcard-container">
      <Box className="eventcard-image-container">
        <img className="eventcard-image" src={event.posterUrl || IMAGE_DEFAULT} alt={`${event.name} - imagen`} />
      </Box>
      <Box className="eventcard-details-container">
        <Box className="eventcard-name-container">
          <Typography className="eventcard-name">{event?.name}</Typography>
          <Box className="eventcard-status-container">
            {showIcon && <Visibility className="eventcard-status-icon" />}
            <Typography className="eventcard-status">{eventStatus}</Typography>
          </Box>
        </Box>
        <Box className="eventcard-info-container">
          <Typography className="eventcard-info">Organizador: {event.organizatorName}</Typography>
          <br />
          <Typography className="eventcard-info">
            {mapDateToRightFormat(event?.startDate)}
          </Typography>
          <Box className="eventcard-inscriptions-container">
            <Box className="eventcard-inscriptions">
              <Typography className="eventcard-info">
                Inscritos {event?.inscriptions}/{event?.maxCapacity ?? 0}
              </Typography>
              <Typography className="eventcard-info">{inscriptionsPercentage}%</Typography>
            </Box>
            <LinearProgress
              className="eventcard-progress"
              variant="determinate"
              value={inscriptionsPercentage}
            />
          </Box>
          {event.stocks && event.stocks.length > 0 ? (
            <Box>
              <Typography className="eventcard-info">Stock: </Typography>
              <StocksTable stocks={event.stocks} />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className="eventcard-buttons-container">
        {" "}
        <Button
          className="eventcard-buttons"
          rounded="md"
          startIcon={<Checklist />}
          onClick={handleListButtonClick}
        >
          INSCRIPCIONES
        </Button>
        {canEditEvent() && (
          <Button
            className="eventcard-buttons"
            rounded="md"
            startIcon={<Edit />}
            onClick={handleManageButtonClick}
          >
            ADMINISTRAR
          </Button>
        )}
        <Button
          className="eventcard-buttons"
          rounded="md"
          startIcon={<RemoveRedEye />}
          onClick={handleSeeButtonClick}
        >
          VER EVENTO
        </Button>
        {canPublishResults() && (
          <Button
            className="eventcard-buttons"
            rounded="md"
            startIcon={<KeyboardDoubleArrowDown />}
            onClick={() => setOpenResultsDialog(true)}
          >
            VOLCAR RESULTADOS
          </Button>
        )}
        {(userInfo?.roles.includes(ROLES.SUPER_ADMIN) ||
          userInfo?.roles.includes(ROLES.REFEJYDA)) && (
          <Button
            className="eventcard-buttons cancel"
            rounded="md"
            startIcon={<Delete />}
            onClick={handleOpenCancelEvent}
          >
            DAR DE BAJA
          </Button>
        )}
        {(userInfo?.roles.includes(ROLES.SUPER_ADMIN) ||
          userInfo?.roles.includes(ROLES.REFEJYDA)) &&
          event.status === "pending" && (
            <Button
              className="eventcard-buttons"
              rounded="md"
              startIcon={<HowToReg />}
              onClick={handleOpenValidateEvent}
            >
              Validar evento
            </Button>
          )}
      </Box>
      <CustomDialog
        title="Validar evento"
        content="¿Estás seguro de que quieres validar este evento?"
        onAccept={handleValidateEvent}
        isOpen={openValidateEvent}
        onClose={handleCloseValidateEvent}
      />
      <CustomDialog
        title="Cancelar evento"
        content="¿Estás seguro de que quieres cancelar este evento?"
        onAccept={handleCancelEvent}
        isOpen={openCancelEvent}
        onClose={handleCloseCancelEvent}
      />
      <DumpResultsDialog
        isOpen={openResultsDialog}
        onClose={handleCloseResultsDialog}
        eventId={event.id}
        eventCode={event.code}
        eventName={event.name}
      />
    </Paper>
  );
};

export default EventCard;
