import { z } from "zod";
import { MESSAGE } from "../../constants/errorMessages";

export type TUserSimple = {
  avatarUrl: string;
  belt: string;
  bornAt: string;
  categoryId: string;
  categoryName: string;
  categoryWeight: string;
  clubId: string;
  email: string;
  federationId: string;
  genre: string;
  id: string;
  isActive: number;
  name: string;
  phone: string;
  surname: string;
  //
  federationName: string;
  clubName: string;
  cnr: string;
  dateBirth: string;
  logoUrl: string;
  lastAccess: string;
  isSportilyActive: number;
};

export type TQueryUsers = {
  data: [TUserSimple];
  page: number;
  perPage: number;
  total: number;
};

export type TUserResponsible = {
  name: string;
  dni: string;
  surname: string;
  email: string;
  familiarRelationship: string;
  phoneNumber: string;
};

export const addUserSchema = z.object({
  //Step 1
  name: z.string().min(1, MESSAGE.REQUIRED).max(255, { message: MESSAGE.MAX_255 }),
  bornAt: z.string().optional(),
  surname: z.string().min(1, MESSAGE.REQUIRED).max(255, MESSAGE.MAX_255),
  identityNumber: z.string().max(255, MESSAGE.MAX_255).optional(),
  nationality: z.string().max(255, MESSAGE.MAX_255).optional(),
  currentCategoryId: z.string().max(100, MESSAGE.MAX_100).optional(),
  category: z
    .object({
      categoryGenre: z.string().optional(),
      categoryAge: z.string().optional(),
      categoryId: z.string().optional(),
      categoryName: z.string().optional(),
      categoryWeight: z.string().optional(),
      categorySport: z.string().optional(),
    })
    .optional(),
  genre: z.string().max(255, MESSAGE.MAX_255).optional(),
  address: z
    .object({
      address: z.string().optional(),
      countryCode: z.string().optional(),
      autonomyCode: z.string().optional(),
      provinceCode: z.string().optional(),
      municipalityCode: z.string().optional(),
      postalCode: z.string().optional(),
    })
    .optional(),
  phone: z.string(),
  email: z.string().max(255, MESSAGE.MAX_255).email(MESSAGE.EMAIL),
  responsibles: z
    .array(
      z
        .object({
          name: z.string().optional(),
          dni: z.string().optional(),
          surname: z.string().optional(),
          email: z.string().optional(),
          familiarRelationship: z.string().optional(),
          phone: z.string().optional(),
          id: z.string().optional(),
        })
        .optional()
    )
    .optional(),
  //Solo admin
  roleIds: z.array(z.string().optional()).optional(),

  //Step 2
  federationId: z.string().max(255, MESSAGE.MAX_255).optional(),
  clubId: z.string().max(255, MESSAGE.MAX_255).optional().nullable(),
  currentBeltId: z.string().max(100, MESSAGE.MAX_100).optional().nullable(),
  cnr: z.string().max(255, MESSAGE.MAX_255).optional().nullable(),
  coachType: z.string().optional().nullable(),
  refereeType: z.string().optional().nullable(),
  avatarUrl: z.string().optional().nullable(),
  allergy: z.string().max(100, MESSAGE.MAX_100).optional().nullable(),
  profession: z.string().max(255, MESSAGE.MAX_255).optional().nullable(),
});

export type TUser = z.infer<typeof addUserSchema>;

export type TUserSheet = {
  userInfo: {
    age: number;
    genre: string;
    name: string;
    surname: string;
    belt_name: string;
    club_code: string;
    federation_code: string;
    federation_id: string;
    club_id: string;
    avatar_url: string;
  };
  resultsData: {
    sport: string;
    belt: string;
    club_code: string;
    weights: string[];
  }[];
};
