import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { FilterState } from "../utils/schemas/listFilters";

export const getClubs = async ({
  page,
  perPage,
  search,
  autonomy,
  province,
  federationId,
}: FilterState) => {
  try {
    const params = new URLSearchParams();
    if (page) params.append("page", page.toString());
    if (perPage) params.append("perPage", perPage.toString());
    if (search && search !== "") params.append("search", search.toString());
    if (autonomy && autonomy !== "") params.append("autonomy", autonomy.toString());
    if (province && province !== "") params.append("province", province.toString());
    if (federationId && federationId !== "") params.append("federationId", federationId.toString());

    const { data } = await http.get(`${API_URL}${SERVICE.CLUBS}?${params}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export async function getClub(clubId: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.CLUBS}/${clubId}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}

export const getClubsList = async (federationId: string) => {
  try {
    const params = new URLSearchParams();
    if (federationId && federationId !== "") params.append("federationId", federationId);

    const { data } = await http.get(`${API_URL}${SERVICE.CLUBS_LIST}?${params}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const checkShortCodeClub = async (shortCode: string) => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.CLUBS}/short-code-available/${shortCode}`);
    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};
