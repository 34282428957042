import { Badge, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InscriptionsValidated from "../../components/inscriptions/inscriptionsValidated/InscriptionValidated";
import useSetPageName from "../../utils/hooks/useSetPageName";
import "./inscriptionEvent.scss";

import CreateInscription from "../../components/inscriptions/createInscription/CreateInscription";
import InscriptionsPending from "../../components/inscriptions/inscriptionsPending/InscriptionsPending";
import InscriptionsRejected from "../../components/inscriptions/inscriptionsRejected/InscriptionsRejected";
import { setFilter } from "../../context/filters/filterSlice";
import { useAppDispatch } from "../../context/hooks/hooks";
import useInscriptionFilters from "../../utils/hooks/useInscriptionsFilters";
import useQueryClubsList from "../../utils/hooks/useQueryClubList";
import useQueryEventId from "../../utils/hooks/useQueryEventById";
import useQueryFederationsList from "../../utils/hooks/useQueryFederationsList";
import {
  useQueryInscriptionsPending,
  useQueryInscriptionsRejected,
  useQueryInscriptionsValidated,
} from "../../utils/hooks/useQueryInscriptions";
import { FilterState } from "../../utils/schemas/listFilters";

const InscriptionEvent = () => {
  useSetPageName([
    { name: "Inscripciones", path: "/gestor_eventos" },
    { name: "Lista inscritos", path: "/inscripciones/:id" },
  ]);
  const { id: eventId = "" } = useParams();
  const [valueTab, setValueTab] = useState(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };
  //Get info from the event
  const { eventData, activate } = useQueryEventId(eventId);

  useEffect(() => {
    activate();
    updateApprovedFilter("eventId", eventId);
    updatePendingFilter("eventId", eventId);
    updateRejectedFilter("eventId", eventId);
  }, []);

  //Inscriptions filters
  const {
    pendingFilters,
    approvedFilters,
    rejectedFilters,
    updateApprovedFilter,
    updatePendingFilter,
    updateRejectedFilter,
  } = useInscriptionFilters();

  //Get inscriptions
  const { data: inscriptionsPending, refetch: refetchPending } =
    useQueryInscriptionsPending(pendingFilters);
  const { data: inscriptionsValidated, refetch: refetchValidated } =
    useQueryInscriptionsValidated(approvedFilters);
  const { data: inscriptionsRejected } = useQueryInscriptionsRejected(rejectedFilters);

  //Get clubs and federations
  const { data: clubs } = useQueryClubsList(pendingFilters.federationId || "");
  const { data: federations } = useQueryFederationsList();

  //Users call
  const dispatch = useAppDispatch();
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };

  //Check unique club or federation
  useEffect(() => {
    if (clubs?.length === 1) {
      updateApprovedFilter("clubId", clubs[0].id);
      updatePendingFilter("clubId", clubs[0].id);
      updateRejectedFilter("clubId", clubs[0].id);
      handleFilterChange(clubs[0].id, "clubId");
    }
    if (federations?.length === 1) {
      updateApprovedFilter("federationId", federations[0].id);
      updatePendingFilter("federationId", federations[0].id);
      updateRejectedFilter("federationId", federations[0].id);
      handleFilterChange(federations[0].id, "federationId");
    }
  }, [clubs, federations]);

  return (
    <>
      <Tabs
        value={valueTab}
        onChange={handleChangeTab}
        allowScrollButtonsMobile
        className="inscriptions-tabs"
        TabIndicatorProps={{
          className: "inscriptions-tab-indicator",
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className="inscriptions-tab"
          label={
            <Badge
              badgeContent={totalUsers}
              color="primary"
              className="users-badge-count"
              showZero
              max={9999}
            >
              Crear inscripción
            </Badge>
          }
        />
        <Tab
          className="inscriptions-tab"
          label={
            <Badge
              badgeContent={inscriptionsPending?.total}
              className="users-badge-count"
              color="primary"
              showZero
              max={9999}
            >
              Pendientes de validar
            </Badge>
          }
        />
        <Tab
          className="inscriptions-tab"
          label={
            <Badge
              badgeContent={inscriptionsValidated?.total}
              className="users-badge-count"
              color="primary"
              showZero
              max={9999}
            >
              Inscritos validados
            </Badge>
          }
        />
        <Tab
          className="inscriptions-tab"
          label={
            <Badge
              badgeContent={inscriptionsRejected?.total}
              className="users-badge-count"
              color="primary"
              showZero
              max={9999}
            >
              Inscritos Rechazados
            </Badge>
          }
        />
      </Tabs>
      {valueTab === 0 ? (
        <CreateInscription
          eventId={eventId}
          stockCategories={eventData?.stocks}
          refetchInscriptions={refetchPending}
          clubs={clubs}
          federations={federations}
          setTotalUsers={setTotalUsers}
        />
      ) : valueTab === 1 ? (
        <InscriptionsPending
          stockCategories={eventData?.stocks}
          inscriptions={inscriptionsPending}
          refetchInscriptions={refetchPending}
          updatePendingFilter={updatePendingFilter}
          pendingFilters={pendingFilters}
          clubs={clubs}
          federations={federations}
          changeDateLimit={eventData?.weighingChangeLimitDate}
        />
      ) : valueTab === 2 ? (
        <InscriptionsValidated
          inscriptions={inscriptionsValidated}
          refetchInscriptions={refetchValidated}
          stockCategories={eventData?.stocks}
          updateApprovedFilter={updateApprovedFilter}
          filterApproved={approvedFilters}
          clubs={clubs}
          federations={federations}
          changeDateLimit={eventData?.weighingChangeLimitDate}
        />
      ) : (
        <InscriptionsRejected
          inscriptions={inscriptionsRejected}
          updateRejectedFilter={updateRejectedFilter}
          filterRejected={rejectedFilters}
          clubs={clubs}
          federations={federations}
        />
      )}
    </>
  );
};

export default InscriptionEvent;
