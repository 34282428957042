import { Search } from "@mui/icons-material";
import { FormControl, Grid, IconButton } from "@mui/material";
import { GENRES_OPTIONS } from "../../../../constants/constants";
import useDebounce from "../../../../utils/hooks/useDebouncing";
import { TClubList } from "../../../../utils/schemas/club";
import { TFederationsList } from "../../../../utils/schemas/federations";
import { TInscriptionFilters } from "../../../../utils/schemas/listFilters";
import SelectInput from "../../../selectInput/SelectInput";
import TextInput from "../../../textInput/TextInput";
import "./validated-filters.scss";

type Props = {
  updateApprovedFilter: (key: keyof TInscriptionFilters, value: string) => void;
  filterApproved: TInscriptionFilters;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
};

const ValidatedFilters = ({ updateApprovedFilter, filterApproved, clubs, federations }: Props) => {
  //Search optimization
  const debounceSearch = useDebounce((value) => updateApprovedFilter("search", value), 500);

  return (
    <Grid
      item
      container
      className="validated-filters-container"
      columnSpacing={2}
      rowSpacing={2}
      xs={12}
    >
      <Grid className="validated-search-box" item>
        <IconButton>
          <Search className="inscriptions-header-searchInput-icon" />
        </IconButton>
        <TextInput
          placeholder="Buscar"
          minWidth="full-width"
          onChange={(e) => debounceSearch(e.target.value)}
        />
      </Grid>
      {federations && federations.length > 1 ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={filterApproved.federationId}
              rounded="md"
              onChange={(e) => updateApprovedFilter("federationId", e.target.value as string)}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Federación" },
                ...(federations?.map((federation) => ({
                  value: federation.id,
                  label: federation.name,
                })) || []),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      {clubs && clubs.length > 1 ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={filterApproved.clubId}
              rounded="md"
              onChange={(e) => updateApprovedFilter("clubId", e.target.value as string)}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Club" },
                ...(clubs?.map((club) => ({
                  value: club.id,
                  label: club.name,
                })) || []),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item>
        <FormControl>
          <SelectInput
            value={filterApproved.genre}
            rounded="md"
            onChange={(e) => updateApprovedFilter("genre", e.target.value as string)}
            displayEmpty
            defaultValue=""
            options={GENRES_OPTIONS}
          ></SelectInput>
        </FormControl>
      </Grid>
      {/* filtros para añadir:
             categoryCode: "",
      */}
    </Grid>
  );
};

export default ValidatedFilters;
