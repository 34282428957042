import { toastMessageError } from "../components/common/toastMessage";
import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function uploadImage(body: { image: string; mime: string }) {
  try {
    const { data } = await http.post(`${SERVICE.IMAGE}`, body);
    return data;
  } catch (error) {
    toastMessageError("Error al subir la imagen");
  }
}
