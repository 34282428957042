import { RemoveRedEye, Search } from "@mui/icons-material";
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Loading from "../../components/loader/Loading";
import SelectInput from "../../components/selectInput/SelectInput";
import TextInput from "../../components/textInput/TextInput";
import { resetFilters, setFilter } from "../../context/filters/filterSlice";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import useDebounce from "../../utils/hooks/useDebouncing";
import useQueryCenso from "../../utils/hooks/useQueryCenso";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { FilterState } from "../../utils/schemas/listFilters";

import ArrowButtons from "../../components/common/arrowButtons/ArrowButtons";
import { toastMessageSuccess } from "../../components/common/toastMessage";
import DialogImage from "../../components/dialogImg64/DialogImage";
import { validateUserCenso } from "../../services/users";
import { mapDateToEventFormat } from "../../utils/date";
import "./mail-vote.scss";
import CustomDialog from "../../components/customDialog/CustomDialog";
import { TUserCenso } from "../../utils/schemas/censo";

const MailVote = () => {
  useSetPageName([{ name: "Votación por correo", path: "/voto-correo" }]);
  const { provinces } = useAppSelector((state) => state.formsOptions);
  const [seeImage, setSeeImage] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [selectedField, setSelectedField] = useState<keyof TUserCenso | null>(null);
  const [openValidate, setOpenValidate] = useState<boolean>(false);

  //Filtros
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };
  //Limpiamos los filtros
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  //Search optimization
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  const { data, isLoading, isRefetching, refetch } = useQueryCenso(filterState);
  const users = data?.data;

  const handleCheckBox = async (id: string, field: keyof TUserCenso) => {
    setOpenValidate(true);
    setSelectedUser(id);
    setSelectedField(field);
  };

  const handleSeeImage = (e: any, id: string) => {
    e.preventDefault();
    setSeeImage(true);
    setSelectedUser(id);
  };

  const handleValidateUser = async () => {
    if (selectedField) {
      const validateResponse = await validateUserCenso(selectedUser, selectedField);
      if (validateResponse) {
        toastMessageSuccess("Actualizado correctamente");
        refetch();
      } else {
        toastMessageSuccess("Error al actualizar el campo");
      }
      setOpenValidate(false);
    }

  };

  return (
    <Grid container mt={5}>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={11} sm={3} container flexDirection="row">
          <Grid item xs={1} sm={2}>
            <IconButton>
              <Search />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <TextInput
              placeholder="Buscar"
              minWidth="full-width"
              onChange={(e) => debounceSearch(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9} container justifyContent="flex-end" gap={2}>
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Provincia: Todas"
            options={[
              { value: "", label: "Provincia: Todas" },
              ...(provinces && provinces.length > 0
                ? provinces.map((province) => ({
                  value: province.name,
                  label: province.name,
                }))
                : []),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "province")}
            value={filterState.province}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Procesados"
            options={[
              { value: "", label: "Estado: Todas" },
              { value: "true", label: "Solo procesados" },
              { value: "false", label: "Sin procesar" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "checked")}
            value={filterState.checked}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Duplicados"
            options={[
              { value: "", label: "Estado: Todas" },
              { value: "true", label: "Solo duplicados" },
              { value: "false", label: "Sin duplicar" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "duplicate")}
            value={filterState.duplicate}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Subsanado"
            options={[
              { value: "", label: "Estado: Todas" },
              { value: "true", label: "Subsanados" },
              { value: "false", label: "Sin subsanar" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "subsanado")}
            value={filterState.subsanado}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Dato erroneo"
            options={[
              { value: "", label: "Estado: Todas" },
              { value: "true", label: "Solo con datos erroneos" },
              { value: "false", label: "Sin datos erroneos" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "dato_erroneo")}
            value={filterState.dato_erroneo}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Consentimientos"
            options={[
              { value: "", label: "Estado: Todas" },
              { value: "true", label: "Sin consentimiento" },
              { value: "false", label: "Con consentimiento" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "en_consentimientos")}
            value={filterState.en_consentimientos}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Rechazados"
            options={[
              { value: "", label: "Estado: Todas" },
              { value: "true", label: "Solo Rechazado" },
              { value: "false", label: "Quitar los rechazados" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "rechazado")}
            value={filterState.rechazado}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 4000]}
          component="div"
          count={data?.total || 0}
          rowsPerPage={data?.perPage || 10}
          page={data?.page ? data?.page - 1 : 1}
          onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
          onRowsPerPageChange={(e) => {
            handleFilterChange(e.target.value, "perPage");
            handleFilterChange(1, "page");
          }}
          labelRowsPerPage="Usuarios por página"
        />
      </Grid>
      {users && users?.length > 0 ? (
        <>
          <Typography mb={2} width="100%">
            Usuarios totales: {data.total ?? 0}
          </Typography>
          <Grid container>
            <Grid elevation={4} sx={{ borderRadius: 4 }} component={Paper} item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow className="user-no-wrap">
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          Nombre
                          <ArrowButtons
                            id="name"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          Apellidos
                          <ArrowButtons
                            id="surname1"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          Email
                          <ArrowButtons
                            id="email"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          CNR
                          <ArrowButtons
                            id="cnr"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          NIF
                          <ArrowButtons
                            id="nif"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          Estamento
                          <ArrowButtons
                            id="estamento"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell style={{ padding: "8px" }}>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          Deporte
                          <ArrowButtons
                            id="sport"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          Solicitado
                          <ArrowButtons
                            id="created_at"
                            filterState={filterState}
                            handleFilterChange={handleFilterChange}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          flexDirection="row"
                          wrap="nowrap"
                          alignItems="center"
                          columnGap={1}
                          whiteSpace="nowrap"
                        >
                          <Tooltip title="Procesado por RFEJYDA" placement="top" arrow>
                            <p>Procesado</p>
                          </Tooltip>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isRefetching || isLoading ? (
                      <Loading />
                    ) : (
                      users?.map((user) => (
                        <TableRow key={user.id} sx={{ whiteSpace: "nowrap" }}>
                          <TableCell style={{ textTransform: 'uppercase', fontSize: '11px' }}>{user.name}</TableCell>
                          <TableCell style={{ textTransform: 'uppercase', fontSize: '11px' }}>{`${user.surname1} ${user.surname2 ?? ""}`}</TableCell>
                          <TableCell style={{ textTransform: 'lowercase', fontSize: '12px' }}>{user.email}</TableCell>
                          <TableCell style={{ textTransform: 'uppercase', fontSize: '11px' }}>{user.cnr}</TableCell>
                          <TableCell style={{ textTransform: 'uppercase', fontSize: '11px' }}>{user.nif}</TableCell>
                          <TableCell style={{ textTransform: 'uppercase', fontSize: '11px' }}>{user.estamento}</TableCell>
                          <TableCell style={{ textTransform: 'uppercase', fontSize: '11px' }}>{user.sport}</TableCell>
                          <TableCell>
                            {user.created_at ? mapDateToEventFormat(user.created_at) : ""}
                          </TableCell>

                          <TableCell>
                            <Tooltip title="Registro duplicado" placement="top" arrow>
                              <Checkbox
                                checked={!!user.duplicate}
                                onChange={() => handleCheckBox(user.id, 'duplicate')}
                                className="checkbox-duplicate"
                              />
                            </Tooltip>

                            <Tooltip title="Registro subsanado" placement="top" arrow>

                              <Checkbox
                                checked={!!user.subsanado}
                                onChange={() => handleCheckBox(user.id, 'subsanado')}
                                className="checkbox-subsanado"
                              />
                            </Tooltip>
                            <Tooltip title="Registro con dato erroneo" placement="top" arrow>
                              <Checkbox
                                checked={!!user.dato_erroneo}
                                onChange={() => handleCheckBox(user.id, 'dato_erroneo')}
                                className="checkbox-dato-erroneo"
                              />
                            </Tooltip>
                            <Tooltip title="Registro no está en consentimientos" placement="top" arrow>
                              <Checkbox
                                checked={!!user.en_consentimientos}
                                onChange={() => handleCheckBox(user.id, 'en_consentimientos')}
                                className="checkbox-en-consentimientos"
                              />
                            </Tooltip>
                            <Tooltip title="Registro rechazado" placement="top" arrow>
                              <Checkbox
                                checked={!!user.rechazado}
                                onChange={() => handleCheckBox(user.id, 'rechazado')}
                                className="checkbox-rechazado"
                              />
                            </Tooltip>
                            <Tooltip title="Registro procesado por completo" placement="top" arrow>
                              <Checkbox
                                checked={!!user.checked_by_rfejyda}
                                onChange={() => handleCheckBox(user.id, 'checked_by_rfejyda')}
                                disabled={!!user.checked_by_rfejyda}
                                className="checkbox-accept"
                              /></Tooltip>
                            <Tooltip title="Ver imagen" placement="top" arrow>
                              <IconButton
                                className="icon-button-accept"
                                onClick={(e) => handleSeeImage(e, user.id)}
                              >
                                <RemoveRedEye fontSize="inherit" className="" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid >
        </>
      ) : (
        <Grid item xs={12} container justifyContent="center">
          <p>No se encontraron resultados</p>
        </Grid>
      )}
      <Grid container justifyContent="center" mt={2}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data?.total || 0}
          rowsPerPage={data?.perPage || 10}
          page={data?.page ? data?.page - 1 : 1}
          onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
          onRowsPerPageChange={(e) => {
            handleFilterChange(e.target.value, "perPage");
            handleFilterChange(1, "page");
          }}
          labelRowsPerPage="Usuarios por página"
        />
      </Grid>
      <DialogImage isOpen={seeImage} onClose={() => setSeeImage(false)} id={selectedUser} />
      <CustomDialog
        isOpen={openValidate}
        onClose={() => setOpenValidate(false)}
        title="Marcar como enviado"
        content="¿Estás seguro de que quieres marcar este usuario como enviado?"
        onAccept={handleValidateUser}
      />
    </Grid >
  );
};
export default MailVote;
