const BASE_API_URL =
  process.env.NEXT_PUBLIC_BASE_API_URL ?? "https://63571hrcdf.execute-api.eu-west-1.amazonaws.com";

const APP_URL = "/prod/v1";
const S3_URL = "/api/s3";

export const API_URL = `${BASE_API_URL}`;
export const SALT = "$2a$10$9477qIFU5ZgiVoMyxWCrU.";

export const SERVICE = {
  BASE: APP_URL,
  USERS: `${APP_URL}/users`,
  ADMINS: `${APP_URL}/users/admins`,
  IMAGES: `${APP_URL}/images`,
  PREMISES: `${APP_URL}/premises`,
  COUNTRIES: `${APP_URL}/utils/countries`,
  CITIES_UTILS: `${APP_URL}/utils/cities`,
  TICKETS: `${APP_URL}/tickets`,
  EXTRAS: `${APP_URL}/extras`,
  ORDERS: `${APP_URL}/orders`,
  FAVORITES: `${APP_URL}/favorites`,
  PAYMENTS: `${APP_URL}/payments`,
  AUTH: `${APP_URL}/users/login`,
  STUDENT: `${APP_URL}/student`,
  CITIES: `${APP_URL}/cities`,
  LANGUAGES: `${APP_URL}/languages`,
  EVENTS: `${APP_URL}/events`,
  BUY_TICKET: `${APP_URL}/buy_ticket`,
  REQUEST_CARD: `${APP_URL}/request_card`,
  CONSUME_TICKET: `${APP_URL}/consume_ticket`,
  SUBMIT_DOCUMENTATION: `${APP_URL}/submit_documentation`,
  CARDS: `${APP_URL}/cards`,
  INSCRIPTIONS: `${APP_URL}/inscriptions`,
  FORGET_PASSWORD: `${APP_URL}/users/forgot-password`,
  RESET_PASSWORD: `${APP_URL}/users/reset-password`,
  RESULTS: `${APP_URL}/results`,
  RANKINGS: `${APP_URL}/rankings`,

  STUDENT_PHOTO: `${S3_URL}/student_profile`,
  All_LISTS: `${APP_URL}/utils/all-lists`,
  CLUBS: `${APP_URL}/clubs`,
  CLUBS_LIST: `${APP_URL}/clubs/list`,
  FEDERATIONS: `${APP_URL}/federations`,
  IMAGE: `${APP_URL}/images`,
};

export const TOKEN_KEY = "token";
