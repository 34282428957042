import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};

const ProgramSchedule = ({ isDisabled }: Props) => {
  const { formState, setValue, clearErrors, getValues } = useFormContext<TEvent>();
  const { errors } = formState;

  const [schedules, setSchedules] = useState<
    { date: Date | null; time: string | null; description?: string }[]
  >([]);

  useEffect(() => {
    const savedSchedule = getValues("programSchedules");
    if (savedSchedule) {
      const initialDaysSelected =
        typeof savedSchedule === "string" ? JSON.parse(savedSchedule) : savedSchedule;
      setSchedules(initialDaysSelected);
    }
  }, [getValues]);

  const handleSwitchChange = (e: boolean) => {
    if (e) {
      setSchedules([{ date: null, time: null, description: "" }]);
    } else {
      setSchedules([]);
      clearErrors("programSchedules");
    }
  };

  const handleSetDate = (index: number, value: Date | null) => {
    setValue(`programSchedules.${index}.date`, value ?? null);
    setSchedules((schedules) => {
      schedules[index].date = value;
      return schedules;
    });
  };

  const handleSetTime = (index: number, value: string | null) => {
    setValue(`programSchedules.${index}.time`, value ?? "");
    setSchedules((schedules) => {
      schedules[index].time = value;
      return schedules;
    });
  };

  const handleSetDescription = (index: number, value: string) => {
    const newSchedules = [...schedules];
    newSchedules[index].description = value;
    setSchedules(newSchedules);
    setValue(`programSchedules.${index}.description`, value);
  };

  const handleAddSchedule = () => {
    setSchedules((prevSchedules) => {
      const newSchedules = [...prevSchedules];
      newSchedules.push({ date: null, time: null, description: "" });
      return newSchedules;
    });
  };

  const handleRemoveSchedule = () => {
    setSchedules((prevSchedules) => {
      if (prevSchedules.length === 1) {
        return [{ date: null, time: null, description: "" }];
      } else {
        const newSchedules = [...prevSchedules];
        newSchedules.pop();
        return newSchedules;
      }
    });
  };

  return (
    <Box className="form-section-container">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography className="form-section-title">Cronograma de programa</Typography>
        <Switch
          onChange={(e) => handleSwitchChange(e.target.checked)}
          className="form-switch"
          checked={schedules.length > 0 || false}
          disabled={isDisabled}
        />
      </Stack>
      {schedules.map((_, index) => {
        return (
          <Grid
            container
            columnSpacing={4}
            rowGap={{ xs: 2, md: 4 }}
            sx={{ my: 2 }}
            alignItems="center"
            key={`schedules_${index}`}
          >
            <Grid item md={3} xs={6}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Fecha*</Typography>
                <DatePicker
                  className="form-date-picker"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  value={schedules[index].date ? dayjs(schedules[index].date) : null}
                  onChange={(value: Dayjs | null) => handleSetDate(index, value?.toDate() ?? null)}
                  readOnly={isDisabled}
                />
                {errors?.programSchedules?.[index]?.date && (
                  <Typography className="form-input-error">
                    {errors?.programSchedules?.[index]?.date?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Horario*</Typography>
                <TimePicker
                  className="form-date-picker"
                  format="HH:mm"
                  ampm={false}
                  views={["hours", "minutes"]}
                  value={schedules[index].time ? dayjs(schedules[index].time) : null}
                  onChange={(value: Dayjs | null) =>
                    handleSetTime(index, value?.toString() ?? null)
                  }
                  readOnly={isDisabled}
                />
                {errors?.programSchedules?.[index]?.time && (
                  <Typography className="form-input-error">
                    {errors?.programSchedules?.[index]?.time?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={6}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Descripción*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Descripción*"
                  error={!!errors?.programSchedules?.[index]?.description}
                  value={schedules[index].description}
                  onChange={(e) => handleSetDescription(index, e.target.value)}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.programSchedules?.[index]?.description && (
                  <Typography className="form-input-error">
                    {errors?.programSchedules?.[index]?.description?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3} xs={6}>
              {index === schedules.length - 1 && (
                <Stack
                  direction="row"
                  width="100px"
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                  flexWrap="nowrap"
                >
                  <IconButton onClick={handleAddSchedule} disabled={isDisabled}>
                    <AddCircleOutline />
                  </IconButton>

                  <IconButton onClick={handleRemoveSchedule} disabled={isDisabled}>
                    <RemoveCircleOutline />
                  </IconButton>
                </Stack>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default ProgramSchedule;
