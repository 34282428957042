import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Fragment, useState } from "react";
import Button from "../../button/Button";

import { useNavigate } from "react-router-dom";
import "./type-dialog.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const EVENT_TYPES = [
  { value: "simple", label: "Evento simple" },
  { value: "complete", label: "Evento completo" },
];

function TypeDialog({ isOpen, onClose }: Props) {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<string>("");

  const handleAccept = () => {
    if (selectedType === "complete") {
      navigate("/gestor_eventos/agregar");
    } else if (selectedType === "simple") {
      navigate("/gestor_eventos/simple");
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="dialog-title">Tipo de evento</DialogTitle>
      <DialogContent className="dialog-content">
        <RadioGroup
          className="dialog-radio-group"
          name="radio-buttons-group"
          row
          value={selectedType}
          defaultValue=""
          onChange={(e) => setSelectedType(e.target.value)}
        >
          {EVENT_TYPES.map((eventType, index) => (
            <Fragment key={index}>
              <FormControlLabel
                key={eventType.value}
                className="dialog-radio-label"
                value={eventType.value}
                control={<Radio className="check-radio-red" />}
                label={eventType.label}
              />
              {index !== EVENT_TYPES.length - 1 && <Divider />}
            </Fragment>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button type="submit" className="button-cancel" onClick={onClose}>
          CANCELAR
        </Button>
        <Button
          type="submit"
          className="button-accept"
          onClick={handleAccept}
          disabled={selectedType === ""}
        >
          EMPEZAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TypeDialog;
