import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import { Box, Stack, Switch, Typography } from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};

const Picnic = ({ isDisabled }: Props) => {
  const { setValue, watch } = useFormContext<TEvent>();

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("picnic", e.target.checked);
  };

  const includesPicnic = watch("picnic");

  return (
    <Box className="form-section-container">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography className="form-section-title">Incluye Picnic</Typography>
        <Switch
          onChange={handleSwitchChange}
          className="form-switch"
          checked={includesPicnic || false}
          disabled={isDisabled}
        />
      </Stack>
    </Box>
  );
};

export default Picnic;
