import "./login.scss";

import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

import { AlternateEmail, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Button from "../../components/button/Button";
import { loginUser } from "../../context/auth/authActions";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";

const Login = () => {
  const formSchema = z.object({
    email: z.string().email({ message: "Ingrese un email valido" }),
    password: z.string().min(3, { message: "Ingrese una contraseña valida" }),
    // .regex(/^(?=.*[A-Z])(?=.*[-!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/, {
    //   message: "La contraseña no cumple los requisitos",
    // }),
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors({});
    const formData = new FormData(event.currentTarget);
    let email = formData.get("email")?.toString();
    let password = formData.get("password")?.toString();

    const data = { email, password };
    try {
      const validatedData = formSchema.parse(data);
      const response = await dispatch(loginUser(validatedData));
      if (typeof response.payload === "string") {
        setErrors({ noValid: response.payload });
      } else {
        navigate("/");
      }
    } catch (error: any) {
      if (error instanceof z.ZodError) {
        if (error instanceof z.ZodError) {
          const errors: Record<string, string> = {};
          for (const issue of error.issues) {
            if (issue.path[0]) {
              errors[issue.path[0]] = issue.message;
            }
          }
          setErrors(errors);
        }
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: "url(images/background/imagen_back_rfejyda.png)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100%",
        }}
      />
      <Grid item xs={12} sm={8} md={4}>
        <Box className="login-container">
          <Container className="login-logo" />

          <Typography className="login-title" component="h1" variant="h5">
            Inicia Sesión en tu cuenta
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            className="login-form"
          >
            <Stack gap={3}>
              <TextField
                className="text-field login-text-field"
                name="email"
                id="email"
                autoComplete="email"
                autoFocus
                variant="outlined"
                placeholder="Email*"
                error={!!errors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmail />
                    </InputAdornment>
                  ),
                }}
              />

              <Stack>
                <TextField
                  className="text-field login-text-field"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  placeholder="Contraseña*"
                  error={!!errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  className="login-checkbox-container"
                  control={<Checkbox value="remember" className="login-checkbox" />}
                  label="Mantener sesión iniciada"
                />
              </Stack>
            </Stack>
            <Stack alignItems="center" sx={{ mt: 4 }}>
              {Object.values(errors).map((error, i) => (
                <Typography key={`error-${i}`} className="login-error-text" component="p">
                  {error}
                </Typography>
              ))}
              <Button
                width="full-width"
                size="large"
                type="submit"
                sx={{ mt: 3, mb: 1 }}
                className="login-button-login"
                disabled={loading}
              >
                {loading ? "Cargando" : "LOG IN"}
              </Button>
              <MuiLink
                component={Link}
                to="/forget-password"
                variant="body2"
                className="login-forget-text"
              >
                ¿Olvidaste tu Contraseña?
              </MuiLink>
            </Stack>
            <Stack className="login-logos-container">
              <img
                src="images/logos/EU_financiation.png"
                alt="Imagen Financiado por la Unión Europea"
                className="login-logo-sponsors"
              />
              <img
                src="images/logos/Plan_recuperacion.png"
                alt="Plan de recuperación, Transformación y Resiliencia"
                className="login-logo-sponsors"
              />
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
