import {
  Edit,
  Hail,
  InsertInvitation,
  ManageAccounts,
  RemoveRedEye,
  SportsKabaddi,
} from "@mui/icons-material";
import {
  Box,
  Paper,
  Popover,
  Stack,
  Typography,
  Button as ButtonMui,
  ButtonGroup,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { TClubSimple } from "../../utils/schemas/club";
import Button from "../button/Button";
import "./club-card.scss";
import CreateUsersDialog from "../usersCsv/usersDialog/CreateUsersDialog";

export type ClubCardProps = {
  club: TClubSimple;
};

const ClubCard = ({ club }: ClubCardProps) => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.auth);
  const [openMultiUserDialog, setOpenMultiUserDialog] = useState(false);

  const clubsDetails = [
    {
      name: "Deportistas",
      data: club.deportistas,
      icon: <SportsKabaddi className="clubcard-icon" />,
      link: `/gestor_usuarios?club=${club.id}`,
    },
    {
      name: "Entrenadores",
      data: club.entrenadores,
      icon: <Hail className="clubcard-icon" />,
      link: `/gestor_usuarios?club=${club.id}`,
    },
    {
      name: "Eventos",
      data: club.events,
      icon: <InsertInvitation className="clubcard-icon" />,
      link: `/gestor_eventos?club=${club.id}`,
    },
  ];

  const handleAddUserButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenMultiUserDialog(true);
  };

  const handleIndividualUser = () => {
    navigate(`/users/agregar?clubId=${club.id}`);
  };
  const handleEditButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/clubs/${club.id}/editar`);
  };
  const handleSeeClub = (event: MouseEvent) => {
    event.stopPropagation();
    navigate(`/clubs/${club.id}/ver`);
  };

  const canEditClub = () => {
    if (userInfo?.roles.includes(ROLES.SUPER_ADMIN)) return true;
    const roles = userInfo?.roles.includes(ROLES.CLUB) || userInfo?.roles.includes(ROLES.FEDERATION);
    const clubId = userInfo?.clubId === club.id;
    return roles && clubId;
  };

  const handleNavigate = (link: string) => {
    navigate(link);
  };

  /* popper */
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePop = (e: MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popperId = openPopover ? "simple-popover" : undefined;

  return (
    <Paper elevation={4} className="clubcard-container">
      <Box className="clubcard-info-container">
        <Typography className="clubcard-name">{club.name}</Typography>
        <Stack className="clubcard-info-details-container">
          <Typography className="clubcard-info-details">Email: {club.email}</Typography>
          <Typography className="clubcard-info-details">Ciudad: {club.cityName}</Typography>
        </Stack>
      </Box>
      <Box className="clubcard-details-container">
        {clubsDetails.map((club) => {
          return (
            <Box
              key={club.name}
              className="clubcard-detail-container"
              onClick={() => handleNavigate(club.link)}
            >
              <Box className="clubcard-icon-container">{club.icon}</Box>
              <Box className="clubcard-data-container">
                <Typography className="clubcard-data value">{club.data}</Typography>
                <Typography className="clubcard-data">{club.name}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className="clubcard-buttons-container">
        <Button
          className="clubcard-buttons"
          rounded="md"
          startIcon={<ManageAccounts />}
          onClick={handleOpenPopover}
          aria-describedby={popperId}
        >
          AÑADIR DEPORTISTA
        </Button>
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClosePop}
          id={popperId}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ButtonGroup
            variant="contained"
            orientation="vertical"
            aria-label="vertical button group"
          >
            <ButtonMui
              onClick={handleIndividualUser}
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                fontSize: "12px",
                "&:hover": { backgroundColor: "#e7af13", color: "#fff" },
              }}
            >
              Deportista individual
            </ButtonMui>
            <ButtonMui
              onClick={handleAddUserButtonClick}
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                fontSize: "12px",
                "&:hover": { backgroundColor: "#e7af13", color: "#fff" },
              }}
            >
              Agregar desde csv
            </ButtonMui>
          </ButtonGroup>
        </Popover>
        {canEditClub() && (
          <Button
            className="clubcard-buttons"
            rounded="md"
            startIcon={<Edit />}
            onClick={handleEditButtonClick}
          >
            Editar
          </Button>
        )}
        <Button
          className="federationcard-buttons"
          rounded="md"
          startIcon={<RemoveRedEye />}
          onClick={handleSeeClub}
        >
          Ver
        </Button>
      </Box>
      <CreateUsersDialog
        isOpen={openMultiUserDialog}
        onClose={() => setOpenMultiUserDialog(false)}
      />
    </Paper>
  );
};

export default ClubCard;
