import { Add } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Button as MuiButton,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TUser, TUserResponsible } from "../../../../utils/schemas/user";

type Props = {
  isDisabled?: boolean;
};

const MinorRepresentatives = ({ isDisabled }: Props) => {
  const { register, formState, setValue, watch, control } = useFormContext<TUser>();
  const { errors } = formState;
  const [formUsers, setUsers] = useState<TUserResponsible[]>([]);

  const handleAddUser = () => {
    const newResponsible: TUserResponsible = {
      name: "",
      dni: "",
      surname: "",
      email: "",
      familiarRelationship: "",
      phoneNumber: "",
    };
    setUsers((prev) => [...prev, newResponsible]);
  };

  const handleRemoveUser = (index: number) => {
    setUsers((prev) => [...prev].filter((_, i) => i !== index));
    setValue(
      "responsibles",
      formUsers.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    const responsibles = watch("responsibles") as TUserResponsible[];
    setUsers(responsibles || []);
  }, [watch("responsibles")]);

  return (
    <>
      <Paper className="form-paper" sx={{ p: { md: 5, xs: 1 }, mb: 2 }}>
        <Typography className="form-section-title representative-title">
          Datos del Representante del Menor
        </Typography>

        {formUsers.map((_, i) => {
          return (
            <Box key={i}>
              <Grid container item xs={12} justifyContent="flex-end">
                <IconButton onClick={() => handleRemoveUser(i)} disabled={isDisabled}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
                <Grid item sm={12} container columnSpacing={3}>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="form-input-label">Representante*</Typography>
                      <Controller
                        name={`responsibles.${i}.familiarRelationship`}
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <Select
                            className="input-select form-select-field"
                            error={!!errors?.responsibles?.[i]?.familiarRelationship}
                            {...field}
                            displayEmpty
                            defaultValue={""}
                            slotProps={{ input: { readOnly: isDisabled } }}
                          >
                            <MenuItem value={""}>
                              <em>Representante</em>
                            </MenuItem>
                            <MenuItem value={"madre"}>Madre</MenuItem>
                            <MenuItem value={"padre"}>Padre</MenuItem>
                            <MenuItem value={"tutor legal"}>Tutor legal</MenuItem>
                          </Select>
                        )}
                      />
                      {errors?.responsibles?.[i]?.familiarRelationship && (
                        <Typography className="form-input-error">
                          {errors?.responsibles?.[i]?.familiarRelationship?.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Nombre*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Nombre"
                      error={!!errors?.responsibles?.[i]?.name}
                      {...register(`responsibles.${i}.name`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsibles?.[i]?.name && (
                      <Typography className="form-input-error">
                        {errors?.responsibles?.[i]?.name?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">DNI*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="DNI"
                      error={!!errors?.responsibles?.[i]?.dni}
                      {...register(`responsibles.${i}.dni`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsibles?.[i]?.dni && (
                      <Typography className="form-input-error">
                        {errors?.responsibles?.[i]?.dni?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Apellidos*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Apellidos"
                      error={!!errors?.responsibles?.[i]?.surname}
                      {...register(`responsibles.${i}.surname`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsibles?.[i]?.surname && (
                      <Typography className="form-input-error">
                        {errors?.responsibles?.[i]?.surname?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
                <Grid item md={6} xs={12} container columnSpacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography className="form-input-label">Teléfono*</Typography>
                      <TextField
                        className="input-text form-text-field"
                        variant="outlined"
                        placeholder="Teléfono*"
                        error={!!errors?.responsibles?.[i]?.phone}
                        {...register(`responsibles.${i}.phone`)}
                        InputProps={{ readOnly: isDisabled }}
                      />
                      {errors?.responsibles?.[i]?.phone && (
                        <Typography className="form-input-error">
                          {errors?.responsibles?.[i]?.phone?.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Email*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Email*"
                      error={!!errors?.responsibles?.[i]?.email}
                      {...register(`responsibles.${i}.email`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsibles?.[i]?.email && (
                      <Typography className="form-input-error">
                        {errors?.responsibles?.[i]?.email?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Paper>
      {formUsers.length < 3 && (
        <Paper className="form-paper-button">
          <MuiButton
            className="form-button-add-representative"
            startIcon={<Add />}
            onClick={() => handleAddUser()}
            fullWidth
            disabled={isDisabled}
          >
            Añadir {formUsers.length === 0 ? "un" : "otro"} Representante del Menor
          </MuiButton>
        </Paper>
      )}
    </>
  );
};

export default MinorRepresentatives;
