import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Add, Cancel } from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { TClub } from "../../../../utils/schemas/club";
import Button from "../../../button/Button";
import ImageUpload from "../../../imageUpload/ImageUpload";
import BoardMemberCard from "./BoardMemberCard";

const initialState = {
  avatar: "",
  name: "",
  lastName: "",
  position: "",
};

type Props = {
  isDisabled?: boolean;
};

const BoardMembers = ({ isDisabled }: Props) => {
  const { setValue, getValues } = useFormContext<TClub>();
  const members = getValues("boardMembers") ?? [];
  const [showNewMember, setShowNewMember] = useState(!Boolean(members?.length));
  const [newBoardMember, setNewBoardMember] = useState<{
    avatar: string;
    name: string;
    lastName: string;
    position: string;
  }>(initialState);
  const [boardMembers, setBoardMembers] = useState<
    {
      avatar: string;
      name: string;
      lastName: string;
      position: string;
    }[]
  >([]);
  const [newMemberErrors, setNewMemberErrors] = useState<Record<string, boolean>>({});

  const handleAddNewBoardMember = () => {
    setShowNewMember(true);
    setNewBoardMember(initialState);
  };

  const handleSetNewMember = () => {
    setNewMemberErrors({});
    const errors: Record<string, boolean> = {};
    if (newBoardMember !== null) {
      if (!newBoardMember.position) errors.position = true;

      if (!newBoardMember.name) errors.name = true;
      if (!newBoardMember.lastName) errors.lastName = true;
      if (errors && !Object.keys(errors).length) {
        setValue("boardMembers", [...members, newBoardMember]);
        setBoardMembers((prev) => [...prev, newBoardMember]);
        setShowNewMember(false);
      } else {
        setNewMemberErrors(errors);
      }
    }
  };

  const handleCloseNewMember = () => {
    setShowNewMember(false);
    setNewBoardMember(initialState);
    setNewMemberErrors({});
  };

  return (
    <Box className="form-section-container">
      <Stack direction="row" gap={3} justifyContent="space-between" flexWrap="wrap">
        <Typography className="form-section-title">Organigrama de la Junta</Typography>
        <Button
          className="form-button small-text"
          width="fit-content"
          startIcon={<Add />}
          onClick={handleAddNewBoardMember}
          disabled={isDisabled}
        >
          AGREGAR NUEVO USUARIO
        </Button>
      </Stack>

      <Stack direction="column" gap={3} justifyContent="flex-start" my={3}>
        {boardMembers?.map((member, i) => (
          <BoardMemberCard key={`${member.name} ${member.lastName} - ${i}`} index={i} />
        ))}
      </Stack>
      <Stack direction="row">
        {showNewMember && (
          <Paper className="form-card-container" elevation={3}>
            <Stack direction="column" gap={3} justifyContent="space-between">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography className="form-card-label">Añadir Persona</Typography>
                <IconButton
                  className="form-card-label"
                  onClick={handleCloseNewMember}
                  disabled={isDisabled}
                >
                  <Cancel />
                </IconButton>
              </Stack>

              <Grid container columnSpacing={3} rowSpacing={3} alignItems="center">
                <Grid item md={2} xs={12}>
                  <ImageUpload
                    onImageSelect={(e) =>
                      typeof e === "string" && setNewBoardMember((prev) => ({ ...prev, avatar: e }))
                    }
                    url={newBoardMember.avatar}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item md={2.5} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Puesto*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      placeholder="Puesto"
                      value={newBoardMember.position}
                      error={newMemberErrors.position}
                      onChange={(e) =>
                        setNewBoardMember((member) => ({
                          ...member,
                          position: e.target.value,
                        }))
                      }
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {newMemberErrors.position && (
                      <Typography className="form-input-error">Agregue un puesto</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={2.5} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Nombre*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      placeholder="Nombre"
                      value={newBoardMember.name}
                      error={newMemberErrors.name}
                      onChange={(e) =>
                        setNewBoardMember((member) => ({
                          ...member,
                          name: e.target.value,
                        }))
                      }
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {newMemberErrors.name && (
                      <Typography className="form-input-error">Agregue un nombre</Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={2.5} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Apellido*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      placeholder="Apellido"
                      value={newBoardMember.lastName}
                      error={newMemberErrors.lastName}
                      onChange={(e) =>
                        setNewBoardMember((member) => ({
                          ...member,
                          lastName: e.target.value,
                        }))
                      }
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {newMemberErrors.lastName && (
                      <Typography className="form-input-error">Agregue un apellido</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={2.5} xs={12}>
                  <Typography className="form-input-label"></Typography>
                  <Button
                    className="form-button small-text"
                    width="full-width"
                    onClick={handleSetNewMember}
                    disabled={isDisabled}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
        )}
      </Stack>
    </Box>
  );
};

export default BoardMembers;
