import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../button/Button";
import "./customDialog.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string;
  onAccept: () => void;
  isLoading?: boolean;
};

function CustomDialog({ isOpen, onClose, title, content, onAccept, isLoading }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-text">{content}</DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button type="submit" className="button-cancel" onClick={onClose}>
          CANCELAR
        </Button>
        <Button type="submit" className="button-accept" onClick={onAccept} disabled={isLoading}>
          {isLoading ? "Cargando..." : "ACEPTAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
