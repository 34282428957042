import { createSlice } from "@reduxjs/toolkit";

type InitialStateType = {
  pageName: { name: string; path: string }[];
};

const initialState: InitialStateType = {
  pageName: [{ name: "Inicio", path: "/" }],
};

const pageNameSlice = createSlice({
  name: "pageName",
  initialState,
  reducers: {
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },
  },
});

export const { setPageName } = pageNameSlice.actions;

export default pageNameSlice.reducer;
