import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import { Box, Stack, Switch, Typography } from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";
import ImageUpload from "../../../imageUpload/ImageUpload";

type Props = {
  isDisabled?: boolean;
};

const Sponsors = ({ isDisabled }: Props) => {
  const { setValue, watch } = useFormContext<TEvent>();

  const sponsor = watch("sponsors");
  const sponsorsUrl = watch("sponsorsUrl");

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("sponsors", e.target.checked);
    } else {
      setValue("sponsors", e.target.checked);
    }
  };

  return (
    <Box className="form-section-container">
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
        marginBottom={2}
      >
        <Typography className="form-section-title">Sponsors</Typography>
        <Switch
          onChange={handleSwitchChange}
          className="form-switch"
          checked={sponsor || false}
          disabled={isDisabled}
        />
      </Stack>
      {sponsor && (
        <Stack>
          <ImageUpload
            url={sponsorsUrl || ""}
            multi={false}
            onImageSelect={(e) =>
              typeof e === "string" ? setValue("sponsorsUrl", e) : setValue("sponsorsUrl", "")
            }
            disabled={isDisabled}
          />
        </Stack>
      )}
    </Box>
  );
};

export default Sponsors;
