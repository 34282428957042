import { useState } from "react";
import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getEventById } from "../../services/events";
import { TEvent } from "../schemas/event";

export default function useQueryEventId(id: string = "") {
  const [isActive, setIsActive] = useState(false);

  const eventData = useQuery<TEvent, Error>([RQ_KEY.EVENT, id], () => getEventById(id), {
    enabled: isActive && id !== null,
  });

  const activate = () => {
    setIsActive(true);
  };

  const { data, isLoading, isRefetching } = eventData;

  return {
    eventData: data,
    activate,
    isLoading,
    isRefetching,
  };
}
