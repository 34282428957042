import "./schedule.scss";

import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { AddFederationSchemaType } from "../../../../utils/schemas/federations";
import Button from "../../../button/Button";

const days = [
  { value: "Lunes", label: "L", order: 1 },
  { value: "Martes", label: "M", order: 2 },
  { value: "Miércoles", label: "X", order: 3 },
  { value: "Jueves", label: "J", order: 4 },
  { value: "Viernes", label: "V", order: 5 },
  { value: "Sábado", label: "S", order: 6 },
  { value: "Domingo", label: "D", order: 7 },
];

type Props = {
  isDisabled?: boolean;
};

const ScheduleFederation = ({ isDisabled }: Props) => {
  const { setValue, getValues } = useFormContext<AddFederationSchemaType>();

  const [daysSelected, setDaysSelected] = useState<
    Record<string, { start: string; end: string }[]>
  >({});

  useEffect(() => {
    const savedSchedule = getValues("timetable");
    if (savedSchedule) {
      const initialDaysSelected =
        typeof savedSchedule === "string" ? JSON.parse(savedSchedule) : savedSchedule;
      setDaysSelected(initialDaysSelected);
    }
  }, [getValues]);

  const handleSelectDay = (day: string) => {
    if (daysSelected[day]) {
      const removedDay = { ...daysSelected };
      delete removedDay[day];
      setDaysSelected(removedDay);
    } else {
      setDaysSelected((prev) => ({
        ...prev,
        [day]: [{ start: "", end: "" }],
      }));
    }
  };

  const handleSetTime = (day: string, index: number, position: "start" | "end", value: string) => {
    const newTimes = [...daysSelected[day]];
    newTimes[index][position] = value;
    setValue(`timetable.${day}.${index}.${position}`, value);
    setDaysSelected((prev) => ({ ...prev, [day]: newTimes }));
  };

  const handleAddTime = (day: string) => {
    const newTimes = [...daysSelected[day]];
    newTimes.push({ start: "", end: "" });
    setDaysSelected((prev) => ({ ...prev, [day]: newTimes }));
  };

  const handleRemoveTime = (day: string) => {
    const newTimes = [...daysSelected[day]];
    newTimes.pop();
    setDaysSelected((prev) => ({ ...prev, [day]: newTimes }));
  };

  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Horario</Typography>

      <Stack
        direction="row"
        gap={{ md: 3, xs: 1.2 }}
        justifyContent="flex-start"
        my={3}
        maxWidth="100%"
        flexWrap="wrap"
      >
        {days.map((day) => (
          <Button
            key={day.label}
            className={`form-date-button ${!daysSelected[day.value] ? "unselected" : ""}`}
            width="fit-content"
            onClick={() => handleSelectDay(day.value)}
            disabled={isDisabled}
          >
            {day.label}
          </Button>
        ))}
      </Stack>
      {days
        .filter((day) => daysSelected[day.value])
        .sort((a, b) => a.order - b.order)
        .map((day) => {
          return (
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexWrap="wrap"
              my={2}
              key={day.value}
            >
              <Typography>{day.value}</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                gap={5}
                flexWrap="wrap"
              >
                {daysSelected[day.value].map((times, index) => (
                  <Box key={`${day} - ${index}`} className="form-date-picker-container">
                    <TimePicker
                      className="form-date-picker"
                      format="HH:mm"
                      ampm={false}
                      value={daysSelected[day.value][index].start ? dayjs(times?.start) : null}
                      onChange={(value: Dayjs | null) =>
                        handleSetTime(day.value, index, "start", value?.toString() ?? "")
                      }
                      readOnly={isDisabled}
                    />
                    hasta
                    <TimePicker
                      className="form-date-picker"
                      format="HH:mm"
                      ampm={false}
                      value={daysSelected[day.value][index].end ? dayjs(times?.end) : null}
                      minTime={dayjs(times?.start).add(1, "hour")}
                      onChange={(value: Dayjs | null) =>
                        handleSetTime(day.value, index, "end", value?.toString() ?? "")
                      }
                      readOnly={isDisabled}
                    />
                  </Box>
                ))}
                <Stack
                  direction="row"
                  width="100px"
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                  flexWrap="nowrap"
                >
                  {daysSelected[day.value].length < 3 && (
                    <IconButton onClick={() => handleAddTime(day.value)} disabled={isDisabled}>
                      <Add />
                    </IconButton>
                  )}
                  {daysSelected[day.value].length > 1 && (
                    <IconButton onClick={() => handleRemoveTime(day.value)} disabled={isDisabled}>
                      <Remove />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            </Stack>
          );
        })}
    </Box>
  );
};

export default ScheduleFederation;
