import { useState } from "react";
import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getUser } from "../../services/users";
import { TUserSimple } from "../schemas/user";

export default function useQueryUserById(id: string = "") {
  const [isActive, setIsActive] = useState(false);

  const userData = useQuery<TUserSimple, Error>([RQ_KEY.USER, id], () => getUser(id), {
    enabled: isActive && id !== null,
  });

  const activate = () => {
    setIsActive(true);
  };

  return {
    userData,
    activate,
  };
}
