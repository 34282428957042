import "../../../components/formComponents/form-components.scss";
import "./add-federation.scss";

import { zodResolver } from "@hookform/resolvers/zod";
import { Looks3, LooksOne, LooksTwo } from "@mui/icons-material";
import { Box, Container, Paper, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import BasicData from "../../../components/formComponents/createFederation/basicData/BasicData";
import BillingData from "../../../components/formComponents/createFederation/billingData/BillingData";
import ContactData from "../../../components/formComponents/createFederation/contactData/ContactDaata";
import MediaData from "../../../components/formComponents/createFederation/mediaUrls/MediaUrls";
import ScheduleFederation from "../../../components/formComponents/createFederation/schedule/Schedule";

import MapsUrl from "../../../components/formComponents/createFederation/mapsUrl/MapsUrl";
import { sendFederationData, updateFederationData } from "../../../context/forms/formsActions";
import { useAppDispatch } from "../../../context/hooks/hooks";
import useQueryFederationId from "../../../utils/hooks/useQueryFederationId";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import { AddFederationSchemaType, addFederationSchema } from "../../../utils/schemas/federations";

type Props = {
  isDisabled?: boolean;
};

const steps = ["Información general", "Detalles"];

const AddFederation = ({ isDisabled }: Props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  useSetPageName([
    { name: "Federaciones", path: "/federaciones" },
    { name: isEdit ? "Editar Federación" : "Agregar Federación", path: "/federaciones/agregar" },
  ]);
  const navigate = useNavigate();
  //Logic for edit federation
  const { id: federationId = "" } = useParams();
  const { federationData, activate } = useQueryFederationId(federationId);

  const validateStep1 = async () =>
    await formMethods.trigger([
      "name",
      "shortCode",
      "logoUrl",
      "description",
      "address",
      "phone",
      "email",
      "fiscalAddress",
      "fiscalName",
      "iban",
      "cif",
    ]);

  const validateStep2 = async () => await formMethods.trigger(["timetable", "socialMedia"]);

  const handleStep = (step: number) => async () => {
    //Check campos de la parte actual del form están correctos
    setActiveStep(step);
  };

  const CustomStepIcon = ({ active, icon }: { active: boolean; icon: number }) => {
    const stepIcons = [
      <LooksOne className="stepper-icon" />,
      <LooksTwo className="stepper-icon" />,
      <Looks3 className="stepper-icon" />,
    ];

    return <div className={`stepper-step ${active ? "active" : ""} `}>{stepIcons[icon - 1]}</div>;
  };

  useEffect(() => {
    if (federationId && federationId !== "") {
      setIsEdit(true);
      activate();
    }
  }, [federationId]);

  useEffect(() => {
    if (federationData.data) {
      if (!federationData.data.fiscalAddress) {
        federationData.data.isFiscalAddressSameAsAddress = true;
        setValue("isFiscalAddressSameAsAddress", true);
      }
      reset(federationData.data);
    }
  }, [federationData.data]);

  const dispatch = useAppDispatch();

  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("❌Errores federación =>", error);
  };

  const formMethods = useForm<AddFederationSchemaType>({
    mode: "all",
    resolver: zodResolver(addFederationSchema),
  });
  const { handleSubmit, formState, reset, setValue } = formMethods;
  const { errors } = formState;

  const onSubmit: SubmitHandler<AddFederationSchemaType> = async (data) => {
    setLoadingSubmit(true);
    if (isEdit) {
      const updateResponse = await dispatch(updateFederationData({ data: data, id: federationId }));
      if (updateResponse.meta.requestStatus === "fulfilled") {
        toastMessageSuccess("Federación actualizada correctamente");
        setTimeout(() => {
          navigate("/federaciones");
        }, 2000);
      } else {
        toastMessageError(updateResponse.payload ?? "Error al actualizar la federación");
        setLoadingSubmit(false);
      }
    } else {
      const createResponse = await dispatch(sendFederationData(data));
      if (createResponse.meta.requestStatus === "fulfilled") {
        toastMessageSuccess("Federación creada correctamente");
        setTimeout(() => {
          navigate("/federaciones");
        }, 2000);
      } else {
        toastMessageError(createResponse.payload ?? "Error al crear la federación");
        setLoadingSubmit(false);
      }
    }
  };

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleGoBack = () => {
    if (activeStep === 0) {
      navigate("/federaciones");
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextButton = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (!(await validateStep1())) {
        return;
      }
    } else if (activeStep === 1) {
      if (!(await validateStep2())) {
        return;
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setActiveStep(activeStep + 1);
  };

  return (
    <Container className="add-federation-container">
      <Box className="stepper-container">
        <Stepper nonLinear activeStep={activeStep} connector={null}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                className="stepper-step-container"
                onClick={handleStep(index)}
                StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Paper sx={{ p: { md: 5, xs: 1 } }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {activeStep === 0 ? (
              <>
                <BasicData isDisabled={isDisabled} />
                <ContactData isDisabled={isDisabled} />
                <BillingData isDisabled={isDisabled} />
              </>
            ) : (
              <>
                <ScheduleFederation isDisabled={isDisabled} />
                <MapsUrl isDisabled={isDisabled} />
                <MediaData isDisabled={isDisabled} />
              </>
            )}
            {errors && Object.keys(errors).length > 0 ? (
              <Typography className="error-message">*Revise los campos del formulario</Typography>
            ) : null}

            <Stack direction="row" justifyContent="center" gap={5} flexWrap="wrap">
              <Button className="form-button cancel" onClick={handleGoBack}>
                {activeStep === 0 ? "CANCELAR" : "ANTERIOR"}
              </Button>
              {isLastStep() ? (
                isDisabled ? null : (
                  <Button
                    type="submit"
                    className="form-button"
                    disabled={isDisabled || loadingSubmit}
                  >
                    {loadingSubmit ? "Enviando..." : "ENVIAR"}
                  </Button>
                )
              ) : (
                <Button className="form-button" onClick={(event) => handleNextButton(event)}>
                  SIGUIENTE
                </Button>
              )}
            </Stack>
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
};

export default AddFederation;
