import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../constants/axios";
import { API_URL, SERVICE } from "../../constants/api";
import { TInscriptionClub } from "../../utils/schemas/inscriptions";

export const createInscriptions = createAsyncThunk(
  "forms/options",
  async ({ data, role }: { data: TInscriptionClub; role: string }, { rejectWithValue }) => {
    try {
      const request = await http.post(`${API_URL}${SERVICE.INSCRIPTIONS}/${role}`, data);
      const response = await request.data;
      return { ...response };
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);

export const changeCategory = createAsyncThunk(
  "forms/options",
  async ({ data, id }: { data: { newCategoryId: string }; id: string }, { rejectWithValue }) => {
    try {
      const request = await http.put(
        `${API_URL}${SERVICE.INSCRIPTIONS}/change-category/${id}`,
        data
      );
      const response = await request.data;
      return response;
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);
