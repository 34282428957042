import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { CloseOutlined, Download } from "@mui/icons-material";
import { useState } from "react";
import { useAppDispatch } from "../../context/hooks/hooks";
import Button from "../button/Button";
import { toastMessageError, toastMessageSuccess } from "../common/toastMessage";

import { CSVLink } from "react-csv";

import { sendResults } from "../../context/forms/formsActions";
import useDownloadInscriptions from "../../utils/hooks/useDownloadInscriptions";
import { THeadersCsv } from "../../utils/schemas/results";
import CsvReader from "../usersCsv/usersDialog/csvReader/CsvReader";
import "./dump-results.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
  eventCode: string;
  eventName: string;
};

const DumpResultsDialog = ({ isOpen, onClose, eventId, eventCode, eventName }: Props) => {
  const [isFile, setIsFile] = useState<boolean>(false);
  const [csvData, setCSVData] = useState([]);

  const { headersResults, dataToDownload, usersCategoryId } = useDownloadInscriptions(
    isOpen ? eventId : "",
    eventCode
  );

  const dispatch = useAppDispatch();

  const handleSendDump = async () => {
    // Transform the data to send
    const dataToSend = csvData.map((user: THeadersCsv) => {
      const hasWon = user.HaGanadoCombates?.toLowerCase() === "si" ? true : false;
      const userCategoryId = usersCategoryId.find(
        (per) => per.userId === user?.IdUsuario
      )?.categoryId;
      return {
        userId: user.IdUsuario,
        categoryId: userCategoryId,
        position: user.Posicion,
        hasWonAnyCombat: hasWon,
      };
    });
    // Body to send
    const body = {
      eventId: eventId,
      eventCode: eventCode,
      resultsData: dataToSend,
    };
    const response = await dispatch(sendResults(body));
    if (response.type.includes("fulfilled")) {
      toastMessageSuccess("Resultados enviados correctamente");
    } else if (response.type.includes("rejected")) {
      toastMessageError(response.payload ?? "Error al enviar los resultados");
    }
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="dump-dialog-title">
        <Typography className="dump-dialog-title-text">Volcar resultados:</Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined className="dump-dialog-title-icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container className="dump-dialog-container">
          <Grid item xs={6} className="dump-dialog-content">
            <Typography>
              Descarga el archivo CSV con la lista de inscritos al evento y sube el archivo con los
              resultados. Por favor, asegúrate de que los datos son correctos y de no modificar la
              estructura del archivo.
            </Typography>
            <CsvReader setIsFile={setIsFile} setCSVData={setCSVData} />
          </Grid>
          <Grid item xs={6} className="dump-dialog-content">
            <Typography className="user-dialog-content-text">Vista previa:</Typography>
            <img
              src="/images/examples/dump-csv.svg"
              alt="csv preview"
              className="csv-preview-image"
            />
            {/* <Button className="button-accepts" startIcon={<Download />} onClick={handleDownloadCSV}>
              Descargar CSV
            </Button> */}
            <CSVLink
              headers={headersResults}
              data={dataToDownload}
              target="_blank"
              separator={";"}
              filename={`${eventName}-inscriptions.csv`}
              className="dump-download-button"
            >
              <Download /> Descarga inscritos
            </CSVLink>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button type="submit" className="button-cancel" onClick={onClose}>
          CANCELAR
        </Button>
        <Button type="submit" className="button-accept" onClick={handleSendDump} disabled={!isFile}>
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DumpResultsDialog;
