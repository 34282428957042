import { Typography } from "@mui/material";
import { useEffect } from "react";
import AdminsList from "../../components/admins/adminList/AdminsList";
import AdminFilters from "../../components/admins/adminsFilters/AdminsFilters";
import Loading from "../../components/loader/Loading";
import { resetFilters, setFilter } from "../../context/filters/filterSlice";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import useQueryAdmins from "../../utils/hooks/useQueryAdmins";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { FilterState } from "../../utils/schemas/listFilters";
import { useLocation } from "react-router-dom";

const AdminsManager = () => {
  useSetPageName([{ name: "Gestor de Administradores", path: "/gestor_admins" }]);
  const location = useLocation();

  //Filtros
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };
  //Limpiamos los filtros
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  const { data: admins, isLoading, isRefetching, refetch } = useQueryAdmins(filterState);

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const federation = searchParams.get("federation");
      if (federation) {
        handleFilterChange(federation, "federationId");
      }
    }
  }, [location]);

  return (
    <>
      <AdminFilters handleFilterChange={handleFilterChange} filterState={filterState} />
      {isLoading || isRefetching ? (
        <Loading />
      ) : admins?.data && admins.data.length > 0 ? (
        <AdminsList
          admins={admins.data}
          handleFilterChange={handleFilterChange}
          filterState={filterState}
          page={admins.page}
          perPage={admins.perPage}
          total={admins.total}
          refetch={refetch}
        />
      ) : (
        <Typography>No hay admins disponibles</Typography>
      )}
    </>
  );
};

export default AdminsManager;
