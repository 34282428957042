import { Grid, Typography } from "@mui/material";

import "./csvReader.scss";

import { useCSVReader } from "react-papaparse";
import { CloudUpload } from "@mui/icons-material";

type Props = {
  setIsFile: (isFile: boolean) => void;
  setCSVData: (csvData: any) => void;
};

const COLORS = {
  orange: "#e7af13",
  red: "#d42a29",
};

const CsvReader = ({ setIsFile, setCSVData }: Props) => {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        setIsFile(true);
        setCSVData(results.data);
      }}
      onError={() => {
        setIsFile(false);
      }}
      config={{
        header: true,
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
        <>
          <div {...getRootProps()} className="zone">
            {acceptedFile ? (
              <>
                <div className="file">
                  <div className="info">
                    {/* <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span> */}
                    <span className="name">{acceptedFile.name}</span>
                  </div>
                  <div className="progressBar">
                    <ProgressBar style={{ backgroundColor: COLORS.orange }} />
                  </div>
                  <div
                    {...getRemoveFileProps()}
                    className="remove"
                    onClick={(e: Event) => {
                      getRemoveFileProps().onClick(e);
                      setIsFile(false);
                    }}
                  >
                    <Remove className="remove-icon" color={COLORS.red} />
                  </div>
                </div>
              </>
            ) : (
              <Grid container className="drag-drop-container">
                <Typography className="drag-drop-text">
                  Suelta el archivo aquí, o haz click para seleccionar uno.
                </Typography>
                <CloudUpload className="drag-drop-icon" />
              </Grid>
            )}
          </div>
        </>
      )}
    </CSVReader>
  );
};

export default CsvReader;
