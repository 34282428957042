import { useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

import { TClub } from "../../../../utils/schemas/club";

type Props = {
  isDisabled?: boolean;
};

const ContactInfo = ({ isDisabled }: Props) => {
  const { register, formState } = useFormContext<TClub>();
  const { errors } = formState;
  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Datos de contacto</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Email*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Email"
              required
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.email}
              {...register("email")}
            />
            {errors?.email && (
              <Typography className="form-input-error">{errors?.email?.message}</Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Número de teléfono*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Número de teléfono"
              required
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.phone}
              {...register("phone")}
            />
            {errors?.phone && (
              <Typography className="form-input-error">{errors?.phone?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Web</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Dirección Web"
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.webSite}
              {...register("webSite")}
            />
            {errors?.webSite && (
              <Typography className="form-input-error">{errors?.webSite?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInfo;
