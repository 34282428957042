import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import useQueryClubsList from "../../../../utils/hooks/useQueryClubList";
import useQueryFederationsList from "../../../../utils/hooks/useQueryFederationsList";
import { TUser } from "../../../../utils/schemas/user";

type Props = {
  isDisabled?: boolean;
};

const BasicAdminInfo = ({ isDisabled }: Props) => {
  const { register, formState, setValue, watch, control } = useFormContext<TUser>();
  const { errors } = formState;

  const handleSetDate = (value: Date | null) => {
    setValue("bornAt", value?.toDateString() || "");
  };

  const { data: clubs } = useQueryClubsList(watch("federationId") || "");
  const { data: federations } = useQueryFederationsList();

  return (
    <Box className="form-section-container">
      <Typography className="add-user-form-title">Datos del usuario</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        {/* federaciones y clubs */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Federación</Typography>
            <Controller
              control={control}
              name="federationId"
              defaultValue=""
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  displayEmpty
                  defaultValue=""
                  {...field}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value="">Federación</MenuItem>
                  {federations?.map((federation) => {
                    return (
                      <MenuItem key={federation.id} value={federation.id}>
                        {federation.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Club</Typography>
            <Controller
              control={control}
              name="clubId"
              defaultValue=""
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  displayEmpty
                  defaultValue=""
                  value={field.value || ""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value="">Club</MenuItem>
                  {clubs?.map((club) => {
                    return (
                      <MenuItem key={club.id} value={club.id}>
                        {club.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Nombre*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Nombre"
              error={!!errors?.name}
              {...register("name")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.name && (
              <Typography className="form-input-error">{errors?.name?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Fecha de nacimiento*</Typography>
            <DatePicker
              className="input-text form-text-field"
              format="DD/MM/YYYY"
              maxDate={dayjs()}
              value={watch("bornAt") ? dayjs(watch("bornAt")) : null}
              readOnly={isDisabled}
              onChange={(value: Dayjs | null) => handleSetDate(value?.toDate() ?? null)}
            />
            {errors?.bornAt && (
              <Typography className="form-input-error">{errors?.bornAt?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Apellidos*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Apellidos"
              error={!!errors?.surname}
              {...register("surname")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.name && (
              <Typography className="form-input-error">{errors?.surname?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">DNI*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="DNI"
              error={!!errors?.identityNumber}
              {...register("identityNumber")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.name && (
              <Typography className="form-input-error">
                {errors?.identityNumber?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Nacionalidad</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Nacionalidad"
              error={!!errors?.nationality}
              {...register("nationality")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.nationality && (
              <Typography className="form-input-error">{errors?.nationality?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={6} rowSpacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <Typography className="from-input-label">Género</Typography>
              <RadioGroup
                name="radio-buttons-group"
                row
                value={watch("genre") ?? ""}
                defaultValue=""
                onChange={(e) => setValue("genre", e.target.value)}
              >
                <FormControlLabel
                  value="w"
                  control={<Radio className="check-radio-orange" />}
                  label="Mujer"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="m"
                  control={<Radio className="check-radio-orange" />}
                  label="Hombre"
                  disabled={isDisabled}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicAdminInfo;
