import { Apartment, BarChart, Store, Event } from "@mui/icons-material";
import { Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loader/Loading";
import useQueryEvents from "../../utils/hooks/useQueryEvents";
import useSetPageName from "../../utils/hooks/useSetPageName";
import "./home.scss";
import { IMAGE_DEFAULT } from "../../constants/constants";

type Props = {};

const Home = (props: Props) => {
  useSetPageName([{ name: "Inicio", path: "/" }]);
  const navigate = useNavigate();

  const filters = {
    page: 1,
    perPage: 10,
    order: { desc: ["start_date"], asc: [""] },
  };

  const { data, isLoading } = useQueryEvents(filters);

  const buttonLinks = [
    { name: "Ver mi club", path: "/clubs", icon: <Store /> },
    { name: "Ver mi federación", path: "/federaciones", icon: <Apartment /> },
    { name: "Ver rankings", path: "/ranking", icon: <BarChart /> },
  ];

  return (
    <Container sx={{ height: "100dvh", p: 1 }}>
      <Stack spacing={2} direction="column" marginBottom={4}>
        {buttonLinks.map((link) => (
          <Button
            variant="contained"
            onClick={() => navigate(link.path)}
            className="home-button"
            startIcon={link.icon}
          >
            {link.name}
          </Button>
        ))}
        {!data && (
          <Button
            onClick={() => navigate("/gestor_eventos")}
            color="primary"
            sx={{ width: "100%" }}
            className="home-button"
            startIcon={<Event />}
          >
            Ver Eventos
          </Button>
        )}
      </Stack>

      {/* Lista de eventos  */}
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
        mb={2}
      >
        <Paper elevation={2} sx={{ padding: "10px", minHeight: "50dvh" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              mb: 1,
              textAlign: "left",
              fontSize: { xs: "1rem", md: "1rem" },
            }}
            variant="h5"
            mt={2}
          >
            Próximos eventos
          </Typography>
          {isLoading ? (
            <Loading />
          ) : (
            <Grid item sx={{ maxHeight: "calc(60vh - 85px)", overflowY: "auto" }}>
              {data?.data?.map((event) => (
                <Grid container item key={event.id} className="event-row">
                  <Grid item xs={3}>
                    <img src={event.posterUrl || IMAGE_DEFAULT} alt={event.name} className="event-image" />
                  </Grid>
                  <Grid item xs={6} paddingLeft={2}>
                    <Typography variant="body1" component="div">
                      {event.name}
                    </Typography>
                    <Typography color="text.secondary">
                      {new Date(event.startDate).toLocaleDateString()} -{" "}
                      {new Date(event.endDate).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/event/${event.id}`)}
                      className="event-link"
                    >
                      Ver Detalles
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          <Button
            onClick={() => navigate("/gestor_eventos")}
            color="primary"
            sx={{ width: "100%" }}
            className="home-button"
            startIcon={<Event />}
          >
            Ver más
          </Button>
        </Paper>
      </Grid>
    </Container>
  );
};

export default Home;
