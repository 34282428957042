import L, { type LatLngTuple } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { Marker, useMapEvents } from "react-leaflet";

const customMarker = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

interface LocationMarkerProps {
  position: LatLngTuple | null;
  handleSetPosition: (lat: number, lng: number) => void;
  isDisabled?: boolean;
}

const LocationMarker: React.FC<LocationMarkerProps> = ({
  position,
  handleSetPosition,
  isDisabled,
}) => {
  useMapEvents({
    click(e) {
      if (isDisabled) return;
      handleSetPosition(e.latlng.lat, e.latlng.lng);
    },
  });

  return position === null ? null : <Marker position={position} icon={customMarker} />;
};

export default LocationMarker;
