import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterState, initialState } from "../../utils/schemas/listFilters";

const filterSlice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ filterName: keyof FilterState; payload: any }>) => {
      const { filterName, payload } = action.payload;
      state[filterName] = payload;
    },
    resetFilters: () => {
      return initialState;
    },
  },
});

export const { setFilter, resetFilters } = filterSlice.actions;
export default filterSlice.reducer;
