import { createAsyncThunk } from "@reduxjs/toolkit";

import { API_URL, SALT, SERVICE } from "../../constants/api";
import http from "../../constants/axios";
import { checkShortCodeClub } from "../../services/clubs";
import { checkShortCodeFederation } from "../../services/federations";
import { checkUserCnr, checkUserEmail } from "../../services/users";
import { transformObjectWithUrls } from "../../utils/images";
import { TEvent } from "../../utils/schemas/event";
import { AddFederationSchemaType } from "../../utils/schemas/federations";
import { TUser } from "../../utils/schemas/user";
import { purgeObjectToEmpty, sanitizeBoolean } from "../../utils/utils";
import { TClub } from "../../utils/schemas/club";
import bcrypt from "bcryptjs";

export const getFormOptions = createAsyncThunk("forms/options", async (_, { rejectWithValue }) => {
  try {
    const request = await http.get(`${API_URL}${SERVICE.All_LISTS}`);
    const response = await request.data;
    return { ...response };
  } catch (error: any) {
    return rejectWithValue(error.response.data.error ?? "Error en servidor");
  }
});

//Events
export const sendEventData = createAsyncThunk(
  "forms/sendEventData",
  async (data: TEvent, { rejectWithValue }) => {
    try {
      const dataToSend = JSON.parse(JSON.stringify(data)) as any;
      const bodyToSend = await transformObjectWithUrls(dataToSend);

      purgeObjectToEmpty(bodyToSend);
      bodyToSend.ranking = bodyToSend.ranking ? 1 : 0;
      bodyToSend.isActive = sanitizeBoolean(bodyToSend.isActive);

      //Stocks de inscripciones
      if (bodyToSend.stocks) {
        for (let i = 0; i < bodyToSend.stocks.length; i++) {
          bodyToSend.stocks[i].stock = parseInt(bodyToSend.stocks[i].stock);
        }
      }
      bodyToSend.programSchedules = JSON.stringify(bodyToSend.programSchedules);
      bodyToSend.streamingUrl = JSON.stringify(bodyToSend.streamingUrl);

      bodyToSend.status = "pending";
      //
      const request = await http.post(`${API_URL}${SERVICE.EVENTS}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const updateEventData = createAsyncThunk(
  "forms/updateEventData",
  async ({ data, id }: { data: TEvent; id: string }, { rejectWithValue }) => {
    try {
      const dataToSend = JSON.parse(JSON.stringify(data)) as any;
      const bodyToSend = await transformObjectWithUrls(dataToSend);
      purgeObjectToEmpty(bodyToSend);
      bodyToSend.ranking = bodyToSend.ranking ? 1 : 0;
      bodyToSend.isActive = sanitizeBoolean(bodyToSend.isActive);

      bodyToSend.programSchedules = JSON.stringify(bodyToSend.programSchedules);
      bodyToSend.streamingUrl = JSON.stringify(bodyToSend.streamingUrl);
      //Add eventId a stocks
      if (bodyToSend.stocks) {
        for (let i = 0; i < bodyToSend.stocks.length; i++) {
          bodyToSend.stocks[i].eventId = id;
        }
      }
      //hotel
      if (bodyToSend.hotelRooms) {
        for (let i = 0; i < bodyToSend.hotelRooms.length; i++) {
          bodyToSend.hotelRooms[i].eventId = id;
        }
      }
      //
      const request = await http.put(`${API_URL}${SERVICE.EVENTS}/${id}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

//validar evento por id
export const validateEvent = createAsyncThunk(
  "forms/validateEvent",
  async (id: string, { rejectWithValue }) => {
    try {
      const request = await http.put(`${API_URL}${SERVICE.EVENTS}/validate/${id}`);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

//cancelar evento por id
export const cancelEvent = createAsyncThunk(
  "forms/cancelEvent",
  async (id: string, { rejectWithValue }) => {
    try {
      const request = await http.delete(`${API_URL}${SERVICE.EVENTS}/${id}`);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

//Clubs
export const sendClubData = createAsyncThunk(
  "forms/sendEventData",
  async (
    { data, federationId }: { data: TClub; federationId: string | undefined },
    { rejectWithValue }
  ) => {
    try {
      //Check if shortCode is already in use
      const isValidCode = await checkShortCodeClub(data?.shortCode || "");
      if (!isValidCode) {
        return rejectWithValue("El código de club ya está en uso");
      }
      const bodyToSend = await transformObjectWithUrls(data);
      bodyToSend.weekSchedulee = JSON.stringify(bodyToSend.weekSchedulee);
      bodyToSend.federationId = bodyToSend.federationId
        ? bodyToSend.federationId
        : federationId
        ? federationId
        : null;
      delete bodyToSend.isFiscalAddressSameAsAddress;
      const request = await http.post(`${API_URL}${SERVICE.CLUBS}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const updateClubData = createAsyncThunk(
  "forms/updateEventData",
  async (
    { data, id, federationId }: { data: TClub; id: string; federationId: string | undefined },
    { rejectWithValue }
  ) => {
    try {
      const bodyToSend = await transformObjectWithUrls(data);
      bodyToSend.weekSchedule = JSON.stringify(bodyToSend.weekSchedule);
      bodyToSend.federationId = bodyToSend.federationId
        ? bodyToSend.federationId
        : federationId
        ? federationId
        : null;

      delete bodyToSend.isFiscalAddressSameAsAddress;

      const request = await http.put(`${API_URL}${SERVICE.CLUBS}/${id}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const sendFederationData = createAsyncThunk(
  "forms/sendEventData",
  async (data: any, { rejectWithValue }) => {
    try {
      const isValidCode = await checkShortCodeFederation(data.shortCode);
      if (!isValidCode) {
        return rejectWithValue("El código de federación ya está en uso");
      }
      const purgueData = purgeObjectToEmpty(data);
      if (purgueData.isFiscalAddressSameAsAddress) {
        delete purgueData.fiscalAddress;
      }
      delete purgueData.isFiscalAddressSameAsAddress;

      const bodyToSend = await transformObjectWithUrls(purgueData);
      bodyToSend.timetable = JSON.stringify(bodyToSend.timetable);

      const request = await http.post(`${API_URL}${SERVICE.FEDERATIONS}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const updateFederationData = createAsyncThunk(
  "forms/updateEventData",
  async ({ data, id }: { data: AddFederationSchemaType; id: string }, { rejectWithValue }) => {
    try {
      const purgueData = purgeObjectToEmpty(data);
      if (purgueData.isFiscalAddressSameAsAddress) {
        delete purgueData.fiscalAddress;
      }
      delete purgueData.isFiscalAddressSameAsAddress;

      const bodyToSend = await transformObjectWithUrls(purgueData);
      bodyToSend.timetable = JSON.stringify(bodyToSend.timetable);

      const request = await http.put(`${API_URL}${SERVICE.FEDERATIONS}/${id}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

interface SendUserDataParams {
  data: TUser;
  isAdmin?: boolean;
}
//Users
export const sendUserData = createAsyncThunk(
  "forms/sendUserData",
  async ({ data, isAdmin }: SendUserDataParams, { rejectWithValue }) => {
    try {
      if (!isAdmin) {
        const cnrResponse = await checkUserCnr(data.cnr || "");
        if (!cnrResponse) {
          return rejectWithValue("El CNR ya está en uso");
        }
      }
      const emailResponse = await checkUserEmail(data.email);
      if (!emailResponse) {
        return rejectWithValue("El email ya está en uso");
      }
      const bodyToSend = await transformObjectWithUrls(data);
      isAdmin ? (bodyToSend.isActive = true) : (bodyToSend.isActive = false);
      const request = await http.post(`${API_URL}${SERVICE.USERS}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const updateUserData = createAsyncThunk(
  "forms/updateUserData",
  async ({ data, id }: { data: TUser; id: string }, { rejectWithValue }) => {
    try {
      const bodyToSend = await transformObjectWithUrls(data);
      const request = await http.put(`${API_URL}${SERVICE.USERS}/${id}`, bodyToSend);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const setUserPassword = createAsyncThunk(
  "auth/setUserPassword",
  async (data: { body: { password: string }; token: string }, { rejectWithValue }) => {
    const bodyToSend = {
      password: bcrypt.hashSync(data?.body?.password, SALT),
    };
    try {
      const request = await http.put(`${API_URL}${SERVICE.USERS}/verify/${data.token}`, bodyToSend);
      return request.data;
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue("Error al cambiar la contraseña");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "forms/deleteUser",
  async (id: string, { rejectWithValue }) => {
    try {
      const request = await http.delete(`${API_URL}${SERVICE.USERS}/${id}`);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

export const inactiveUser = createAsyncThunk(
  "forms/inactiveUser",
  async (data: { id: string; value: boolean }, { rejectWithValue }) => {
    try {
      const { id, value } = data;
      const body = { newValue: value };
      const request = await http.put(`${API_URL}${SERVICE.USERS}/sportily-active/${id}`, body);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

//Create users from csv
export const createSomeUsers = createAsyncThunk(
  "forms/createSomeUsers",
  async (data: any, { rejectWithValue }) => {
    try {
      const request = await http.post(`${API_URL}${SERVICE.USERS}/some`, data);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

//Send Results from csv
export const sendResults = createAsyncThunk(
  "forms/sendResults",
  async (data: any, { rejectWithValue }) => {
    try {
      const request = await http.post(`${API_URL}${SERVICE.RESULTS}`, data);
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);
