import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import { AddCircleOutline, Delete } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};

const Streaming = ({ isDisabled }: Props) => {
  const { register, formState, setValue, watch, clearErrors } = useFormContext<TEvent>();
  const { errors } = formState;

  const streaming = watch("streaming");
  const streamUrls = watch("streamingUrl") || [];

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("streaming", e.target.checked);
    } else {
      setValue("streaming", e.target.checked);
      setValue("streamingUrl", []);
      clearErrors("streamingUrl");
    }
  };

  const handleAddStreamLink = () => {
    setValue("streamingUrl", [...streamUrls, ""]);
  };

  const handleRemoveStreamLink = (index: number) => {
    setValue(
      "streamingUrl",
      [...streamUrls].filter((_, i) => i !== index)
    );
  };

  return (
    <Box className="form-section-container">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography className="form-section-title">Streaming</Typography>
        <Switch
          onChange={handleSwitchChange}
          className="form-switch"
          checked={streamUrls.length > 0 || false}
          disabled={isDisabled}
        />
      </Stack>
      {streaming && (
        <>
          {streamUrls?.map((_, index) => (
            <Grid
              container
              columnSpacing={4}
              rowGap={{ xs: 2, md: 4 }}
              alignItems="center"
              sx={{ mb: 3 }}
              key={`Stream - ${index}`}
            >
              <Grid item md={6} xs={10}>
                <FormControl fullWidth>
                  <Typography className="form-input-label">URL*</Typography>
                  <TextField
                    className="input-text form-text-field"
                    variant="outlined"
                    placeholder="URL*"
                    error={!!errors?.streamingUrl?.[index]}
                    {...register(`streamingUrl.${index}`)}
                    InputProps={{
                      readOnly: isDisabled,
                    }}
                  />
                  {errors?.streamingUrl?.[index] && (
                    <Typography className="form-input-error">
                      {errors?.streamingUrl?.[index]?.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={1} xs={2}>
                {index !== 0 && (
                  <IconButton onClick={() => handleRemoveStreamLink(index)} disabled={isDisabled}>
                    <Delete />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
          <Stack direction="row" justifyContent="flex-start">
            <ButtonBase
              onClick={handleAddStreamLink}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                width: "150px",
              }}
              disabled={isDisabled}
            >
              <AddCircleOutline /> Añadir otro link
            </ButtonBase>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default Streaming;
