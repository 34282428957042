import { Add, Search } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../context/hooks/hooks";
import useDebounce from "../../../utils/hooks/useDebouncing";
import { FilterState } from "../../../utils/schemas/listFilters";
import Button from "../../button/Button";
import SelectInput from "../../selectInput/SelectInput";
import TextInput from "../../textInput/TextInput";
import { CATEGORY_NAMES, GROUP_AGE_CODE, ROLES } from "../../../constants/constants";
import useQueryClubsList from "../../../utils/hooks/useQueryClubList";
import useQueryFederationsList from "../../../utils/hooks/useQueryFederationsList";
import { useEffect } from "react";

type Props = {
  setOpenMultiUserDialog: (value: boolean) => void;
  handleFilterChange: (value: string | unknown, filterName: keyof FilterState) => void;
  filterState: FilterState;
};
const UsersFilters = ({ setOpenMultiUserDialog, handleFilterChange, filterState }: Props) => {
  const { userInfo } = useAppSelector((state) => state.auth);

  //Look user federation
  useEffect(() => {
    if (userInfo && userInfo.federationId) {
      handleFilterChange(userInfo.federationId, "federationId");
    }
  }, [userInfo]);

  //Filter data
  const { belts } = useAppSelector((state) => state.formsOptions);
  const { data: clubs } = useQueryClubsList((filterState.federationId as string) || "");
  const { data: federations } = useQueryFederationsList();

  //Search optimization
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  return (
    <>
      <Box className="users-header">
        {/* SEARCH BAR */}
        <Box className="users-header-searchInput-container">
          <IconButton>
            <Search className="users-header-searchInput-icon" />
          </IconButton>
          <TextInput
            placeholder="Buscar"
            minWidth="full-width"
            onChange={(e) => debounceSearch(e.target.value)}
          />
        </Box>
        <Box className="users-header-actions-container">
          <Link to="/users/agregar" className="users-header-actions-button">
            <Button
              startIcon={<Add />}
              rounded="md"
              width="fit-content"
              className="users-header-actions-button"
            >
              AGREGAR USUARIO
            </Button>
          </Link>
          <Button
            startIcon={<Add />}
            rounded="md"
            width="fit-content"
            className="users-header-actions-button"
            onClick={() => setOpenMultiUserDialog(true)}
          >
            AGREGAR DESDE CSV
          </Button>
        </Box>
      </Box>
      <Box className="users-header">
        <Box className="users-filters">
          {userInfo &&
            (userInfo.roles.includes(ROLES.SUPER_ADMIN) ||
              userInfo.roles.includes(ROLES.REFEJYDA)) && (
              <SelectInput
                rounded="md"
                defaultValue=""
                title="Federaciones: Todas"
                options={[
                  { value: "", label: "Todas las federaciones" },
                  ...(federations && federations.length > 0
                    ? federations.map((federation) => ({
                        value: federation.id,
                        label: federation.name,
                      }))
                    : []),
                ]}
                onChange={(e) => handleFilterChange(e.target.value, "federationId")}
                value={filterState.federationId}
              />
            )}
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Clubs: Todos"
            options={[
              { value: "", label: "Todos los Clubs" },
              ...(clubs && clubs.length > 0
                ? clubs.map((club) => ({
                    value: club.id,
                    label: club.name,
                  }))
                : []),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "clubId")}
            value={filterState.clubId}
          />
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Categoría: Todas"
            options={[
              { value: "", label: "Todas las categorías" },
              ...CATEGORY_NAMES.map((category) => ({
                value: category,
                label: category,
              })),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "categoryCode")}
            value={filterState.categoryCode}
          />
          <SelectInput
            defaultValue=""
            rounded="md"
            title="Edad: Todas"
            options={[
              { value: "", label: "Todas las edades" },
              ...GROUP_AGE_CODE.map((age) => ({
                value: age,
                label: age,
              })),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "categoryCode")}
            value={filterState.categoryCode}
          />
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Cinturones: Todos"
            options={[
              { value: "", label: "Todos los cinturones" },
              ...(belts && belts.length > 0
                ? [...belts]
                    ?.sort((a, b) => a.position - b.position)
                    .map((belt) => ({
                      value: belt.id,
                      label: belt.name,
                    }))
                : []),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "belt")}
          />
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Estado: Todos"
            options={[
              { value: "", label: "Todos los estados" },
              { value: "true", label: "Activos" },
              { value: "false", label: "Inactivos" },
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "isSportilyActive")}
          />
          {/* <SelectInput rounded="md" defaultValue="" title="Colegaciones: Todas" /> */}
        </Box>
      </Box>
    </>
  );
};

export default UsersFilters;
