import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getEvents } from "../../services/events";
import { DataQueryEvent } from "../schemas/event";
import { FilterState } from "../schemas/listFilters";

export default function useQueryEvents(filters: FilterState) {
  return useQuery<DataQueryEvent, Error>([RQ_KEY.EVENTS, filters], () => getEvents(filters));
}
