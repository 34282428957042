import { Grid, TablePagination, Typography } from "@mui/material";

import { TClubList } from "../../../utils/schemas/club";
import { TFederationsList } from "../../../utils/schemas/federations";
import { TQueryInscription } from "../../../utils/schemas/inscriptions";
import { TInscriptionFilters } from "../../../utils/schemas/listFilters";
import RejectedFilters from "./filters/RejectedFilters";
import RejectedList from "./list/RejectedList";

type Props = {
  inscriptions: TQueryInscription | undefined;
  updateRejectedFilter: (key: keyof TInscriptionFilters, value: string | number) => void;
  filterRejected: TInscriptionFilters;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
};

const InscriptionsRejected = ({
  inscriptions,
  updateRejectedFilter,
  filterRejected,
  clubs,
  federations,
}: Props) => {
  return (
    <>
      <Grid className="inscriptions-container-create" container columnSpacing={4}>
        <Grid item container xs={12} className="inscriptions-buttons-container" columnSpacing={4}>
          <RejectedFilters
            updateRejectedFilter={updateRejectedFilter}
            filterRejected={filterRejected}
            clubs={clubs}
            federations={federations}
          />
        </Grid>
      </Grid>
      <Grid className="inscriptions-header">
        <Typography className="inscriptions-count">
          Inscripciones Rechazados: {inscriptions?.total || 0}
        </Typography>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={inscriptions?.total || 0}
          rowsPerPage={inscriptions?.perPage || 10}
          page={inscriptions?.page || 1 - 1}
          onPageChange={(_, newPage) => updateRejectedFilter("page", newPage)}
          onRowsPerPageChange={(e) => {
            updateRejectedFilter("perPage", e.target.value);
            updateRejectedFilter("page", 1);
          }}
          labelRowsPerPage="Inscripciones por página"
        />
      </Grid>
      {inscriptions && inscriptions?.data.length > 0 ? (
        <RejectedList inscriptions={inscriptions.data} />
      ) : (
        <Typography className="inscriptions-empty">No hay inscritos rechazados</Typography>
      )}
    </>
  );
};
export default InscriptionsRejected;
