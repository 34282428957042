import { z } from "zod";
import { MESSAGE } from "../../constants/errorMessages";

export const addEventSchema = z.object({
  //Step1
  name: z.string().min(1, MESSAGE.REQUIRED).max(255, { message: MESSAGE.MAX_255 }).optional(),
  officialName: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  //sin espacios, solo en minusculas, _ o - o numeros
  code: z
    .string()
    .regex(/^[a-z0-9_-]+$/, {
      message: MESSAGE.EVENT_CODE,
    })
    .optional(),
  codeActuation: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  posterUrl: z.string().optional(),
  description: z.string().nullable().optional(),
  startDate: z.union([z.date(), z.string()]).default(""),
  endDate: z.union([z.date(), z.string()]).default(""),
  //Estas dos no se envían
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  location: z.string().optional(),
  mapsUrl: z.string().optional(),
  nTatamis: z.number().optional(),
  refereeMeet: z.union([z.date(), z.string()]).optional(),
  minAge: z.number().optional(),
  minBeltId: z.string().optional(),
  ranking: z.union([z.boolean(), z.number()]).optional(),
  //Responsables
  hasResponsables: z.boolean().optional(),
  responsables: z
    .array(
      z.object({
        name: z.string().optional(),
        surname: z.string().optional(),
        phone: z.string().optional(),
        familiarRelationship: z.string().optional(),
      })
    )
    .optional()
    .nullable()
    .default(null),
  //Organizador
  organizatorName: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  organizatorType: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  organizatorId: z.string().optional(),
  cif: z.string().optional(),
  iban: z.string().optional(),
  address: z
    .object({
      address: z.string(),
      countryCode: z.string(),
      autonomyCode: z.string(),
      provinceCode: z.string(),
      municipalityCode: z.string(),
      postalCode: z.string(),
    })
    .optional(),
  phoneNumber: z.string().optional(),
  faxNumber: z.string().optional(),
  email: z.string().email().optional().or(z.literal("")),
  web: z.string().optional(),
  //Schedule
  programSchedule: z.boolean().optional(),
  programSchedules: z
    .array(
      z.object({
        date: z.date().nullable().optional(),
        time: z.string().nullable().optional(),
        description: z.string().nullable().optional(),
      })
    )
    .optional()
    .nullable()
    .default(null),
  refereeDescription: z.string().optional(),
  //Step2
  sportCode: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  categoryCode: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  eventType: z.string().min(1, { message: MESSAGE.REQUIRED }).optional(),
  isByTeams: z.union([z.boolean(), z.number()]).optional(),
  stocks: z
    .array(
      z.object({
        stock: z.number().optional(),
        categoryId: z.string().optional(),
        eventId: z.string().optional(),
        id: z.string().optional(),
      })
    )
    .optional()
    .nullable()
    .default(null),
  inscriptions: z.boolean().optional(),
  inscriptionDescription: z.string().optional(),
  weighingChangeLimitDate: z.union([z.date(), z.string()]).optional(),

  //Step3
  lodging: z.boolean().optional(),
  hotelName: z.string().optional(),
  hotelDescription: z.string().optional(),
  hotelImage: z.string().optional(),
  hotelAddress: z.string().optional(),
  hotelMapsUrl: z.string().optional(),
  hotelRooms: z
    .array(
      z.object({
        eventId: z.string().optional(),
        roomType: z.string().optional(),
        roomCapacity: z.number().optional(),
        price: z.number().optional(),
      })
    )
    .optional()
    .nullable()
    .default(null),
  picnic: z.boolean().optional(),
  streaming: z.boolean().optional(),
  streamingUrl: z.array(z.string()).optional().nullable().default(null),
  transport: z.string().optional(),
  //Step4
  sponsors: z.boolean().optional(),
  sponsorsUrl: z.string().optional(),
  gifts: z.string().optional(), //premios
  anexos: z.string().optional(),
  annexImage: z.string().optional(),
  documentation: z.string().optional(),
  dataAndParentalDescription: z.string().optional(),
  circularPdf: z.string().optional().nullable().default(null),

  //NO se utilizan
  subtitle: z.string().optional(),
  onlyForUs: z.boolean().optional(), //meter en el formulario un checkbox
  startSaleDate: z.string().optional(),
  endSaleDate: z.string().optional(),
  publicationDate: z.string().optional(),
  endOfPublicationDate: z.string().optional(),
  publicationStartDate: z.string().optional(),
  publicationEndDate: z.string().optional(),
  latitude: z.string().optional(), //falta en el formulario
  longitude: z.string().optional(), //falta en el formulario
  price: z.number().optional(),
  isVisible: z.boolean().optional(),
  status: z.string().optional(),
  isActive: z.boolean().optional(),
});

export type TEvent = z.infer<typeof addEventSchema>;

export type TEventStock = {
  stock?: number | undefined;
  categoryId?: string | undefined;
  eventId?: string | undefined;
  id?: string | undefined;
};

export type TQueryEvent = {
  type: TEvent;
  search?: string;
  status?: string;
  organizers?: string;
  order?: string;
  category?: string;
  id: string;
  code: string;
  isActive: number;
  isVisible: number;
  name: string;
  organizatorName: string;
  organizatorId: string;
  posterUrl: string;
  startDate: string;
  endDate: string;
  inscriptions: number;
  maxCapacity: number;
  stocks: {
    category_name: string;
    genre: string;
    stock: number;
    weight: string;
    approved: number;
    reserved: number;
  }[];
};

export type DataQueryEvent = {
  data: [TQueryEvent];
  page: number;
  perPage: number;
  total: number;
};

export type EventFilters = {
  type?: string | unknown;
  categoryCode?: string | unknown;
  organizerId?: string | unknown;
  fromDate?: Date | null;
  toDate?: Date | null;
  search?: string | unknown;
  perPage?: number;
  page?: number;
};
