import { Scale } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useMutation } from "react-query";
import { INSCRIPTION_STATUS } from "../../../../constants/constants";
import { validateInscription } from "../../../../services/inscriptions";
import { isLegalAge, mapDateToRightFormat } from "../../../../utils/date";
import { TEventStock } from "../../../../utils/schemas/event";
import { TInscription } from "../../../../utils/schemas/inscriptions";
import { toastMessageError, toastMessageSuccess } from "../../../common/toastMessage";
import CustomDialog from "../../../customDialog/CustomDialog";
import ChangeCategory from "../../changeCategoryDialog/ChangeCategoryDialog";
import RejectDialog from "../../rejectInsctiptionDialog/RejectInscriptionDialog";
import "./inscriptionList.scss";

type Props = {
  inscriptions: TInscription[] | undefined;
  selectedRole: string;
  selectedUsers: string[];
  setSelectedUsers: (users: string[]) => void;
  openValidationDialog: boolean;
  setOpenValidationDialog: (open: boolean) => void;
  refetchInscriptions: () => void;
  stockCategories: TEventStock[] | undefined | null;
  changeDateLimit?: string | Date;
};

const InscriptionPendingList = ({
  inscriptions,
  selectedRole,
  selectedUsers,
  setSelectedUsers,
  openValidationDialog,
  setOpenValidationDialog,
  refetchInscriptions,
  stockCategories,
  changeDateLimit,
}: Props) => {
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [inscriptionToChange, setInscriptionToChange] = useState<TInscription>();
  const [openRejectDialog, setRejectValidationDialog] = useState(false);

  const validateMutation = useMutation(validateInscription);

  const handleValidateUsers = async () => {
    try {
      await validateMutation.mutateAsync({
        body: { ids: selectedUsers },
        rol: selectedRole,
      });
      toastMessageSuccess("Inscripciones validadas correctamente");
    } catch (error: any) {
      toastMessageError(error.message ?? "Error al validar las inscripciones");
    } finally {
      setSelectedUsers([]);
      refetchInscriptions();
      setOpenValidationDialog(false);
    }
  };

  //Check box functions
  const handleCheckAllUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedUsers(inscriptions?.map((inscription) => inscription.id) || []);
    } else {
      setSelectedUsers([]);
    }
  };
  const handleCheckUser = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (selectedUsers.includes(id) && !e.target.checked) {
      setSelectedUsers(selectedUsers.filter((inscriptionId) => inscriptionId !== id));
    } else if (e.target.checked) {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  //Dialog functions
  const handleOpenValidate = (_: any, id: string) => {
    if (id !== "all") {
      setSelectedUsers([id]);
    }
    setOpenValidationDialog(true);
  };

  const handleOpenChangeCategory = (_: any, inscription: TInscription) => {
    setInscriptionToChange(inscription);
    setOpenCategoryDialog(true);
  };

  const handleOpenRejectInscription = (_: any, id: string) => {
    setSelectedUsers([id]);
    setRejectValidationDialog(true);
  };

  const canChangeCategory = () => {
    return dayjs().isBefore(dayjs(changeDateLimit));
  };

  return (
    <>
      <Paper elevation={4} className="container-inscription-list">
        <TableContainer>
          <Table>
            <TableHead className="table-head">
              <TableRow className="no-wrap">
                <TableCell>
                  <Checkbox
                    className="checkbox-inscriptions"
                    onChange={handleCheckAllUsers}
                  ></Checkbox>
                </TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Genero</TableCell>
                <TableCell>Peso kg</TableCell>
                <TableCell>Estado inscripción</TableCell>
                <TableCell>Validación Club</TableCell>
                <TableCell>Validación Federación</TableCell>
                <TableCell>Validación del Menor</TableCell>
                <TableCell>Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inscriptions?.map((inscription) => (
                <TableRow key={inscription.id} className="no-wrap">
                  <TableCell>
                    <Checkbox
                      className="checkbox-inscriptions"
                      onChange={(e) => handleCheckUser(e, inscription.id)}
                      checked={selectedUsers.includes(inscription.id)}
                    ></Checkbox>
                  </TableCell>
                  <TableCell>{inscription.name}</TableCell>
                  <TableCell>{inscription.surname}</TableCell>
                  <TableCell>{inscription.genre}</TableCell>
                  <TableCell>{inscription.categoryWeight}</TableCell>
                  <TableCell>
                    {inscription.status === INSCRIPTION_STATUS.PENDING
                      ? "pendiente"
                      : inscription.status}
                  </TableCell>
                  <TableCell>
                    {inscription.clubValidation
                      ? mapDateToRightFormat(inscription.clubValidation)
                      : "pendiente"}
                  </TableCell>
                  <TableCell>
                    {inscription.federationValidation
                      ? mapDateToRightFormat(inscription.federationValidation)
                      : "pendiente"}
                  </TableCell>
                  <TableCell>
                    {inscription.bornAt
                      ? isLegalAge(inscription.bornAt)
                        ? "mayor de edad"
                        : inscription.minorValidation
                        ? mapDateToRightFormat(inscription.minorValidation)
                        : "pendiente"
                      : null}
                  </TableCell>
                  <TableCell className="no-wrap">
                    <Tooltip title={"Validar inscripción"} placement="top" arrow>
                      <span>
                        <IconButton
                          className="icon-button-accept"
                          sx={{ fontSize: "3rem" }}
                          onClick={(e) => handleOpenValidate(e, inscription.id)}
                          disabled={selectedRole === ""}
                        >
                          <CheckCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Anular inscripción" placement="top" arrow>
                      <IconButton
                        className="icon-button-cancel"
                        onClick={(e) => handleOpenRejectInscription(e, inscription.id)}
                      >
                        <HighlightOffIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    {canChangeCategory() && (
                      <Tooltip title={"Cambiar de categoría"} placement="top" arrow>
                        <span>
                          <IconButton
                            className="icon-button-accept"
                            sx={{ fontSize: "3rem" }}
                            onClick={(e) => handleOpenChangeCategory(e, inscription)}
                          >
                            <Scale fontSize="inherit" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomDialog
        isOpen={openValidationDialog}
        onClose={() => setOpenValidationDialog(false)}
        title="Validar inscripción"
        content="¿Estás seguro que deseas validar las inscripciones seleccionadas?"
        onAccept={handleValidateUsers}
      />
      <ChangeCategory
        isOpen={openCategoryDialog}
        onClose={() => setOpenCategoryDialog(false)}
        inscription={inscriptionToChange}
        stockCategories={stockCategories}
        refetchInscriptions={refetchInscriptions}
      />
      <RejectDialog
        isOpen={openRejectDialog}
        onClose={() => setRejectValidationDialog(false)}
        inscriptionId={selectedUsers[0]}
        refetchInscriptions={refetchInscriptions}
      />
    </>
  );
};

export default InscriptionPendingList;
