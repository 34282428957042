import { Box, Container, Grid, IconButton, TablePagination, Typography } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import FederationCard from "../../components/federationCard/FederationCard";
import TextInput from "../../components/textInput/TextInput";
import SelectInput from "../../components/selectInput/SelectInput";
import Button from "../../components/button/Button";
import useSetPageName from "../../utils/hooks/useSetPageName";
import "./federations.scss";
import { Link } from "react-router-dom";
import useQueryFederations from "../../utils/hooks/useQueryFederations";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import { resetFilters, setFilter } from "../../context/filters/filterSlice";
import { useEffect } from "react";
import useDebounce from "../../utils/hooks/useDebouncing";
import { FilterState } from "../../utils/schemas/listFilters";
import Loading from "../../components/loader/Loading";
import { ROLES } from "../../constants/constants";

const Federations = () => {
  useSetPageName([{ name: "Federaciones", path: "/federaciones" }]);
  const { userInfo } = useAppSelector((state) => state.auth);

  //Filters
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };
  //Reset filters
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  //Filters data
  const { autonomies } = useAppSelector((state) => state.formsOptions);

  //Search optimization
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  const { data: federations, isLoading, isRefetching } = useQueryFederations(filterState);

  return (
    <Container className="federations-container">
      <Box className="federations-header">
        <Box className="federations-header-searchInput-container">
          <IconButton>
            <Search className="federations-header-searchInput-icon" />
          </IconButton>
          <TextInput
            placeholder="Buscar"
            minWidth="full-width"
            onChange={(e) => debounceSearch(e.target.value)}
          />
        </Box>
        <Box className="federation-header-actions-container">
          <SelectInput
            rounded="md"
            title="Autonomía: Todas"
            options={[
              { value: "", label: "Autonomía: Todas" },
              ...(autonomies && autonomies.length > 0
                ? autonomies.map((autonomy) => ({
                    value: autonomy.name,
                    label: autonomy.name,
                  }))
                : []),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "autonomy")}
            value={filterState.autonomy}
          />
          {userInfo?.roles.includes(ROLES.SUPER_ADMIN) && (
            <Link to="/federaciones/agregar" className="federation-header-actions-button">
              <Button
                startIcon={<Add />}
                rounded="md"
                width="fit-content"
                className="federation-header-actions-button"
              >
                AGREGAR FEDERACIÓN
              </Button>
            </Link>
          )}
        </Box>
      </Box>
      <Grid container className="pagination-container">
        {federations && federations.data?.length > 0 && (
          <>
            <Typography className="federations-count">
              Federaciones: {federations?.total || 0}
            </Typography>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={federations.total}
              rowsPerPage={federations.perPage}
              page={federations.page - 1}
              onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
              onRowsPerPageChange={(e) => {
                handleFilterChange(e.target.value, "perPage");
                handleFilterChange(1, "page");
              }}
              labelRowsPerPage="Federaciones por página"
            />
          </>
        )}
      </Grid>
      {isLoading || isRefetching ? (
        <Loading />
      ) : federations && federations.data?.length > 0 ? (
        <Box className="federations-list">
          {federations?.data?.map((federation, index) => {
            return <FederationCard key={`${federation.id}-${index}`} federation={federation} />;
          })}
        </Box>
      ) : (
        <Typography className="federations-empty">No hay federaciones disponibles</Typography>
      )}
    </Container>
  );
};

export default Federations;
