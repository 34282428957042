import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Cancel } from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { TClub } from "../../../../utils/schemas/club";
import Button from "../../../button/Button";

type Props = {
  index: number;
};

const BoardMemberCard = ({ index }: Props) => {
  const { register, formState, setValue, getValues, clearErrors } = useFormContext<TClub>();
  const { boardMembers: errors } = formState.errors;
  const member = getValues(`boardMembers.${index}`);
  const members = getValues("boardMembers") ?? [];
  const [memberChanges, setMemberChanges] = useState(member);

  const handleInputMember = (value: string, key: string) => {
    setMemberChanges((prev) => ({ ...prev, [key]: value }));
  };

  const handleSetNewChanges = () => {
    clearErrors([`boardMembers.${index}`]);
    const errors: Record<string, boolean> = {};
    if (memberChanges !== null) {
      if (!Object.keys(errors).length) {
        setValue(`boardMembers.${index}`, memberChanges);
      }
    }
  };

  const handleRemoveMember = () => {
    const newBoardMemberList = [...members].filter((_, i) => i !== index);
    setValue("boardMembers", newBoardMemberList);
  };

  return (
    <Paper className="form-card-container" elevation={3}>
      <Stack direction="column" alignItems="center" width="100%">
        <Box alignSelf="flex-end">
          <IconButton className="form-card-label small-size" onClick={handleRemoveMember}>
            <Cancel />
          </IconButton>
        </Box>
        <Grid container columnSpacing={3} rowSpacing={3} alignItems="center">
          <Grid item md={1} xs={12}>
            <Stack width="100%" alignItems="center" justifyContent="center">
              <Avatar src={member.avatar} sx={{ width: 56, height: 56 }} />
            </Stack>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Puesto</Typography>
              <TextField
                className={`input-text form-text-field ${
                  member.position !== memberChanges.position ? "updated" : ""
                }`}
                placeholder="Puesto"
                error={!!errors?.[index]?.position}
                {...register(`boardMembers.${index}.position`)}
                onChange={(e) => handleInputMember(e.target.value, "position")}
              />
              {errors?.[index]?.position && (
                <Typography className="form-input-error">
                  {errors?.[index]?.position?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Nombre</Typography>
              <TextField
                className={`input-text form-text-field ${
                  member.name !== memberChanges.name ? "updated" : ""
                }`}
                placeholder="Nombre"
                {...register(`boardMembers.${index}.name`)}
                error={!!errors?.[index]?.name}
                onChange={(e) => handleInputMember(e.target.value, "name")}
              />
              {errors?.[index]?.name && (
                <Typography className="form-input-error">
                  {errors?.[index]?.name?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Apellido</Typography>
              <TextField
                className={`input-text form-text-field ${
                  member.lastName !== memberChanges.lastName ? "updated" : ""
                }`}
                placeholder="Apellido"
                error={!!errors?.[index]?.lastName}
                {...register(`boardMembers.${index}.lastName`)}
                onChange={(e) => handleInputMember(e.target.value, "lastName")}
              />
              {errors?.[index]?.lastName && (
                <Typography className="form-input-error">
                  {errors?.[index]?.lastName?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item md={2} xs={12}>
            <Typography className="form-input-label"></Typography>
            <Button
              className="form-button small-text"
              width="full-width"
              onClick={handleSetNewChanges}
            >
              GUARDAR
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default BoardMemberCard;
