import { Close } from "@mui/icons-material";
import { DialogActions, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "react-query";
import { getUserCensoId } from "../../services/users";
import Loading from "../loader/Loading";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
};

function DialogImage({ isOpen, onClose, id }: Props) {
  const { data, isLoading, isRefetching } = useQuery(["image", id], () => getUserCensoId(id));

  const dni = data?.[0]?.dni_image;
  const firma = data?.[0]?.firma;
  const representante = data?.[0]?.representante;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <>
          <DialogActions>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Datos de usuario
            </Typography>
            <p><strong>Usuario:</strong> {data?.[0]?.name} {data?.[0]?.surname1} {data?.[0]?.surname2}</p>
            <p><strong>Email:</strong> {data?.[0]?.email}</p>
            <p><strong>CNR:</strong> {data?.[0]?.cnr}</p>
            <p><strong>NIF:</strong> {data?.[0]?.nif}</p>
            <p><strong>Dirección:</strong> {data?.[0]?.address}, {data?.[0]?.cp}, {data?.[0]?.poblation}, {data?.[0]?.province}</p>
            <p><strong>Deporte y estamento:</strong> {data?.[0]?.sport} - {data?.[0]?.estamento}</p>
            <p><strong>IP:</strong> {data?.[0]?.ip}</p>
            <p><strong>Certificado de junta checkeado:</strong> {data?.[0]?.certified_voto_club}</p>
            <p><strong>Voto solicitado:</strong> {data?.[0]?.created_at}</p>
            <p><strong>DNI imagen</strong></p>
            {/* <img src={dni} alt="Imagen dni" style={{ width: "100%", height: "auto" }} /> */}
            {dni && dni.includes("data:image/") && (
              <img src={dni} alt="Imagen dni" style={{ width: "100%", height: "auto" }} />
            )}
            {dni && dni.includes("data:application/pdf;base64,") && (
              <object data={dni} type="application/pdf" style={{ width: "100%", height: "auto" }}>
                <embed src={dni} type="application/pdf" />
              </object>
            )}
            {dni && dni.includes("openxmlformats") && (
              <object data={dni} type="application/vnd.openxmlformats-officedocument.wordprocessingml.document" style={{ width: "100%", height: "500px" }}>
                <p>Este navegador no soporta la visualización de documentos Word. Por favor, descarga el documento para verlo: <a href={dni}>Descargar Documento</a>.</p>
              </object>
            )}
            {dni && !dni.includes("data:image/") && !dni.includes("data:application/pdf;base64,") && <p>Formato de dni no soportado</p>}


            <p><strong>Representante imagen</strong></p>
            {representante && representante.includes("data:image/") && (
              <img src={representante} alt="Imagen representante" style={{ width: "100%", height: "auto" }} />
            )}
            {representante && representante.includes("data:application/pdf;base64,") && (
              <object data={representante} type="application/pdf" style={{ width: "100%", height: "auto" }}>
                <embed src={representante} type="application/pdf" />
              </object>
            )}
            {representante && representante.includes("openxmlformats") && (
              <object data={representante} type="application/vnd.openxmlformats-officedocument.wordprocessingml.document" style={{ width: "100%", height: "500px" }}>
                <p>Este navegador no soporta la visualización de documentos Word. Por favor, descarga el documento para verlo: <a href={dni}>Descargar Documento</a>.</p>
              </object>
            )}
            {representante && !representante.includes("data:image/") && !representante.includes("data:application/pdf;base64,") && <p>Formato de firma no soportado</p>}

            <p><strong>Firma imagen</strong></p>
            {/* <img src={firma} alt="Imagen firma" style={{ width: "100%", height: "auto" }} /> */}
            {firma && firma.includes("data:image/") && (
              <img src={firma} alt="Imagen firma" style={{ width: "100%", height: "auto" }} />
            )}
            {firma && firma.includes("data:application/pdf;base64,") && (
              <object data={firma} type="application/pdf" style={{ width: "100%", height: "auto" }}>
                <embed src={firma} type="application/pdf" />
              </object>
            )}
            {firma && firma.includes("openxmlformats") && (
              <object data={firma} type="application/vnd.openxmlformats-officedocument.wordprocessingml.document" style={{ width: "100%", height: "500px" }}>
                <p>Este navegador no soporta la visualización de documentos Word. Por favor, descarga el documento para verlo: <a href={dni}>Descargar Documento</a>.</p>
              </object>
            )}
            {firma && !firma.includes("data:image/") && !firma.includes("data:application/pdf;base64,") && <p>Formato de firma no soportado</p>}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default DialogImage;
