import { Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import "./forget-password.scss";
import TextInput from "../../components/textInput/TextInput";
import Button from "../../components/button/Button";
import { Cancel, KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";
import { z } from "zod";
import { useAppDispatch } from "../../context/hooks/hooks";
import { forgetPassword } from "../../context/auth/authActions";
import { MESSAGE } from "../../constants/errorMessages";
import { toastMessageSuccess } from "../../components/common/toastMessage";

const ForgetPassword = () => {
  const formSchema = z.object({
    email: z.string().email({ message: MESSAGE.EMAIL }),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleResetPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email")?.toString() || "";

    try {
      formSchema.parse({ email });
      const body = {
        email,
        site_verification_url: window.location.origin,
      };
      const response = await dispatch(forgetPassword(body));
      if (response.payload !== "success") {
        setErrors({ noValid: response.payload });
      } else {
        toastMessageSuccess("Se ha enviado un email para restablecer la contraseña.");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          if (err.path) {
            validationErrors[err.path[0]] = err.message;
          }
        });
        setErrors(validationErrors);
      } else {
        setErrors({ noValid: "Ocurrió un error al procesar la solicitud." });
      }
    }
  };

  return (
    <Container className="forget-container">
      <Paper className="forget-paper">
        <Container className="forget-logo" />
        <Container className="forget-title-container">
          <IconButton className="forget-back-button" onClick={handleBackButton}>
            <KeyboardBackspace className="forget-back-icon" />
          </IconButton>
          <Typography className="forget-title" component="h1" variant="h5">
            ¿Olvidaste tu contraseña?
          </Typography>
          <div className="forget-back-button"></div>
        </Container>
        <form onSubmit={handleResetPassword} className="form">
          <TextInput
            name="email"
            required
            id="email"
            title="Email"
            autoComplete="email"
            autoFocus
            minWidth="full-width"
            placeholder="Ingresa tu email"
            defaultValue=""
          />
          {Object.values(errors).map((error, i) => (
            <Typography
              key={`error-${i}`}
              className="reset-error-text"
              component="p"
              sx={{ display: "flex", mt: 1 }}
            >
              <Cancel /> {error}
            </Typography>
          ))}
          <Button
            size="large"
            type="submit"
            width="fit-content"
            rounded="sm"
            sx={{ mt: 3, mb: 2, backgroundColor: "red" }}
            className="forget-button"
          >
            ENVIAR EMAIL
          </Button>
        </form>
        <Grid className="forget-logos-container">
          <img
            src="images/logos/EU_financiation.png"
            alt="Imagen Financiado por la Unión Europea"
            className="forget-logo-sponsors"
          />
          <img
            src="images/logos/Plan_recuperacion.png"
            alt="Plan de recuperación, Transformación y Resiliencia"
            className="forget-logo-sponsors"
          />
        </Grid>
      </Paper>
    </Container>
  );
};

export default ForgetPassword;
