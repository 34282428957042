import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import { Box, Stack, Switch, Typography } from "@mui/material";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};

const Inscription = ({ isDisabled }: Props) => {
  const { setValue, watch } = useFormContext<TEvent>();

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("inscriptions", e.target.checked);
    } else {
      setValue("inscriptions", e.target.checked);
    }
  };

  const inscriptions = watch("inscriptions");

  return (
    <Box className="form-section-container">
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
        marginBottom={2}
      >
        <Typography className="form-section-title">Inscripción</Typography>
        <Switch
          onChange={handleSwitchChange}
          className="form-switch"
          checked={inscriptions || false}
          disabled={isDisabled}
        />
      </Stack>
      {inscriptions && (
        <CKEditor
          editor={ClassicEditor}
          data={watch("inscriptionDescription") || ""}
          onChange={(_, editor) => {
            const data = editor.getData();
            setValue("inscriptionDescription", data);
          }}
          disabled={isDisabled}
          config={{
            removePlugins: [
              "CKFinderUploadAdapter",
              "CKFinder",
              "EasyImage",
              "Image",
              "ImageCaption",
              "ImageStyle",
              "ImageToolbar",
              "ImageUpload",
              "MediaEmbed",
            ],
          }}
        />
      )}
    </Box>
  );
};

export default Inscription;
