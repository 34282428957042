import axios from "axios";
import { API_URL } from "./api";

const http = axios.create({
  baseURL: API_URL,
  //timeout: 4000,
  headers: { Accept: "application/json" },
});

export function setHttpBearerToken(token: string) {
  if (token !== "") {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common["Authorization"];
  }
}

// Para la permanencia del header cuando se recarga la página
const storedToken = localStorage.getItem("token");
if (storedToken) {
  setHttpBearerToken(storedToken);
}

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const errorMessage = error.response.data.error;
    if (errorMessage === "jwt expired") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default http;
