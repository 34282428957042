import { Grid, TablePagination, Typography } from "@mui/material";
import { useState } from "react";
import { TEventStock } from "../../../utils/schemas/event";
import { TQueryInscription } from "../../../utils/schemas/inscriptions";
import Button from "../../button/Button";
import PendingFilters from "./filters/PendingFilters";
import "./inscriptionsPending.scss";
import InscriptionPendingList from "./list/PendingList";
import { TInscriptionFilters } from "../../../utils/schemas/listFilters";
import { TClubList } from "../../../utils/schemas/club";
import { TFederationsList } from "../../../utils/schemas/federations";

type Props = {
  stockCategories: TEventStock[] | undefined | null;
  inscriptions: TQueryInscription | undefined;
  refetchInscriptions: () => void;
  updatePendingFilter: (key: keyof TInscriptionFilters, value: string | number) => void;
  pendingFilters: TInscriptionFilters;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
  changeDateLimit?: string | Date;
};

const InscriptionsPending = ({
  stockCategories,
  inscriptions,
  refetchInscriptions,
  updatePendingFilter,
  pendingFilters,
  clubs,
  federations,
  changeDateLimit,
}: Props) => {
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);

  return (
    <>
      <Grid className="inscriptions-container-create" container columnSpacing={4}>
        <Grid item container xs={12} className="inscriptions-buttons-container" columnSpacing={4}>
          <PendingFilters
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            updatePendingFilter={updatePendingFilter}
            pendingFilters={pendingFilters}
            clubs={clubs}
            federations={federations}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            className="create-button"
            onClick={() => setOpenValidationDialog(true)}
            disabled={selectedRole === "" || selectedUsers.length === 0}
          >
            Validar ({selectedUsers.length})
          </Button>
        </Grid>
      </Grid>
      <Grid className="inscriptions-header">
        <Typography className="inscriptions-count">
          Inscripciones pendientes de validar: {inscriptions?.total || 0}
        </Typography>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={inscriptions?.total ?? 0}
          rowsPerPage={inscriptions?.perPage ?? 5}
          page={inscriptions?.page ? inscriptions.page - 1 : 0}
          onPageChange={(_, newPage) => updatePendingFilter("page", newPage + 1)}
          onRowsPerPageChange={(e) => {
            updatePendingFilter("perPage", e.target.value);
            updatePendingFilter("page", 1);
          }}
          labelRowsPerPage="Inscripciones por página"
        />
      </Grid>
      {inscriptions && inscriptions?.data.length > 0 ? (
        <InscriptionPendingList
          refetchInscriptions={refetchInscriptions}
          inscriptions={inscriptions.data}
          selectedRole={selectedRole}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          openValidationDialog={openValidationDialog}
          setOpenValidationDialog={setOpenValidationDialog}
          stockCategories={stockCategories}
          changeDateLimit={changeDateLimit}
        />
      ) : (
        <Typography className="inscriptions-empty">
          No hay inscritos pendientes de validar
        </Typography>
      )}
    </>
  );
};
export default InscriptionsPending;
