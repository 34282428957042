import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { TUser } from "../../../../utils/schemas/user";
import { useEffect, useState } from "react";
import ImageUpload from "../../../imageUpload/ImageUpload";
import { useAppSelector } from "../../../../context/hooks/hooks";
import useQueryClubsList from "../../../../utils/hooks/useQueryClubList";
import useQueryFederationsList from "../../../../utils/hooks/useQueryFederationsList";

type Props = {
  isDisabled?: boolean;
};

const SportData = ({ isDisabled }: Props) => {
  const { register, formState, setValue, getValues, control, watch } = useFormContext<TUser>();
  const { errors } = formState;
  const [viewTrainer, setViewTrainer] = useState(false);
  const [viewReferee, setViewReferee] = useState(false);
  //Checkbox allergies
  const [selectedAllergies, setSelectedAllergies] = useState<string[]>([]);
  const handleCheckboxChange = (value: string) => {
    if (selectedAllergies.includes(value)) {
      setSelectedAllergies(selectedAllergies.filter((item) => item !== value));
      setValue("allergy", selectedAllergies.join(","));
    } else {
      setSelectedAllergies([...selectedAllergies, value]);
      setValue("allergy", selectedAllergies.join(","));
    }
  };

  const { belts } = useAppSelector((state) => state.formsOptions);

  const { data: clubs } = useQueryClubsList(watch("federationId") || "");
  const { data: federations } = useQueryFederationsList();

  const handleSwitchTrainerChange = (e: boolean) => {
    if (e) {
      setViewTrainer(true);
    } else {
      setViewTrainer(false);
    }
  };
  const handleSwitchRefereeChange = (e: boolean) => {
    if (e) {
      setViewReferee(true);
    } else {
      setViewReferee(false);
    }
  };
  const handleSetAvatar = (value: { avatar: string }) => {
    setValue("avatarUrl", value.avatar);
  };

  useEffect(() => {
    if (watch("refereeType")!!) {
      setViewReferee(true);
    }
    if (watch("coachType")!!) {
      setViewTrainer(true);
    }
    if (watch("allergy")!!) {
      const allergies = watch("allergy")?.split(",");
      if (allergies) {
        setSelectedAllergies(allergies);
      }
    }
  }, []);

  return (
    <Box className="form-section-container">
      <Typography className="add-user-form-title">Datos deportivos</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={6} xs={12} container columnSpacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Federación autonómica</Typography>
              <Controller
                name="federationId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.federationId}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Federación</em>
                    </MenuItem>
                    {federations?.map((federation) => {
                      return (
                        <MenuItem key={federation.id} value={federation.id}>
                          {federation.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              {errors?.federationId && (
                <Typography className="form-input-error">
                  {errors?.federationId?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Club</Typography>
              <Controller
                name="clubId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.clubId}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Club</em>
                    </MenuItem>
                    {clubs?.map((club) => {
                      return (
                        <MenuItem key={club.id} value={club.id}>
                          {club.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              {errors?.clubId && (
                <Typography className="form-input-error">{errors?.clubId?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} container columnSpacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Typography className="form-input-label">Cinturón</Typography>
              <Controller
                name="currentBeltId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.currentBeltId}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Cinturón</em>
                    </MenuItem>
                    {belts &&
                      [...belts]
                        ?.sort((a, b) => a.position - b.position)
                        .map((belt) => {
                          return (
                            <MenuItem key={belt.id} value={belt.id}>
                              {belt.name}
                            </MenuItem>
                          );
                        })}
                  </Select>
                )}
              />
              {errors?.currentBeltId && (
                <Typography className="form-input-error">
                  {errors?.currentBeltId?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="form-input-label">CNR</Typography>
              <TextField
                className="input-text form-text-field"
                variant="outlined"
                placeholder="CNR*"
                error={!!errors?.cnr}
                {...register("cnr")}
                defaultValue={""}
                InputProps={{ readOnly: isDisabled }}
              />
              {errors?.cnr && (
                <Typography className="form-input-error">{errors?.cnr?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <Typography className="form-input-label">Entrenador</Typography>
            <Switch
              onChange={(e) => handleSwitchTrainerChange(e.target.checked)}
              className="form-switch"
              checked={viewTrainer}
              disabled={isDisabled}
            />
          </Stack>
          {viewTrainer && (
            <FormControl fullWidth>
              <RadioGroup
                name="radio-buttons-group"
                value={getValues("coachType")}
                onChange={(e) => setValue("coachType", e.target.value)}
                defaultValue={""}
              >
                <FormControlLabel
                  value="regional"
                  control={<Radio className="check-radio-red" />}
                  label="Entrenador Regional"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="autonomic"
                  control={<Radio className="check-radio-red" />}
                  label="Entrenador Autonómico"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="nacional"
                  control={<Radio className="check-radio-red" />}
                  label="Entrenador Nacional"
                  disabled={isDisabled}
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <Typography className="form-input-label">Arbitro</Typography>
            <Switch
              onChange={(e) => handleSwitchRefereeChange(e.target.checked)}
              className="form-switch"
              checked={viewReferee}
              disabled={isDisabled}
            />
          </Stack>
          {viewReferee && (
            <FormControl fullWidth>
              <RadioGroup
                name="radio-buttons-group"
                value={getValues("refereeType")}
                onChange={(e) => setValue("refereeType", e.target.value)}
                defaultValue={""}
              >
                <FormControlLabel
                  value="regional"
                  control={<Radio className="check-radio-red" />}
                  label="Arbitro Regional"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="autonomic"
                  control={<Radio className="check-radio-red" />}
                  label="Arbitro Autonómico"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="nacional"
                  control={<Radio className="check-radio-red" />}
                  label="Arbitro Nacional"
                  disabled={isDisabled}
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="add-user-form-title">Avatar(opcional)</Typography>
          <ImageUpload
            onImageSelect={(e) => typeof e === "string" && handleSetAvatar({ avatar: e })}
            url={getValues("avatarUrl") || ""}
            multi={false}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="add-user-form-title sports-titles">
            Alergias(para picnics)
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className="check-radio-orange"
                  onChange={() => handleCheckboxChange("lactosa")}
                  checked={selectedAllergies.includes("lactosa")}
                  disabled={isDisabled}
                  defaultValue={""}
                />
              }
              label="Lactosa"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className="check-radio-orange"
                  onChange={() => handleCheckboxChange("gluten")}
                  checked={selectedAllergies.includes("gluten")}
                  disabled={isDisabled}
                  defaultValue={""}
                />
              }
              label="Gluten"
            />
          </FormGroup>
          {errors?.allergy && (
            <Typography className="form-input-error">{errors?.allergy?.message}</Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Profesión(opcional)</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Profesión"
              {...register("profession")}
              error={!!errors?.profession}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.profession && (
              <Typography className="form-input-error">{errors?.profession?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SportData;
