import { useState } from "react";

const useBoolean = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const handleTrue = () => setState(true);
  const handleFalse = () => setState(false);

  return [state, handleTrue, handleFalse] as const;
};

export default useBoolean;
