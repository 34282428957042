import { Grid, TablePagination, Typography } from "@mui/material";
import { TClubList } from "../../../utils/schemas/club";
import { TEventStock } from "../../../utils/schemas/event";
import { TFederationsList } from "../../../utils/schemas/federations";
import { TQueryInscription } from "../../../utils/schemas/inscriptions";
import { TInscriptionFilters } from "../../../utils/schemas/listFilters";
import ValidatedFilters from "./filters/ValidatedFilters";
import "./inscriptionsValidated.scss";
import ValidatedList from "./list/ValidatedList";

type Props = {
  inscriptions: TQueryInscription | undefined;
  refetchInscriptions: () => void;
  stockCategories: TEventStock[] | undefined | null;
  updateApprovedFilter: (key: keyof TInscriptionFilters, value: string | number) => void;
  filterApproved: TInscriptionFilters;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
  changeDateLimit?: string | Date;
};

const InscriptionsValidated = ({
  inscriptions,
  refetchInscriptions,
  stockCategories,
  updateApprovedFilter,
  filterApproved,
  clubs,
  federations,
  changeDateLimit,
}: Props) => {
  return (
    <>
      <Grid className="inscriptions-container-create" container columnSpacing={4}>
        <Grid item container xs={12} className="inscriptions-buttons-container" columnSpacing={4}>
          <ValidatedFilters
            updateApprovedFilter={updateApprovedFilter}
            filterApproved={filterApproved}
            clubs={clubs}
            federations={federations}
          />
        </Grid>
      </Grid>
      <Grid className="inscriptions-header">
        <Typography className="inscriptions-count">
          Inscripciones validados: {inscriptions?.total || 0}
        </Typography>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={inscriptions?.total || 0}
          rowsPerPage={inscriptions?.perPage || 10}
          page={inscriptions?.page || 1 - 1}
          onPageChange={(_, newPage) => updateApprovedFilter("page", newPage)}
          onRowsPerPageChange={(e) => {
            updateApprovedFilter("perPage", e.target.value);
            updateApprovedFilter("page", 1);
          }}
          labelRowsPerPage="Filas por página"
        />
      </Grid>
      {inscriptions && inscriptions?.data?.length > 0 ? (
        <ValidatedList
          inscriptions={inscriptions.data}
          refetchInscriptions={refetchInscriptions}
          stockCategories={stockCategories}
          changeDateLimit={changeDateLimit}
        />
      ) : (
        <Typography className="inscriptions-empty">No hay inscritos validados</Typography>
      )}
    </>
  );
};
export default InscriptionsValidated;
