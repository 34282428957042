import { useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";
import ImageUpload from "../../../imageUpload/ImageUpload";
import PdfUpload from "../../../pdfUpload/PdfUpload";

type Props = {
  isDisabled?: boolean;
};

const Annex = ({ isDisabled }: Props) => {
  const { register, formState, getValues, setValue, watch } = useFormContext<TEvent>();
  const { errors } = formState;

  const circularPdf = watch("circularPdf");

  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Anexo</Typography>
      <Grid marginBottom={4}>
        <FormControl fullWidth>
          <Typography className="form-input-label">Descripción*</Typography>
          <TextField
            className="input-text"
            variant="outlined"
            placeholder="Descripción*"
            multiline
            rows={10}
            error={!!errors?.anexos}
            {...register("anexos")}
            InputProps={{
              readOnly: isDisabled,
            }}
          />
          {errors?.anexos && (
            <Typography className="form-input-error">{errors?.anexos?.message}</Typography>
          )}
        </FormControl>
      </Grid>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid item md={6} xs={12}>
          <Typography className="form-input-label">Imagen*</Typography>
          <ImageUpload
            url={getValues("annexImage") || ""}
            multi={false}
            onImageSelect={(e) =>
              typeof e === "string" ? setValue("annexImage", e) : setValue("annexImage", "")
            }
            disabled={isDisabled}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className="form-input-label">Pdf*</Typography>
          <PdfUpload
            onChange={(value: any) => setValue("circularPdf", value)}
            disabled={isDisabled}
            value={circularPdf}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Annex;
