import { useFormContext } from "react-hook-form";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { GENRES } from "../../../../constants/constants";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TEvent } from "../../../../utils/schemas/event";
import { sortWeights } from "../../../../utils/utils";
import { InfoOutlined } from "@mui/icons-material";

type Props = {
  selectedSportName: string | null | undefined;
  selectedCategory: string | null | undefined;
  isDisabled?: boolean;
};

const STOCK_MIN = 8;

const Categories = ({ selectedCategory, selectedSportName, isDisabled }: Props) => {
  const { watch, setValue } = useFormContext<TEvent>();

  const { categories } = useAppSelector((state) => state.formsOptions);

  const stocks = watch("stocks");

  const handleCheckboxChange = (categoryId: string, isChecked: boolean) => {
    if (isChecked && stocks) {
      setValue("stocks", [
        ...stocks,
        { categoryId: categoryId, stock: STOCK_MIN, eventId: "", id: "" },
      ]);
    } else if (stocks) {
      setValue(
        "stocks",
        stocks.filter((stock) => stock.categoryId !== categoryId)
      );
    }
  };

  const handleTextFieldChange = (categoryId: string, newStockValue: string) => {
    const stockValue = parseInt(newStockValue);
    if (stocks) {
      setValue(
        "stocks",
        stocks.map((stock) =>
          stock.categoryId === categoryId ? { ...stock, stock: stockValue } : stock
        )
      );
    }
  };

  return (
    <Grid container columnSpacing={4}>
      <Grid item xs={12}>
        <Tooltip
          title="El número mínimo de inscripciones para cada peso es de 8 personas."
          placement="top"
          arrow
        >
          <InfoOutlined />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Chicas</Typography>
        {categories && categories.length > 0
          ? sortWeights(
              categories.filter(
                (category) =>
                  category.sportCode === selectedSportName &&
                  category.genre === GENRES.WOMEN &&
                  category.categoryName === selectedCategory
              )
            ).map((category) => {
              return (
                <Grid
                  container
                  className="categories-inscriptions-container"
                  key={`category_${category.id}`}
                >
                  <FormControlLabel
                    className="form-checkbox-container"
                    control={
                      <Checkbox
                        className="form-checkbox"
                        checked={stocks?.some((stock) => stock.categoryId === category.id)}
                        onChange={(e) => handleCheckboxChange(category.id, e.target.checked)}
                        disabled={isDisabled}
                      />
                    }
                    label={category.weight}
                  />
                  <FormControl className="categories-inscriptions-textfield">
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Stock"
                      type="number"
                      inputProps={{ min: STOCK_MIN }}
                      value={stocks?.find((stock) => stock.categoryId === category.id)?.stock || ""}
                      disabled={!stocks?.some((stock) => stock.categoryId === category.id)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      onChange={(e) => handleTextFieldChange(category.id, e.target.value)}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    />
                    {/* {errors?.cif && (
                        <Typography className="form-input-error">{errors?.cif?.message}</Typography>
                      )} */}
                  </FormControl>
                </Grid>
              );
            })
          : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Chicos</Typography>
        {categories && categories.length > 0
          ? sortWeights(
              categories.filter(
                (category) =>
                  category.sportCode === selectedSportName &&
                  category.genre === GENRES.MEN &&
                  category.categoryName === selectedCategory
              )
            ).map((category) => {
              return (
                <Grid
                  container
                  className="categories-inscriptions-container"
                  key={`category_${category.id}`}
                >
                  <FormControlLabel
                    className="form-checkbox-container"
                    control={
                      <Checkbox
                        checked={stocks?.some((stock) => stock.categoryId === category.id)}
                        onChange={(e) => handleCheckboxChange(category.id, e.target.checked)}
                        className="form-checkbox"
                        disabled={isDisabled}
                      />
                    }
                    label={category.weight}
                  />
                  <FormControl className="categories-inscriptions-textfield">
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Stock"
                      type="number"
                      inputProps={{ min: STOCK_MIN }}
                      value={stocks?.find((stock) => stock.categoryId === category.id)?.stock || ""}
                      disabled={!stocks?.some((stock) => stock.categoryId === category.id)}
                      onChange={(e) => handleTextFieldChange(category.id, e.target.value)}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {/* {errors?.cif && (
                        <Typography className="form-input-error">{errors?.cif?.message}</Typography>
                      )} */}
                  </FormControl>
                </Grid>
              );
            })
          : null}
      </Grid>
    </Grid>
  );
};

export default Categories;
