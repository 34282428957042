import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth/authSlice";
import formsSlice from "./forms/formsSlice";
import pageNameSlice from "./pageName/pageNameSlice";
import filterSlice from "./filters/filterSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    pageName: pageNameSlice,
    formsOptions: formsSlice,
    filters: filterSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
