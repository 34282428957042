import { Search } from "@mui/icons-material";
import { Grid, IconButton, TablePagination } from "@mui/material";
import { TQueryRanking, TRankingFilters } from "../../utils/schemas/rankings";
import TextInput from "../textInput/TextInput";
import "./ranking.scss";

type Props = {
  handleFilterChange: (
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => void;
  handleSearchChange: (e: any) => void;
  data: TQueryRanking | undefined;
};

const RankingHeader = ({ handleFilterChange, handleSearchChange, data }: Props) => {
  return (
    <Grid container className="ranking-header">
      <Grid container justifyContent="space-between" item xs={12} md={6} columnSpacing={2}>
        <Grid item xs={11}>
          <Grid className="search-box" item>
            <IconButton>
              <Search className="ranking-header-searchInput-icon" />
            </IconButton>
            <TextInput
              placeholder="Buscar"
              minWidth="full-width"
              onChange={(e) => handleSearchChange(e)}
            />
          </Grid>
        </Grid>
      </Grid>
      {data && (
        <Grid item xs={12} md={6}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={data?.perPage || 10}
            page={data?.page - 1}
            onPageChange={(_, newPage) => handleFilterChange("page", newPage + 1)}
            onRowsPerPageChange={(e) => {
              handleFilterChange("perPage", e.target.value);
              handleFilterChange("page", 1);
            }}
            labelRowsPerPage="Usuarios por página"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RankingHeader;
