import "../../../components/formComponents/form-components.scss";

import { zodResolver } from "@hookform/resolvers/zod";
import { Container, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import BasicAdminInfo from "../../../components/formComponents/createUser/adminInformation/BasicAdminInfo";
import RolesInfo from "../../../components/formComponents/createUser/adminInformation/RolesInfo";
import UserContactInformation from "../../../components/formComponents/createUser/contactInformation/ContactInformation";
import { sendUserData, updateUserData } from "../../../context/forms/formsActions";
import { useAppDispatch } from "../../../context/hooks/hooks";
import useQueryUserById from "../../../utils/hooks/useQueryUserById";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import { TUser, addUserSchema } from "../../../utils/schemas/user";

type Props = {
  isDisabled?: boolean;
};

const AddEditAdmin = ({ isDisabled }: Props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  useSetPageName([
    { name: "Gestor de Admins", path: "/gestor_admins" },
    {
      name: isDisabled ? "Ver Admin" : isEdit ? "Editar Admin" : "Crear admin",
      path: "/gestor_admins",
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formMethods = useForm<TUser>({ mode: "all", resolver: zodResolver(addUserSchema) });
  const errors = formMethods.formState.errors;

  //Logic to know if is edit
  const { id: userId } = useParams();
  const { userData, activate } = useQueryUserById(userId);

  useEffect(() => {
    if (userId) {
      setIsEdit(true);
      activate();
    }
  }, [userId]);

  useEffect(() => {
    if (userData.data) {
      formMethods.reset(userData.data);
    }
  }, [userData.data, formMethods]);

  const handleBackButton = () => {
    navigate("/gestor_admins");
  };

  const onSubmit: SubmitHandler<TUser> = async (data) => {
    setLoadingSubmit(true);
    if (isEdit && userId) {
      const updateResult = await dispatch(updateUserData({ data: data, id: userId }));
      if (updateResult.meta.requestStatus === "fulfilled") {
        toastMessageSuccess("Admin actualizado correctamente");
        setTimeout(() => {
          navigate("/gestor_admins");
        }, 2000);
      } else {
        toastMessageError(updateResult.payload ?? "Error al actualizar el admin");
        setLoadingSubmit(false);
      }
    } else {
      const createResult = await dispatch(sendUserData({ data, isAdmin: true }));
      if (createResult.meta.requestStatus === "fulfilled") {
        toastMessageSuccess("Admin creado correctamente");
        setTimeout(() => {
          navigate("/gestor_admins");
        }, 2000);
      } else {
        toastMessageError(createResult.payload ?? "Error al crear el admin");
        setLoadingSubmit(false);
      }
    }
  };

  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("❌Errores form admin", error);
  };

  return (
    <Container sx={{ py: { md: 5, xs: 1 } }} maxWidth={false}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit, onError)} className="form-container">
          <Paper className="form-paper" sx={{ p: { md: 5, xs: 1 }, mb: 5 }}>
            <BasicAdminInfo isDisabled={isDisabled} />
            <UserContactInformation isDisabled={isDisabled} />
            <RolesInfo isDisabled={isDisabled} />
          </Paper>
          {errors && Object.keys(errors).length > 0 ? (
            <Typography className="error-message">*Revise los campos del formulario</Typography>
          ) : null}
          {!isDisabled && (
            <Stack direction="row" justifyContent="center" gap={5} flexWrap="wrap">
              <Button className="form-button cancel" onClick={handleBackButton}>
                CANCELAR
              </Button>
              <Button type="submit" className="form-button" disabled={loadingSubmit}>
                {loadingSubmit ? "ENVIANDO..." : "ENVIAR"}
              </Button>
            </Stack>
          )}
        </form>
      </FormProvider>
    </Container>
  );
};

export default AddEditAdmin;
