import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
  isAllowed?: boolean;
  redirectTo?: string;
  component: ReactElement;
};

const ProtectedRoute = ({ isAllowed = true, redirectTo = "/", component }: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectTo} />;
  }
  return component;
};

export default ProtectedRoute;
