import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import "./dateRange.scss";

type Props = {
  setFromDate: (date: Date | null) => void;
  setToDate: (date: Date | null) => void;
  fromDate: Date | null | undefined;
  toDate: Date | null | undefined;
};

const DateRangePicker = ({ setFromDate, fromDate, setToDate, toDate }: Props) => {
  // Cambio  fecha de inicio
  const handleFromDateChange = (date: Date | null) => {
    setFromDate(date);
  };

  // Cambio fecha de fin
  const handleToDateChange = (date: Date | null) => {
    setToDate(date);
  };

  return (
    <Box className="container-date-range">
      <DatePicker
        label="Desde"
        format="DD/MM/YYYY"
        value={fromDate}
        onChange={handleFromDateChange}
        className="date-picker"
        timezone="system"
        slotProps={{
          field: { clearable: true, onClear: () => setFromDate(null) },
          textField: { size: "small" },
        }}
        sx={{ padding: 0 }}
      />
      <DatePicker
        label="Hasta"
        format="DD/MM/YYYY"
        value={toDate}
        className="date-picker"
        onChange={handleToDateChange}
        slotProps={{
          field: { clearable: true, onClear: () => setToDate(null) },
          textField: { size: "small" },
        }}
        minDate={fromDate || undefined}
      />
    </Box>
  );
};

export default DateRangePicker;
