import "./header.scss";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Menu as MenuIcon, NavigateNext, Notifications } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Avatar,
  Breadcrumbs,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { DRAWERWIDTH } from "../../../constants/layout";
import { removeUser } from "../../../context/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import Drawer from "../../drawer/Drawer";
import UserDrawer from "../userDrawer/UserDrawer";

type Props = {
  isDrawerOpen: boolean;
  toggleNavDrawer: () => void;
};

const Header = ({ isDrawerOpen, toggleNavDrawer }: Props) => {
  const { pageName } = useAppSelector((state) => state.pageName);
  const { userInfo, userData } = useAppSelector((state) => state.auth);
  const matches = useMediaQuery("(max-width:769px)");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);

  const openMenu = Boolean(anchorEl);

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserDrawerClick = () => {
    setUserDrawerOpen(!userDrawerOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(removeUser());
    navigate("/login");
  };

  return (
    <MuiAppBar
      position="absolute"
      sx={(theme) => ({
        height: theme.spacing(9),
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(!isDrawerOpen &&
          !matches && {
            width: `calc(100% - ${theme.spacing(9)})`,
          }),
        ...(isDrawerOpen &&
          !matches && {
            marginLeft: DRAWERWIDTH.sm,
            width: `calc(100% - ${DRAWERWIDTH.sm}px)`,
            transition: theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
      })}
      className="header-container"
    >
      <Toolbar className="header-toolbar">
        <Container className="header-mobile">
          <IconButton className="header-mobile-menu" onClick={toggleNavDrawer}>
            <MenuIcon />
          </IconButton>
          <Container className="header-logo" />
        </Container>
        <Breadcrumbs className="header-breadcrumbs" separator={<NavigateNext fontSize="small" />}>
          {pageName.map((page, i) => {
            if (i === pageName.length - 1) {
              return <Typography key={page.path}> {page.name}</Typography>;
            }
            return (
              <Link key={page.path} to={page.path}>
                {page.name}
              </Link>
            );
          })}
        </Breadcrumbs>
        <Container className="header-user-container">
          {!matches && <Notifications className="header-user-notification" fontSize="large" />}
          <Button
            className="header-user-button"
            onClick={matches ? handleUserDrawerClick : handleUserClick}
          >
            <Avatar
              alt="Imagen avatar del usuario"
              src={userData && userData.avatarUrl ? userData.avatarUrl : "images/logos/03.png"}
            />
            <Stack className="header-user-info">
              <Typography fontSize={14} fontWeight={700} color="black">
                {userData?.name}
              </Typography>
              <Typography fontSize={10} color="black">
                {userInfo?.roles[0]}
              </Typography>
            </Stack>
          </Button>
          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
            {/* <MenuItem>Mi Perfil</MenuItem> */}
            <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
          </Menu>
          <Drawer
            toggleDrawer={handleUserDrawerClick}
            open={userDrawerOpen}
            anchor="right"
            size="md"
            onClose={handleUserDrawerClick}
          >
            <UserDrawer
              toggleUserDrawer={handleUserDrawerClick}
              userInfo={userInfo}
              handleLogout={handleLogout}
              userData={userData}
            />
          </Drawer>
        </Container>
      </Toolbar>
    </MuiAppBar>
  );
};

export default Header;
