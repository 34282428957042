import "./set-password.scss";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../components/common/toastMessage";
import { useAppDispatch } from "../../context/hooks/hooks";
import { setUserPassword } from "../../context/forms/formsActions";

const formSchema = z
  .object({
    password: z.string().min(3, { message: "Ingrese una contraseña valida" }),
    repeatPassword: z.string().min(3, { message: "Ingrese una contraseña valida" }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Las contraseñas no coinciden",
    path: ["repeatPassword"],
  });

type FormSchema = z.infer<typeof formSchema>;

function SetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { register, getValues, formState, handleSubmit } = useForm<FormSchema>({
    mode: "all",
    resolver: zodResolver(formSchema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = new URLSearchParams(window.location.search).get("token") || "";

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

  const onSubmit = async () => {
    setLoadingSubmit(true);
    const password = getValues("password");
    const data = {
      body: {
        password: password,
      },
      token: token,
    };
    const setResponse = await dispatch(setUserPassword(data));
    if (setResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Contraseña establecida correctamente");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (setResponse.type.includes("rejected")) {
      toastMessageError("Error al establecer la contraseña");
      setLoadingSubmit(false);
    }
  };

  const disableButton = !formState.isValid;

  return (
    <Container className="set-password-container">
      <Container className="verify-logo" />
      <Box className="set-password-title-container">
        <Typography className="set-password-title">Establecer contraseña</Typography>
        <Typography className="set-password-subtitle">
          Estas registrándote en la web de la RFEJYDA
        </Typography>
      </Box>
      <form className="set-password-form-container" onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            className="text-field set-password-text-field"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            placeholder="Contraseña*"
            error={!!formState.errors.password}
            {...register("password")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formState?.errors?.password && (
            <Typography className="set-password-input-error">
              {formState.errors?.password?.message}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth>
          <TextField
            fullWidth
            className="text-field set-password-text-field"
            type={showRepeatPassword ? "text" : "password"}
            id="repeat-password"
            placeholder="Repetir Contraseña*"
            error={!!formState.errors.repeatPassword}
            {...register("repeatPassword")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRepeatPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formState?.errors?.repeatPassword && (
            <Typography className="set-password-input-error">
              {formState.errors?.repeatPassword?.message}
            </Typography>
          )}
        </FormControl>
        <Button
          disabled={disableButton || loadingSubmit}
          type="submit"
          rounded="md"
          className="set-password-button"
        >
          {loadingSubmit ? "Enviando..." : "Establecer contraseña"}
        </Button>
      </form>
      <Grid className="verify-logos-container">
        <img
          src="images/logos/EU_financiation.png"
          alt="Imagen Financiado por la Unión Europea"
          className="verify-logo-sponsors"
        />
        <img
          src="images/logos/Plan_recuperacion.png"
          alt="Plan de recuperación, Transformación y Resiliencia"
          className="verify-logo-sponsors"
        />
      </Grid>
    </Container>
  );
}

export default SetPassword;
