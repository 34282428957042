import { Grid, Typography } from "@mui/material";
import { TAdminSimple } from "../../../utils/schemas/admins";
import { FilterState } from "../../../utils/schemas/listFilters";
import AdminTable from "../adminCard/AdminTable";
import "./admins-list.scss";

type Props = {
  admins: TAdminSimple[];
  handleFilterChange: (value: string | unknown, filterName: keyof FilterState) => void;
  filterState: FilterState;
  total: number;
  page: number;
  perPage: number;
  refetch: () => void;
};

const AdminsList = ({ admins, handleFilterChange, total, page, perPage, refetch }: Props) => {
  return (
    <Grid>
      {admins.length > 0 ? (
        <AdminTable
          admins={admins}
          total={total}
          page={page}
          perPage={perPage}
          handleFilterChange={handleFilterChange}
          refetch={refetch}
        />
      ) : (
        <Typography className="admins-empty">No hay administradores disponibles</Typography>
      )}
    </Grid>
  );
};

export default AdminsList;
