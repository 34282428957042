import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getClubsList } from "../../services/clubs";
import { TClubList } from "../schemas/club";

export default function useQueryClubsList(federationId: string) {
  return useQuery<Array<TClubList>, Error>([RQ_KEY.CLUB_LIST, federationId], () =>
    getClubsList(federationId)
  );
}
