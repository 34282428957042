import { useEffect } from "react";
import { useAppDispatch } from "../../context/hooks/hooks";
import { setPageName } from "../../context/pageName/pageNameSlice";

const useSetPageName = (pageName: { name: string; path: string }[]) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageName(pageName));
    document.title = "Judo España - " + pageName[pageName.length - 1].name;
  }, [dispatch, pageName]);
};

export default useSetPageName;
