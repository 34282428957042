import { useId } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectProps, Stack } from "@mui/material";
import { INPUTMINWIDTH, INPUTROUNDED } from "../../constants/input";
import "./select-input.scss";

type Props = SelectProps & {
  rounded?: "none" | "sm" | "md" | "xl";
  minWidth?: "sm" | "md" | "full-width";
  options?: { value: string | number; label: string }[];
};

const SelectInput = ({
  title,
  required,
  id,
  options = [],
  minWidth = "md",
  rounded = "none",
  sx,
  ...props
}: Props) => {
  const customId = useId();
  return (
    <Stack
      className="custom-select-container"
      sx={{
        width: INPUTMINWIDTH[minWidth],
      }}
    >
      <FormControl size="small">
        <InputLabel className="custom-select-label" required={required} htmlFor={id ?? customId}>
          {title}
        </InputLabel>
        <Select
          className="custom-select-textfield"
          id={id ?? customId}
          required={required}
          label={title}
          sx={{
            borderRadius: INPUTROUNDED[rounded],
            ...sx,
          }}
          {...props}
        >
          {options.map((option, i) => (
            <MenuItem key={option.value + "" + i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default SelectInput;
