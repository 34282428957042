import "../../../components/formComponents/form-components.scss";
import "./add-event.scss";

import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import { Looks3, Looks4, LooksOne, LooksTwo } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import Annex from "../../../components/formComponents/createEvent/annex/Annex";
import BasicInformation from "../../../components/formComponents/createEvent/basicInformation/BasicInformation";
import DataAndAuth from "../../../components/formComponents/createEvent/dataAndAuth/DataAndAuth";
import Documentation from "../../../components/formComponents/createEvent/documentation/Documentation";
import EventType from "../../../components/formComponents/createEvent/eventType/EventType";
import Inscription from "../../../components/formComponents/createEvent/inscription/Inscription";
import Lodging from "../../../components/formComponents/createEvent/lodging/Lodging";
import Organizer from "../../../components/formComponents/createEvent/organizer/Organizer";
import Picnic from "../../../components/formComponents/createEvent/picnic/Picnic";
import Prizes from "../../../components/formComponents/createEvent/prizes/Prizes";
import ProgramSchedule from "../../../components/formComponents/createEvent/programSchedule/ProgramSchedule";
import Referee from "../../../components/formComponents/createEvent/referee/Referee";
import ResponsableContact from "../../../components/formComponents/createEvent/responsableContact/ResponsableContact";
import Sponsors from "../../../components/formComponents/createEvent/sponsors/Sponsors";
import Streaming from "../../../components/formComponents/createEvent/streaming/Streaming";
import Transport from "../../../components/formComponents/createEvent/transport/Transport";
import Loading from "../../../components/loader/Loading";
import {
  getFormOptions,
  sendEventData,
  updateEventData,
} from "../../../context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import useQueryEventId from "../../../utils/hooks/useQueryEventById";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import { TEvent, addEventSchema } from "../../../utils/schemas/event";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";

const steps = ["Información general", "Inscripciones", "Logística", "Informaciones"];

type Props = {
  isDisabled?: boolean;
};

const CreateEditEvent = ({ isDisabled }: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  useSetPageName([
    { name: "Gestor de eventos", path: "/gestor_eventos" },
    {
      name: isDisabled ? "Ver evento" : isEdit ? "Editar evento" : "Crear evento",
      path: "/gestor_eventos/agregar",
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { success } = useAppSelector((state) => state.formsOptions);

  //Errors PROVISIONAL
  const [errors, setErrors] = useState<FieldValues | null>(null);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  useEffect(() => {
    const getOptions = async () => {
      await dispatch(getFormOptions());
    };
    !success && getOptions();
  }, [dispatch, success]);

  //Logic for edit event
  const { id: eventId } = useParams();
  const { eventData, activate, isLoading, isRefetching } = useQueryEventId(eventId);

  useEffect(() => {
    if (eventId) {
      setIsEdit(true);
      activate();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventData) {
      formMethods.reset(eventData);
    }
  }, [eventData]);

  const defaultValues = addEventSchema.parse({});
  const formMethods = useForm<TEvent>({
    resolver: zodResolver(addEventSchema),
    defaultValues: defaultValues,
  });

  const validateStep1 = async () =>
    await formMethods.trigger([
      "name",
      "officialName",
      "code",
      "codeActuation",
      "posterUrl",
      "description",
      "startDate",
      "endDate",
      "location",
      "mapsUrl",
      "nTatamis",
      "refereeMeet",
      "minAge",
      "minBeltId",
      "ranking",
      "hasResponsables",
      "responsables",
      "organizatorName",
      "organizatorType",
      "organizatorId",
      "cif",
      "iban",
      "address",
      "phoneNumber",
      "faxNumber",
      "email",
      "web",
      "programSchedule",
      "programSchedules",
      "refereeDescription",
    ]);

  const validateStep2 = async () =>
    await formMethods.trigger([
      "sportCode",
      "categoryCode",
      "eventType",
      "stocks",
      "inscriptions",
      "inscriptionDescription",
      "weighingChangeLimitDate",
    ]);

  const validateStep3 = async () =>
    await formMethods.trigger([
      "lodging",
      "hotelName",
      "hotelImage",
      "hotelDescription",
      "hotelAddress",
      "hotelMapsUrl",
      "hotelRooms",
      "picnic",
      "streaming",
      "streamingUrl",
      "transport",
    ]);

  const validateStep4 = async () =>
    await formMethods.trigger([
      "sponsors",
      "sponsorsUrl",
      "gifts",
      "anexos",
      "documentation",
      "dataAndParentalDescription",
      "circularPdf",
    ]);

  const onSubmit: SubmitHandler<TEvent> = async (data) => {
    setLoadingSubmit(true);
    formMethods.getValues();
    if (isEdit && eventId) {
      const updateResponse = await dispatch(updateEventData({ data: data, id: eventId }));
      if (updateResponse.type.includes("fulfilled")) {
        toastMessageSuccess("Evento actualizado correctamente");
        setTimeout(() => {
          navigate("/gestor_eventos");
        }, 2000);
      } else {
        toastMessageError(updateResponse.payload ?? "Error al actualizar el evento");
      }
    } else {
      const postResponse = await dispatch(sendEventData(data));
      if (postResponse.type.includes("fulfilled")) {
        toastMessageSuccess("Evento creado correctamente");
        setTimeout(() => {
          navigate("/gestor_eventos");
        }, 2000);
      } else {
        toastMessageError(postResponse.payload ?? "Error al crear el evento");
      }
    }
  };

  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("Error evento", error);
    setErrors(error);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const CustomStepIcon = ({
    active,
    completed,
    icon,
  }: {
    active: boolean;
    completed: boolean;
    icon: number;
  }) => {
    const stepIcons = [
      <LooksOne className="stepper-icon" />,
      <LooksTwo className="stepper-icon" />,
      <Looks3 className="stepper-icon" />,
      <Looks4 className="stepper-icon" />,
    ];

    return (
      <div className={`stepper-step ${active ? "active" : ""} ${completed ? "completed" : ""}`}>
        {stepIcons[icon - 1]}
      </div>
    );
  };

  const handleBackButton = () => {
    if (activeStep === 0) {
      navigate("/gestor_eventos");
    } else {
      window.scrollTo(0, 0);
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextButton = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (!(await validateStep1())) {
        return;
      }
    } else if (activeStep === 1) {
      if (!(await validateStep2())) {
        return;
      }
    } else if (activeStep === 2) {
      if (!(await validateStep3())) {
        return;
      }
    } else if (activeStep === 3) {
      if (!(await validateStep4())) {
        return;
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setActiveStep(activeStep + 1);
  };

  return (
    <Container sx={{ py: { md: 5, xs: 1 } }} maxWidth={false}>
      <Box className="stepper-container">
        <Stepper nonLinear activeStep={activeStep} connector={null}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepLabel
                className="stepper-step-container"
                onClick={handleStep(index)}
                StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit, onError)} className="form-container">
            <Paper className="form-paper" sx={{ p: { md: 5, xs: 1 }, mb: 5 }}>
              {/* Step 1 */}
              <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
                <BasicInformation isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <ResponsableContact isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Organizer isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <ProgramSchedule isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Referee isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
              </Box>
              {/* Step 2*/}
              <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
                <EventType isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Inscription isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
              </Box>
              {/* Step 3 */}
              <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
                <Lodging isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Picnic isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Streaming isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Transport isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
              </Box>
              {/* Step 4 */}
              <Box sx={{ display: activeStep === 3 ? "block" : "none" }}>
                <Sponsors isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Prizes isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Annex isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <Documentation isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
                <DataAndAuth isDisabled={isDisabled} />
                <Divider sx={{ my: 2 }} />
              </Box>
            </Paper>
            {errors && Object.keys(errors).length > 0 ? (
              <Typography className="error-message">*Revise los campos del formulario</Typography>
            ) : null}
            <Stack direction="row" justifyContent="center" gap={5} flexWrap="wrap">
              <Button className="form-button cancel" onClick={handleBackButton}>
                {activeStep === 0 ? "CANCELAR" : "ANTERIOR"}
              </Button>
              {isLastStep() ? (
                <Button type="submit" className="form-button" disabled={isDisabled}>
                  ENVIAR
                </Button>
              ) : (
                <Button
                  className="form-button"
                  onClick={(event) => handleNextButton(event)}
                  disabled={loadingSubmit}
                >
                  SIGUIENTE
                </Button>
              )}
            </Stack>
          </form>
        </FormProvider>
      )}
    </Container>
  );
};

export default CreateEditEvent;
