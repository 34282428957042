import "../../../components/formComponents/form-components.scss";

import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import Button from "../../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import { ORGANIZATOR_TYPES } from "../../../constants/constants";
import { getFormOptions, sendEventData } from "../../../context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import { TEvent, addEventSchema } from "../../../utils/schemas/event";
import PdfUpload from "../../pdfUpload/PdfUpload";

type Props = {
  isDisabled?: boolean;
};

const CreateEditEventSimple = ({ isDisabled }: Props) => {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  useSetPageName([
    { name: "Gestor de eventos", path: "/gestor_eventos" },
    {
      name: "Crear evento simple",
      path: "/gestor_eventos",
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { success } = useAppSelector((state) => state.formsOptions);

  //Errors PROVISIONAL
  const [errors, setErrors] = useState<FieldValues | null>(null);

  useEffect(() => {
    const getOptions = async () => {
      await dispatch(getFormOptions());
    };
    !success && getOptions();
  }, [dispatch, success]);

  const defaultValues = addEventSchema.parse({});
  const formMethods = useForm<TEvent>({
    resolver: zodResolver(addEventSchema),
    defaultValues: defaultValues,
  });
  const { register, control, setValue, watch } = formMethods;

  const onSubmit: SubmitHandler<TEvent> = async (data) => {
    setLoadingSubmit(true);
    formMethods.getValues();
    const postResponse = await dispatch(sendEventData(data));
    if (postResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Evento creado correctamente");
      setTimeout(() => {
        navigate("/gestor_eventos");
      }, 2000);
    } else {
      toastMessageError(postResponse.payload ?? "Error al crear el evento");
    }
  };

  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("Error evento", error);
    setErrors(error);
  };

  const handleBackButton = () => {
    navigate("/gestor_eventos");
  };

  //Update date
  const handleSetDate = (
    position: "startDate" | "endDate" | "weighingChangeLimitDate",
    value: Date | null
  ) => {
    setValue(position, value ? value : "");
  };

  const { categories, eventTypes, sports } = useAppSelector((state) => state.formsOptions);
  const selectedSportName = watch("sportCode");
  // Filtrar categorías por deporte
  const uniqueCategories = new Set();
  const filteredCategories = categories
    ?.filter((category) => category.sportCode === selectedSportName)
    .filter((category) => {
      if (uniqueCategories.has(category.categoryName)) {
        return false;
      } else {
        uniqueCategories.add(category.categoryName);
        return true;
      }
    });

  return (
    <Container sx={{ py: { md: 5, xs: 1 } }} maxWidth={false}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit, onError)} className="form-container">
          <Grid
            container
            spacing={3}
            component={Paper}
            sx={{ p: { md: 5, xs: 1 }, mb: 5 }}
            className="form-paper"
          >
            <Grid item xs={12}>
              <Typography className="form-section-title">Evento simple</Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Titulo de evento*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Titulo Evento*"
                  error={!!errors?.name}
                  {...register("name")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.name && (
                  <Typography className="form-input-error">{errors?.name?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Nombre official*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Nombre official*"
                  error={!!errors?.officialName}
                  {...register("officialName")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.officialName && (
                  <Typography className="form-input-error">
                    {errors?.officialName?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Código official*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Código official*"
                  error={!!errors?.code}
                  {...register("code")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.code && (
                  <Typography className="form-input-error">{errors?.code?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Nombre Organizador*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Nombre*"
                  error={!!errors?.organizatorName}
                  {...register("organizatorName")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.organizatorName && (
                  <Typography className="form-input-error">
                    {errors?.organizatorName?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Tipo Organizador*</Typography>
                <Controller
                  name="organizatorType"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      className="input-select form-select-field"
                      error={!!errors?.organizatorType}
                      slotProps={{ input: { readOnly: isDisabled } }}
                    >
                      <MenuItem value={""}>
                        <em>Tipo Organizador</em>
                      </MenuItem>
                      {ORGANIZATOR_TYPES.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors?.organizatorType && (
                  <Typography className="form-input-error">
                    {errors?.organizatorType?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Fecha Inicio*</Typography>
                <DatePicker
                  className="input-text form-text-field"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  value={watch("startDate") ? dayjs(watch("startDate")) : null}
                  onChange={(value: Dayjs | null) =>
                    handleSetDate("startDate", value?.toDate() ?? null)
                  }
                  readOnly={isDisabled}
                />
                {errors?.startDate && (
                  <Typography className="form-input-error">{errors?.startDate?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Fecha Fin*</Typography>
                <DatePicker
                  className="input-text form-text-field"
                  format="DD/MM/YYYY"
                  value={watch("endDate") ? dayjs(watch("endDate")) : null}
                  onChange={(value: Dayjs | null) =>
                    handleSetDate("endDate", value?.toDate() ?? null)
                  }
                  readOnly={isDisabled}
                />
                {errors?.endDate && (
                  <Typography className="form-input-error">{errors?.endDate?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid item md={6} xs={12}>
                <Typography className="form-input-label">Pdf*</Typography>
                <PdfUpload
                  onChange={(value: any) => setValue("circularPdf", value)}
                  disabled={isDisabled}
                  value={watch("circularPdf")}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Deporte*</Typography>
                <Controller
                  name="sportCode"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="input-select form-select-field"
                      error={!!errors?.sportCode}
                      defaultValue={""}
                      displayEmpty
                      slotProps={{
                        input: { readOnly: isDisabled },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>Deporte</em>
                      </MenuItem>
                      {sports?.map((sport) => {
                        return (
                          <MenuItem key={sport.id} value={sport.code}>
                            {sport.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors?.sportCode && (
                  <Typography className="form-input-error">{errors?.sportCode?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            {/* Categoría DE EVENTO */}
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Categoría*</Typography>
                <Controller
                  name="categoryCode"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="input-select form-select-field"
                      error={!!errors?.categoryCode}
                      displayEmpty
                      defaultValue={""}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue("stocks", []);
                      }}
                      slotProps={{
                        input: { readOnly: isDisabled },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>Categoría</em>
                      </MenuItem>
                      {filteredCategories
                        ?.filter((category) => category.sportCode === selectedSportName)
                        .map((category) => {
                          return (
                            <MenuItem key={category.id} value={category.categoryName}>
                              {category.categoryName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  )}
                />
                {errors?.eventType && (
                  <Typography className="form-input-error">{errors?.eventType?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            {/* TIPO DE EVENTO */}
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Tipo de evento*</Typography>
                <Controller
                  name="eventType"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="input-select form-select-field"
                      error={!!errors?.eventType}
                      displayEmpty
                      defaultValue={""}
                      slotProps={{
                        input: { readOnly: isDisabled },
                      }}
                    >
                      <MenuItem value={""}>
                        <em>Tipo de evento</em>
                      </MenuItem>
                      {eventTypes?.map((eventType) => {
                        return (
                          <MenuItem key={eventType.id} value={eventType.code}>
                            {eventType.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors?.eventType && (
                  <Typography className="form-input-error">{errors?.eventType?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} className="teams-check-container">
              <FormControl fullWidth>
                <Typography className="form-input-label">
                  Fecha limite cambio de categoría*
                </Typography>
                <DatePicker
                  className="input-text form-text-field"
                  format="DD/MM/YYYY"
                  minDate={dayjs()}
                  value={
                    watch("weighingChangeLimitDate")
                      ? dayjs(watch("weighingChangeLimitDate"))
                      : null
                  }
                  onChange={(value: Dayjs | null) =>
                    handleSetDate("weighingChangeLimitDate", value?.toDate() ?? null)
                  }
                  readOnly={isDisabled}
                />
                {errors?.weighingChangeLimitDate && (
                  <Typography className="form-input-error">
                    {errors?.weighingChangeLimitDate?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {errors && Object.keys(errors).length > 0 ? (
            <Typography className="error-message">*Revise los campos del formulario</Typography>
          ) : null}
          <Stack direction="row" justifyContent="center" gap={5} flexWrap="wrap">
            <Button className="form-button cancel" onClick={handleBackButton}>
              CANCELAR
            </Button>
            <Button type="submit" className="form-button" disabled={isDisabled || loadingSubmit}>
              {loadingSubmit ? "Cargando..." : "ENVIAR"}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
};

export default CreateEditEventSimple;
