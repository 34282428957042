import dayjs, { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { ChangeEvent } from "react";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TEvent } from "../../../../utils/schemas/event";
import ImageUpload from "../../../imageUpload/ImageUpload";
import SwitchWithIcons from "../../IconSwitch";
import { InfoOutlined } from "@mui/icons-material";

type Props = {
  isDisabled?: boolean;
};

const BasicInformation = ({ isDisabled }: Props) => {
  const { register, formState, setValue, getValues, watch, control } = useFormContext<TEvent>();
  const { errors } = formState;

  const { belts } = useAppSelector((state) => state.formsOptions);

  //Update date
  const handleSetDate = (position: "startDate" | "endDate", value: Date | null) => {
    setValue(position, value ? value : "");
  };

  //Update time
  const handleSetTime = (position: "startDate" | "endDate", value: Date | null) => {
    if (value) {
      const date = getValues(position);
      if (date) {
        const updatedDate = dayjs(date)
          .set("hour", dayjs(value).hour())
          .set("minute", dayjs(value).minute())
          .toDate();
        setValue(position, updatedDate);
      }
    }
  };

  const handleChangeReferee = (value: Date | null) => {
    if (value) {
      const date = getValues("refereeMeet");
      if (date) {
        const updatedDate = dayjs(date)
          .set("hour", dayjs(value).hour())
          .set("minute", dayjs(value).minute())
          .toDate();
        setValue("refereeMeet", updatedDate);
      }
    }
  };

  return (
    <Box className="form-section-container">
      <Grid container sx={{ flexDirection: "row", mb: 1 }}>
        <Typography>Evento visible</Typography>
        <Tooltip
          title="Marca esta casilla si quieres que el evento sea visible en la app."
          placement="top"
          arrow
          sx={{ marginRight: 2 }}
        >
          <InfoOutlined />
        </Tooltip>
        <SwitchWithIcons
          checked={watch("isVisible") || false}
          handleChange={(e) => setValue("isVisible", e.target.checked)}
        />
      </Grid>
      <Typography className="form-section-title">Datos del club</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }}>
        <Grid item md={8.5} xs={12}>
          <Grid container columnSpacing={4} rowGap={{ xs: 2 }} sx={{ my: 2 }}>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Titulo de evento*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Titulo Evento*"
                  error={!!errors?.name}
                  {...register("name")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.name && (
                  <Typography className="form-input-error">{errors?.name?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Nombre official*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Nombre official*"
                  error={!!errors?.officialName}
                  {...register("officialName")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.officialName && (
                  <Typography className="form-input-error">
                    {errors?.officialName?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Código official*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Código official*"
                  error={!!errors?.code}
                  {...register("code")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.code && (
                  <Typography className="form-input-error">{errors?.code?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Código actuación*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Código actuación*"
                  error={!!errors?.codeActuation}
                  {...register("codeActuation")}
                  InputProps={{ readOnly: isDisabled }}
                />
                {errors?.codeActuation && (
                  <Typography className="form-input-error">
                    {errors?.codeActuation?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3.5} xs>
          <Typography className="form-input-label">Imagen/Logo*</Typography>
          <ImageUpload
            url={getValues("posterUrl") || ""}
            multi={false}
            onImageSelect={(e) =>
              typeof e === "string" ? setValue("posterUrl", e) : setValue("posterUrl", "")
            }
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Descripción*</Typography>
            <TextField
              className="input-text"
              variant="outlined"
              placeholder="Descripción*"
              multiline
              rows={6}
              error={!!errors?.description}
              {...register("description")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.description && (
              <Typography className="form-input-error">{errors?.description?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Fecha Inicio*</Typography>
            <DatePicker
              className="input-text form-text-field"
              format="DD/MM/YYYY"
              //minDate={dayjs()}
              value={watch("startDate") ? dayjs(watch("startDate")) : null}
              onChange={(value: Dayjs | null) =>
                handleSetDate("startDate", value?.toDate() ?? null)
              }
              readOnly={isDisabled}
            />
            {errors?.startDate && (
              <Typography className="form-input-error">{errors?.startDate?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Hora Inicio*</Typography>
            <TimePicker
              timezone="system"
              className="input-text form-text-field"
              format="HH:mm"
              ampm={false}
              views={["hours", "minutes"]}
              value={watch("startDate") ? dayjs(watch("startDate")) : null}
              onChange={(value: Dayjs | null) =>
                handleSetTime("startDate", value?.toDate() ?? null)
              }
              readOnly={isDisabled}
            />
            {errors?.startTime && (
              <Typography className="form-input-error">{errors?.startTime?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Fecha Fin*</Typography>
            <DatePicker
              className="input-text form-text-field"
              format="DD/MM/YYYY"
              value={watch("endDate") ? dayjs(watch("endDate")) : null}
              onChange={(value: Dayjs | null) => handleSetDate("endDate", value?.toDate() ?? null)}
              readOnly={isDisabled}
            />
            {errors?.endDate && (
              <Typography className="form-input-error">{errors?.endDate?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Hora Fin*</Typography>
            <TimePicker
              timezone="system"
              className="input-text form-text-field"
              format="HH:mm"
              ampm={false}
              views={["hours", "minutes"]}
              value={watch("endDate") ? dayjs(watch("endDate")) : null}
              onChange={(value: Dayjs | null) => handleSetTime("endDate", value?.toDate() ?? null)}
              readOnly={isDisabled}
            />
            {errors?.endTime && (
              <Typography className="form-input-error">{errors?.endTime?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Lugar*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Ubicación*"
              error={!!errors?.location}
              {...register("location")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.location && (
              <Typography className="form-input-error">{errors?.location?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">URL de Google Maps</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="URL de Google Maps"
              error={!!errors?.mapsUrl}
              {...register("mapsUrl")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.mapsUrl && (
              <Typography className="form-input-error">{errors?.mapsUrl?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Cantidad de tatamis*</Typography>
            <Controller
              name="nTatamis"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  type="number"
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Cantidad de tatamis*"
                  error={!!errors?.nTatamis}
                  inputProps={{ min: 0 }}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  {...field}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const newValue =
                      inputValue === "" || inputValue === null ? 0 : parseInt(inputValue);
                    setValue("nTatamis", newValue);
                  }}
                  InputProps={{ readOnly: isDisabled }}
                />
              )}
            />
            {errors?.nTatamis && (
              <Typography className="form-input-error">{errors?.nTatamis?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Hora Reunion de arbitraje*</Typography>
            <TimePicker
              className="input-text form-text-field"
              format="HH:mm"
              ampm={false}
              timezone="system"
              views={["hours", "minutes"]}
              value={watch("refereeMeet") ? dayjs(watch("refereeMeet")) : null}
              onChange={(value: Dayjs | null) => handleChangeReferee(value?.toDate() ?? null)}
              readOnly={isDisabled}
            />
            {errors?.refereeMeet && (
              <Typography className="form-input-error">{errors?.refereeMeet?.message}</Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Edad minima*</Typography>
            <Controller
              name="minAge"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  type="number"
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Edad minima*"
                  error={!!errors?.minAge}
                  {...field}
                  inputProps={{ min: 0 }}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const newValue =
                      inputValue === "" || inputValue === null ? 0 : parseInt(inputValue);
                    setValue("minAge", newValue);
                  }}
                  InputProps={{ readOnly: isDisabled }}
                />
              )}
            />
            {errors?.minAge && (
              <Typography className="form-input-error">{errors?.minAge?.message}</Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <FormControl fullWidth>
              <Typography className="form-input-label">Cinturón mínimo*</Typography>
              <Controller
                name="minBeltId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    error={!!errors?.minBeltId}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Cinturón mínimo</em>
                    </MenuItem>
                    {belts &&
                      [...belts]
                        ?.sort((a, b) => a?.position - b?.position)
                        .map((belt) => {
                          return (
                            <MenuItem key={belt.id} value={belt.id}>
                              {belt.name}
                            </MenuItem>
                          );
                        })}
                  </Select>
                )}
              />
              {errors?.minBeltId && (
                <Typography className="form-input-error">{errors?.minBeltId.message}</Typography>
              )}
            </FormControl>
          </FormControl>
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Switch
            className="form-switch"
            checked={watch("ranking") === 1 || false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue("ranking", e.target.checked ? 1 : 0)
            }
            disabled={isDisabled}
          />
        }
        label="Puntúa para ranking"
        labelPlacement="start"
        sx={{ m: 0, gap: 2 }}
      />
    </Box>
  );
};

export default BasicInformation;
