import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import useSetPageName from "../../../utils/hooks/useSetPageName";
import "./sport-card.scss";
import { GENRES } from "../../../constants/constants";
import { Link, useParams } from "react-router-dom";
import useQuerySportCard from "../../../utils/hooks/useQuerySportCard";

const SportCard = () => {
  const { id: userId } = useParams();

  useSetPageName([
    { name: "Gestor de Usuarios", path: "/gestor_usuarios" },
    { name: "Ver Usuario", path: `/users/${userId}` },
    { name: "Ficha deportista", path: "/users/sport-card" },
  ]);

  const { data: user } = useQuerySportCard(userId ?? "");
  return (
    <Paper elevation={4} className="sport-card-container">
      <Grid container>
        <Grid item xs={12} container className="sport-card-container-first" columnSpacing={4}>
          <Grid item xs={6} md={3}>
            <Avatar
              alt="imagen perfil del usuario"
              className="sport-card-container-avatar"
              src={user?.userInfo?.avatar_url}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 3, md: 2 } }}>
            <List>
              <ListItem>
                <ListItemText
                  primary={<Typography className="sport-card-list-text">Apellidos:</Typography>}
                  secondary={user?.userInfo?.surname}
                  className="sport-card-list-item"
                />
              </ListItem>
              <Divider variant="middle" component="li" />
              <ListItem>
                <ListItemText
                  primary={<Typography className="sport-card-list-text">Nombre:</Typography>}
                  secondary={user?.userInfo?.name}
                  className="sport-card-list-item"
                />
              </ListItem>
              <Divider variant="middle" component="li" />
              <ListItem>
                <ListItemText
                  primary={<Typography className="sport-card-list-text">Genero:</Typography>}
                  secondary={
                    user?.userInfo?.genre === GENRES.MEN
                      ? "Masculino"
                      : user?.userInfo?.genre === GENRES.WOMEN
                      ? "Femenino"
                      : ""
                  }
                  className="sport-card-list-item"
                />
              </ListItem>
              <Divider variant="middle" component="li" />
              <ListItem>
                <ListItemText
                  primary={<Typography className="sport-card-list-text">Edad:</Typography>}
                  secondary={user?.userInfo?.age}
                  className="sport-card-list-item"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} md={3} sx={{ order: { xs: 2, md: 3 } }}>
            <Link
              to={`/federacion/${user?.userInfo.federation_id}`}
              style={{ textDecoration: "none", color: "#000" }}
            >
              {" "}
              Federación: {user?.userInfo.federation_code}{" "}
            </Link>{" "}
            <br />
            <Link
              to={`/clubs/${user?.userInfo.club_id}/ver`}
              style={{ textDecoration: "none", color: "#000" }}
            >
              Club: {user?.userInfo.club_code}
            </Link>
          </Grid>
          <Grid item xs={6} md={3} sx={{ order: { xs: 2, md: 3 } }}></Grid>
        </Grid>
        {user?.resultsData && user.resultsData.length > 0 ? (
          <>
            <Divider className="sport-card-divider" />
            <Typography>Deportes</Typography>
            <Grid item xs={12} container columnSpacing={4}>
              {user.resultsData.map((sport, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography className="sport-card-list-text">Categorías:</Typography>
                        }
                        secondary={sport.weights.join(", ")}
                        className="sport-card-list-item"
                      />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                      <ListItemText
                        primary={<Typography className="sport-card-list-text">Deporte:</Typography>}
                        secondary={sport.sport}
                        className="sport-card-list-item"
                      />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography className="sport-card-list-text">Cinturón:</Typography>
                        }
                        secondary={sport.belt}
                        className="sport-card-list-item"
                      />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                      <ListItemText
                        primary={<Typography className="sport-card-list-text">Club:</Typography>}
                        secondary={sport.club_code}
                        className="sport-card-list-item"
                      />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                      <ListItemText
                        primary={<Typography className="sport-card-list-text">Ranking:</Typography>}
                        // secondary={sport.ranking}
                        className="sport-card-list-item"
                      />
                    </ListItem>
                  </List>
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default SportCard;
