import { toastMessageError } from "../components/common/toastMessage";
import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { TRankingFilters } from "../utils/schemas/rankings";

export const getRankings = async ({
  search,
  date,
  weight,
  genre,
  page,
  perPage,
  sportCode,
  category,
}: TRankingFilters) => {
  try {
    const urlParams = new URLSearchParams();
    if (search && search !== "") urlParams.append("search", search);
    if (date) urlParams.append("date", date);
    if (weight && weight !== "") urlParams.append("weight", weight);
    if (genre && genre !== "") urlParams.append("genre", genre);
    if (page) urlParams.append("page", page.toString());
    if (perPage) urlParams.append("perPage", perPage.toString());
    if (sportCode) urlParams.append("sportCode", sportCode);
    if (category && category !== "") urlParams.append("category", category);

    const { data } = await http.get(`${API_URL}${SERVICE.RANKINGS}?${urlParams}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
};

export const generateRanking = async (body: {
  toDate: string;
  sportCode: string;
  categoryCode: string;
}) => {
  try {
    const { toDate, sportCode, categoryCode } = body;
    const { data } = await http.get(
      `${API_URL}${SERVICE.RANKINGS}/generate?toDate=${toDate}&categoryCode=${categoryCode}&sportCode=${sportCode}`
    );

    return data;
  } catch (error: any) {
    toastMessageError(error.response.data.error ?? "Error en servidor");
  }
};
