import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import LocationMarker from "./LocationMarker";
import SearchField from "./Search";
import { type LatLngTuple } from "leaflet";

const ZGZ_CENTER: LatLngTuple = [41.6488226, -0.8890853];

interface MapContentProps {
  lat: number | null;
  lng: number | null;
  marker: boolean;
  isDisabled: boolean;
  handleChangeLatLong: (lat: number, lng: number) => void;
}

const MapContent: React.FC<MapContentProps> = ({
  lat,
  lng,
  marker,
  isDisabled,
  handleChangeLatLong,
}) => {
  const [position, setPosition] = useState<LatLngTuple | null>(null);
  const mapCenter: LatLngTuple | null = lat && lng ? [lat, lng] : null;

  useEffect(() => {
    if (lat !== null && lng !== null) {
      setPosition([lat, lng]);
    }
  }, [lat, lng]);

  const handleSetPosition = (lat: number, lng: number) => {
    setPosition([lat, lng]);
    handleChangeLatLong(lat, lng);
  };

  const prov = new OpenStreetMapProvider();

  return (
    <MapContainer
      id="map"
      center={mapCenter ? mapCenter : ZGZ_CENTER}
      zoom={15}
      style={{ height: "100%", width: "100%" }}
      dragging={!isDisabled}
      scrollWheelZoom={!isDisabled}
      doubleClickZoom={!isDisabled}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {marker && (
        <LocationMarker
          position={position}
          handleSetPosition={handleSetPosition}
          isDisabled={isDisabled}
        />
      )}
      <SearchField
        provider={prov}
        showMarker={false}
        retainZoomLevel={false}
        animateZoom={true}
        autoClose={false}
        searchLabel="Buscar dirección"
        keepResult={true}
      />
    </MapContainer>
  );
};

export default MapContent;
