import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { INSCRIPTION_STATUS } from "../../constants/constants";
import { TInscription } from "../schemas/inscriptions";
import { TInscriptionFilters } from "../schemas/listFilters";
import { useQueryInscriptionsValidated } from "./useQueryInscriptions";

const headersResults = [
  "CodigoEvento",
  "Peso",
  "CodigoFederacion",
  "IdUsuario",
  "AnioNacimiento",
  "Nombre",
  "Posicion",
  "HaGanadoCombates",
];

const useDownloadInscriptions = (eventId: string, eventCode: string) => {
  const [dataToDownload, setDataToDownload] = useState<any[]>([]);
  const [usersCategoryId, setUsersCategoryId] = useState<{ userId: string; categoryId: string }[]>([
    { userId: "", categoryId: "" },
  ]);

  const queryFilters = {
    categoryCode: "",
    clubId: "",
    federationId: "",
    eventId: eventId,
    genre: "",
    status: INSCRIPTION_STATUS.APPROVED_OR_RESERVED,
    search: "",
    page: 1,
    perPage: 1000,
  } as TInscriptionFilters;

  const { data: inscriptions } = useQueryInscriptionsValidated(queryFilters);

  useEffect(() => {
    if (inscriptions) {
      const data = inscriptions.data.map((inscription: TInscription) => {
        setUsersCategoryId((prevState) => [
          ...prevState,
          { userId: inscription.userId, categoryId: inscription.categoryId },
        ]);
        return {
          CodigoEvento: eventCode,
          Peso: inscription.categoryWeight,
          CodigoFederacion: inscription.federationCode,
          IdUsuario: inscription.userId,
          AnioNacimiento: dayjs(inscription.bornAt).year(),
          Apellido: inscription.surname,
          Nombre: inscription.name,
          Posicion: "",
          HaGanadoCombateshasWonFights: "",
        };
      });
      setDataToDownload(data);

      //Clear the states
      return () => {
        setDataToDownload([]);
        setUsersCategoryId([{ userId: "", categoryId: "" }]);
      };
    }
  }, [inscriptions]);

  return { headersResults, dataToDownload, usersCategoryId };
};

export default useDownloadInscriptions;
