import { Avatar, Container, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./user-drawer.scss";
import { TUserInfo } from "../../../utils/schemas/userDashboard";
import { TUserSimple } from "../../../utils/schemas/user";

type Props = {
  toggleUserDrawer: () => void;
  userInfo: TUserInfo;
  handleLogout: () => void;
  userData: TUserSimple | null | undefined;
};

const UserDrawer = ({ toggleUserDrawer, userInfo, handleLogout, userData }: Props) => {
  // const navigate = useNavigate();

  // const handelNavLinkClick = (path: string) => {
  //   toggleUserDrawer();
  //   navigate(path);
  // };

  return (
    <Container className="user-drawer-container">
      <Avatar
        className="user-drawer-avatar"
        alt="Imagen avatar del usuario"
        src={userData && userData.avatarUrl ? userData.avatarUrl : "images/logos/03.png"}
      />
      <List className="user-drawer-list">
        <ListItem className="user-drawer-mail">{userInfo?.email}</ListItem>
        {/* <ListItemButton className={`list-item`} onClick={() => handelNavLinkClick("perfil")}>
          <ListItemText primary="Mi Perfil" className="item-text" />
        </ListItemButton> */}
        <ListItemButton className={`list-item`} onClick={handleLogout}>
          <ListItemText primary="Cerrar sesion" className="item-text" />
        </ListItemButton>
      </List>
    </Container>
  );
};

export default UserDrawer;
