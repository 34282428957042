import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 2 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const RQ_KEY = {
  ADMINS: "ADMINS",
  ALL_INSCRIPTIONS_PENDING: "ALL_INSCRIPTIONS_PENDING",
  ALL_INSCRIPTIONS_REJECTED: "ALL_INSCRIPTIONS_REJECTED",
  ALL_INSCRIPTIONS_VALIDATED: "ALL_INSCRIPTIONS_VALIDATED",
  ALL_INSCRIPTIONS: "ALL_INSCRIPTIONS",
  CARDS: "CARDS",
  CITIES: "CITIES",
  CITY: "CITY",
  CLUB_LIST: "CLUB_LIST",
  CLUB: "CLUB",
  CLUBS: "CLUBS",
  CONSUME_TICKET: "CONSUME_TICKET",
  COUNTRIES: "COUNTRIES",
  EVENT: "EVENT",
  EVENTS: "EVENTS",
  FAVORITES: "FAVORITES",
  FEDERATION_ID: "FEDERATION_ID",
  FEDERATION: "FEDERATION",
  FEDERATIONS_LIST: "FEDERATIONS_LIST",
  FEDERATIONS: "FEDERATIONS",
  INSCRIPTIONS: "INSCRIPTIONS",
  PREMISES: "ORGANIZATIONS",
  PROFILE_PIC: "PROFILE_PIC",
  REGIONS: "REGIONS",
  REQUEST_CARD: "REQUEST_CARD",
  SPORT_CARD: "SPORT_CARD",
  STUDENT: "STUDENT",
  USER: "USER",
  USERS: "USERS",
  RANKINGS: "RANKINGS",
  CENSO: "CENSO",
};
