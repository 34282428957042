import { FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ADMIN_ROLES } from "../../../../constants/constants";
import { TUser } from "../../../../utils/schemas/user";
import ImageUpload from "../../../imageUpload/ImageUpload";
import { useAppSelector } from "../../../../context/hooks/hooks";

type Props = {
  isDisabled?: boolean;
};

const RolesInfo = ({ isDisabled }: Props) => {
  const { register, formState, setValue, watch, getValues } = useFormContext<TUser>();
  const { errors } = formState;

  const handleChangeRol = (e: any) => {
    const value = e.target.value;
    setValue("roleIds", [value]);
  };

  const { roles } = useAppSelector((state) => state.formsOptions);

  return (
    <Grid>
      <Grid container columnSpacing={4} rowSpacing={4}>
        <Grid item xs={12} md={6}>
          <Typography className="add-user-form-title" sx={{ marginBottom: 2 }}>
            Tipo de rol
          </Typography>
          <FormControl fullWidth>
            <Typography className="form-input-label">Rol*</Typography>
            <Select
              displayEmpty
              className="input-select form-select-field"
              error={!!errors?.roleIds}
              onChange={handleChangeRol}
              value={watch("roleIds") || []}
              slotProps={{ input: { readOnly: isDisabled } }}
            >
              <MenuItem value="" disabled>
                Rol
              </MenuItem>
              {ADMIN_ROLES?.map((role) => (
                <MenuItem
                  key={role.label}
                  value={roles?.find((rol) => rol.name === role.value)?.id ?? ""}
                >
                  {role.label}
                </MenuItem>
              ))}
            </Select>
            {errors?.roleIds && (
              <Typography className="form-input-error">{errors?.roleIds?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={12} md={6} sx={{ marginBottom: 2 }}>
            <Typography className="add-user-form-title" sx={{ marginBottom: 2 }}>
              Avatar(opcional)
            </Typography>
            <ImageUpload
              multi={false}
              onImageSelect={(e) =>
                typeof e === "string" ? setValue("avatarUrl", e) : setValue("avatarUrl", "")
              }
              disabled={isDisabled}
              url={getValues("avatarUrl") || ""}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: 2 }}>
            <Typography className="add-user-form-title" sx={{ marginBottom: 2 }}>
              Profesión(opcional)
            </Typography>
            <FormControl fullWidth>
              <Typography className="form-input-label">Profesión*</Typography>
              <TextField
                className="input-text form-text-field"
                variant="outlined"
                placeholder="Profesión*"
                error={!!errors?.profession}
                {...register("profession")}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.profession && (
                <Typography className="form-input-error">{errors?.profession?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RolesInfo;
