import { InputLabel, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { useId } from "react";
import { INPUTMINWIDTH } from "../../constants/input";
import "./text-input.scss";

type Props = TextFieldProps & {
  minWidth?: "sm" | "md" | "full-width";
  hasError?: string | boolean;
};

const TextInput = ({
  title,
  required,
  id,
  sx,
  className = "",
  minWidth = "md",
  hasError,
  ...props
}: Props) => {
  const customId = useId();
  return (
    <Stack className={`text-input-container`}>
      <InputLabel className="text-input-label" required={required} htmlFor={id ?? customId}>
        {title}
      </InputLabel>
      <TextField
        variant="outlined"
        className={`text-input-textfield ${className}`}
        id={id ?? customId}
        required={required}
        error={!!hasError}
        {...props}
        sx={{
          width: `min(${INPUTMINWIDTH[minWidth]}, 100%)`,
          color: "red",
          ...sx,
        }}
      ></TextField>
      {hasError && (
        <Typography className="text-input-error" component="p">
          {hasError}
        </Typography>
      )}
    </Stack>
  );
};

export default TextInput;
