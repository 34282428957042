import { FormControl, Grid, IconButton, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { CATEGORY_NAMES, INSCRIPTIONS_ROLES, ROLES } from "../../../../constants/constants";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TOrganization } from "../../../../utils/schemas/admins";
import SelectInput from "../../../selectInput/SelectInput";

import { Search } from "@mui/icons-material";
import { TClubList } from "../../../../utils/schemas/club";
import { TFederationsList } from "../../../../utils/schemas/federations";
import TextInput from "../../../textInput/TextInput";
import "./create-inscription-filters.scss";
import { FilterState } from "../../../../utils/schemas/listFilters";

type Props = {
  selectedOrganization: TOrganization;
  setSelectedOrganization: (organization: TOrganization) => void;
  debounceSearch: (value: string) => void;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
  filterState: FilterState;
  handleFilterChange: (value: string | unknown, filterName: keyof FilterState) => void;
};

type organization = {
  name: string;
  id: string;
};

const CreateFilters = ({
  setSelectedOrganization,
  selectedOrganization,
  debounceSearch,
  clubs,
  federations,
  filterState,
  handleFilterChange,
}: Props) => {
  const [organizations, setOrganizations] = useState<organization[]>([]);

  const { userInfo } = useAppSelector((state) => state.auth);

  const userRole = userInfo?.roles?.length === 1 ? userInfo?.roles[0] : "";

  //Set the rol of the user
  useEffect(() => {
    setSelectedOrganization({
      id: "",
      type: userRole !== ROLES.SUPER_ADMIN ? userRole : "",
      categoryId: selectedOrganization.categoryId || "",
    });
  }, []);

  //Set organizations for the select
  useEffect(() => {
    if (selectedOrganization.type === ROLES.CLUB) {
      setOrganizations(
        clubs?.map((club) => ({ name: club.name, id: club.id, categoryId: "" })) || []
      );
    } else if (selectedOrganization.type === ROLES.FEDERATION) {
      setOrganizations(
        federations?.map((federation) => ({
          name: federation.name,
          id: federation.id,
          categoryId: "",
        })) || []
      );
    }
  }, [selectedOrganization.type]);

  const handleChangeRol = (e: SelectChangeEvent<unknown>) => {
    setSelectedOrganization({
      id: "",
      type: e.target.value as string,
      categoryId: selectedOrganization.categoryId,
    });
  };

  const handleChangeOrganization = (e: SelectChangeEvent<unknown>) => {
    setSelectedOrganization({
      id: e.target.value as string,
      type: selectedOrganization.type,
      categoryId: selectedOrganization.categoryId,
    });
  };

  const handleChangeUserState = (e: SelectChangeEvent<unknown>) => {
    handleFilterChange(e.target.value as string, "isSportilyActive");
  };

  //Clear states on dismount
  useEffect(() => {
    return () => {
      setSelectedOrganization({ id: "", type: "", categoryId: "" });
    };
  }, []);

  return (
    <Grid item container columnSpacing={4} rowSpacing={2} className="inscription-filters-container">
      <Grid className="search-box" item>
        <IconButton>
          <Search className="inscriptions-header-searchInput-icon" />
        </IconButton>
        <TextInput
          placeholder="Buscar"
          minWidth="full-width"
          onChange={(e) => debounceSearch(e.target.value)}
        />
      </Grid>
      {(userInfo?.roles && userInfo?.roles?.length > 1) ||
      userInfo?.roles.includes(ROLES.SUPER_ADMIN) ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={selectedOrganization.type}
              rounded="md"
              onChange={handleChangeRol}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Rol para inscribir" },
                ...INSCRIPTIONS_ROLES.map((role) => ({
                  value: role,
                  label: role,
                })),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      {(clubs && clubs?.length > 1) || (federations && federations?.length > 1) ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={selectedOrganization.id}
              rounded="md"
              onChange={handleChangeOrganization}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Organización" },
                ...organizations.map((organization) => ({
                  value: organization.id,
                  label: organization.name,
                })),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item>
        <FormControl>
          <SelectInput
            value={filterState.isSportilyActive}
            rounded="md"
            onChange={handleChangeUserState}
            displayEmpty
            defaultValue=""
            options={[
              { value: "", label: "Estado deportista" },
              { value: "true", label: "Activo" },
              { value: "false", label: "Inactivo" },
            ]}
          ></SelectInput>
        </FormControl>
      </Grid>
      {userInfo?.roles.includes(ROLES.FEDERATION) ? (
        <>
          <Grid item>
            <SelectInput
              rounded="md"
              defaultValue=""
              title="Clubs: Todos"
              options={[
                { value: "", label: "Todos los Clubs" },
                ...(clubs && clubs.length > 0
                  ? clubs.map((club) => ({
                      value: club.id,
                      label: club.name,
                    }))
                  : []),
              ]}
              onChange={(e) => handleFilterChange(e.target.value, "clubId")}
              value={filterState.clubId}
            />
          </Grid>
          <Grid item>
            <SelectInput
              rounded="md"
              defaultValue=""
              title="Categoría: Todas"
              options={[
                { value: "", label: "Todas las categorías" },
                ...CATEGORY_NAMES.map((category) => ({
                  value: category,
                  label: category,
                })),
              ]}
              onChange={(e) => handleFilterChange(e.target.value, "categoryCode")}
              value={filterState.categoryCode}
            />
          </Grid>
        </>
      ) : null}

      {/* <Grid item>
        <FormControl>
          <SelectInput
            value={selectedOrganization.categoryId}
            rounded="md"
            onChange={handleChangeCategory}
            displayEmpty
            defaultValue=""
            options={[
              { value: "", label: "Categoría" },
              ...filteredCategories?.map((category) => ({
                value: category.id,
                label: category.categoryName + " _ " + category.genre + " _ " + category.weight,
              })),
            ]}
          ></SelectInput>
        </FormControl>
      </Grid> */}
    </Grid>
  );
};

export default CreateFilters;
