import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { AddFederationSchemaType } from "../../../../utils/schemas/federations";

type Props = {
  isDisabled?: boolean;
};

const BillingData = ({ isDisabled }: Props) => {
  const { register, formState } = useFormContext<AddFederationSchemaType>();

  const { errors } = formState;

  return (
    <Box className="add-federation-section-container">
      <Typography className="add-federation-form-title">Datos de facturación</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="add-federation-input-label">Denominación fiscal*</Typography>
            <TextField
              className="input-text add-federation-text-field"
              id="address"
              variant="outlined"
              placeholder="Denominación fiscal*"
              required
              error={!!errors?.fiscalName}
              {...register("fiscalName")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.fiscalName && (
              <Typography className="form-input-error">{errors?.fiscalName?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="add-federation-input-label">IBAN*</Typography>
            <TextField
              className="input-text add-federation-text-field"
              id="address"
              variant="outlined"
              placeholder="IBAN*"
              required
              error={!!errors?.iban}
              {...register("iban")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.iban && (
              <Typography className="form-input-error">{errors?.iban?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="add-federation-input-label">CIF*</Typography>
            <TextField
              className="input-text add-federation-text-field"
              id="address"
              variant="outlined"
              placeholder="CIF*"
              required
              error={!!errors?.cif}
              {...register("cif")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.cif && (
              <Typography className="form-input-error">{errors?.cif?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingData;
