import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { TUserSimple } from "../../utils/schemas/user";
import { validateUser } from "../../services/users";
import { toastMessageError, toastMessageSuccess } from "../common/toastMessage";
import CustomDialog from "../customDialog/CustomDialog";

type Props = {
  users: TUserSimple[] | undefined;
  refetch: () => void;
};

const UsersPending = ({ users, refetch }: Props) => {
  const [openValidate, setOpenValidate] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>("");

  const handleOpenValidate = (event: MouseEvent, id: string) => {
    event.stopPropagation();
    setOpenValidate(true);
    setSelectedUser(id);
  };

  const handleValidateUser = async () => {
    const sendValidation = await validateUser(selectedUser);
    if (sendValidation === "success") {
      toastMessageSuccess("Usuario validado correctamente");
      refetch();
    } else {
      toastMessageError("Error al validar usuario");
    }
    setOpenValidate(false);
  };

  const handleDeleteButtonClick = (event: MouseEvent, id: string) => {
    console.log("Eliminar usuario", id);
    event.stopPropagation();
  };

  return (
    <Paper elevation={4} className="container-user-list">
      <TableContainer>
        <Table>
          <TableHead className="user-user-head">
            <TableRow className="user-no-wrap">
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>CNR</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user.id} className="user-no-wrap">
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.surname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.cnr}</TableCell>
                <TableCell className="user-no-wrap">
                  <Tooltip title="Validar usuario" placement="top" arrow>
                    <IconButton
                      className="user-icon-button-accept"
                      onClick={(e) => handleOpenValidate(e, user.id)}
                    >
                      <CheckCircleOutline fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Eliminar Usuario" placement="top" arrow>
                    <IconButton
                      className="user-icon-button-cancel"
                      onClick={(e) => handleDeleteButtonClick(e, user.id)}
                    >
                      <CancelOutlined fontSize="inherit" />
                    </IconButton>
                  </Tooltip> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDialog
        title="Validar Usuario"
        content="¿Estás seguro de validar este usuario?"
        isOpen={openValidate}
        onClose={() => setOpenValidate(false)}
        onAccept={handleValidateUser}
      />
    </Paper>
  );
};
export default UsersPending;
