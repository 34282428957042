import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { AddCircleOutline, Delete } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";
import ImageUpload from "../../../imageUpload/ImageUpload";

type Props = {
  isDisabled?: boolean;
};

const Lodging = ({ isDisabled }: Props) => {
  const { register, formState, setValue, getValues, watch } = useFormContext<TEvent>();
  const { errors } = formState;
  const hotelRooms = watch("hotelRooms", [{ roomCapacity: 0, price: 0, roomType: "" }]);
  const [lodging, setLodging] = useState<boolean>(false);

  useEffect(() => {
    if (getValues("hotelName")) {
      setLodging(true);
    }
  }, []);

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLodging(e.target.checked);
  };

  const handleAddHotelRoom = () => {
    const getHotelRoom = getValues("hotelRooms");
    if (getHotelRoom) {
      setValue("hotelRooms", [...getHotelRoom, { roomCapacity: 0, price: 0, roomType: "" }]);
    } else {
      setValue("hotelRooms", [{ roomCapacity: 0, price: 0, roomType: "" }]);
    }
  };

  const handleRemoveHotelRoom = (index: number) => {
    if (hotelRooms) {
      setValue(
        "hotelRooms",
        [...hotelRooms].filter((_, i) => i !== index)
      );
    }
  };

  return (
    <Box className="form-section-container">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography className="form-section-title">Alojamiento</Typography>
        <Switch
          onChange={handleSwitchChange}
          className="form-switch"
          checked={lodging}
          disabled={isDisabled}
        />
      </Stack>
      {lodging && (
        <>
          <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <Grid item md={8.5} xs={12}>
              <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Nombre Hotel</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Nombre Hotel*"
                      error={!!errors?.hotelName}
                      {...register("hotelName")}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                    />
                    {errors?.hotelName && (
                      <Typography className="form-input-error">
                        {errors?.hotelName?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <Typography className="form-input-label">Descripción</Typography>
                <TextField
                  className="input-text"
                  variant="outlined"
                  placeholder="Descripción*"
                  multiline
                  rows={4}
                  error={!!errors?.hotelDescription}
                  {...register("hotelDescription")}
                  InputProps={{
                    readOnly: isDisabled,
                  }}
                />
                {errors?.hotelDescription && (
                  <Typography className="form-input-error">
                    {errors?.hotelDescription?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3.5} xs>
              <Typography className="form-input-label">Imagen de poster</Typography>
              <ImageUpload
                url={getValues("hotelImage") || ""}
                multi={false}
                onImageSelect={(e) =>
                  typeof e === "string" ? setValue("hotelImage", e) : setValue("hotelImage", "")
                }
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={4}
            rowGap={{ xs: 2, md: 4 }}
            sx={{ my: 4 }}
            alignItems="center"
          >
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Dirección</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Dirección*"
                  error={!!errors?.hotelAddress}
                  {...register("hotelAddress")}
                  InputProps={{
                    readOnly: isDisabled,
                  }}
                />
                {errors?.hotelAddress && (
                  <Typography className="form-input-error">
                    {errors?.hotelAddress?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">URL de Google Maps</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="URL de Google Maps*"
                  error={!!errors?.hotelMapsUrl}
                  {...register("hotelMapsUrl")}
                  InputProps={{
                    readOnly: isDisabled,
                  }}
                />
                {errors?.hotelMapsUrl && (
                  <Typography className="form-input-error">
                    {errors?.hotelMapsUrl?.message}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {hotelRooms?.map((_, index) => {
              return (
                <Fragment key={`hotelRoom - ${index}`}>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="form-input-label">Tipo Habitación</Typography>
                      <TextField
                        className="input-text form-text-field"
                        variant="outlined"
                        placeholder="Tipo Habitación*"
                        error={!!errors?.hotelRooms?.[index]?.roomType}
                        {...register(`hotelRooms.${index}.roomType`)}
                        InputProps={{
                          readOnly: isDisabled,
                        }}
                      />
                      {errors?.hotelRooms?.[index]?.roomType && (
                        <Typography className="form-input-error">
                          {errors?.hotelRooms?.[index]?.roomType?.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="form-input-label">Precio</Typography>
                      <TextField
                        type="number"
                        className="input-text form-text-field"
                        variant="outlined"
                        inputProps={{ min: 0 }}
                        error={!!errors?.hotelRooms?.[index]?.price}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        placeholder="Precio*"
                        value={hotelRooms[index]?.price || 0}
                        {...(register(`hotelRooms.${index}.price`),
                        {
                          onChange: (e) => {
                            setValue(`hotelRooms.${index}.price`, parseFloat(e.target.value));
                          },
                        })}
                        InputProps={{
                          readOnly: isDisabled,
                        }}
                      />
                      {errors?.hotelRooms?.[index]?.price && (
                        <Typography className="form-input-error">
                          {errors?.hotelRooms?.[index]?.price?.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="form-input-label">
                        Limite personas para la habitación
                      </Typography>
                      <TextField
                        type="number"
                        className="input-text form-text-field"
                        variant="outlined"
                        placeholder="Limite*"
                        error={!!errors?.hotelRooms?.[index]?.roomCapacity}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        value={hotelRooms[index]?.roomCapacity || 0}
                        {...(register(`hotelRooms.${index}.roomCapacity`),
                        {
                          onChange: (e) => {
                            setValue(`hotelRooms.${index}.roomCapacity`, parseInt(e.target.value));
                          },
                        })}
                        inputProps={{ min: 0 }}
                        InputProps={{
                          readOnly: isDisabled,
                        }}
                      />
                      {errors?.hotelRooms?.[index]?.roomCapacity && (
                        <Typography className="form-input-error">
                          {errors?.hotelRooms?.[index]?.roomCapacity?.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <IconButton onClick={() => handleRemoveHotelRoom(index)} disabled={isDisabled}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
          <ButtonBase
            onClick={handleAddHotelRoom}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              width: "150px",
            }}
            disabled={isDisabled}
          >
            <AddCircleOutline /> Añadir Habitación
          </ButtonBase>
        </>
      )}
    </Box>
  );
};

export default Lodging;
