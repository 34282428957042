import { Box, FormControl, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TClub } from "../../../../utils/schemas/club";
import MapContent from "../../../map/Map";

type Props = {
  isDisabled?: boolean;
};

const ClubMap = ({ isDisabled = false }: Props) => {
  const { watch, setValue } = useFormContext<TClub>();

  const latitude = watch("latitude") ?? 0;
  const longitude = watch("longitude") ?? 0;

  const handleChangeLatLong = (lat: number, lng: number) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  return (
    <Box className="form-section-container" sx={{ mb: 2 }}>
      <Typography className="form-section-title" sx={{ mb: 2 }}>
        Ubicación
      </Typography>
      <FormControl fullWidth>
        <Grid sx={{ height: "250px", width: "100%" }}>
          <MapContent
            lat={latitude}
            lng={longitude}
            marker
            isDisabled={isDisabled}
            handleChangeLatLong={handleChangeLatLong}
          />
        </Grid>
      </FormControl>
    </Box>
  );
};

export default ClubMap;
