import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getClubs } from "../../services/clubs";
import { TQueryClubs } from "../schemas/club";
import { FilterState } from "../schemas/listFilters";

export default function useQueryClubs({
  page,
  perPage,
  search,
  autonomy,
  province,
  federationId,
}: FilterState) {
  return useQuery<TQueryClubs, Error>(
    [RQ_KEY.CLUBS, page, perPage, search, autonomy, province, federationId],
    () => getClubs({ page, perPage, search, autonomy, province, federationId })
  );
}
