import { useQuery } from "react-query";
import { TQueryRanking, TRankingFilters } from "../schemas/rankings";
import { RQ_KEY } from "../../constants/query";
import { getRankings } from "../../services/rankings";

export default function useQueryRankings(rankingFilters: TRankingFilters) {
  return useQuery<TQueryRanking, Error>(
    [RQ_KEY.RANKINGS, rankingFilters],
    () => getRankings(rankingFilters),
    {
      refetchOnWindowFocus: false,
    }
  );
}
