import dayjs from "dayjs";

export function transformDateFormat(date: string) {
  const [formattedDate] = date.replaceAll("-", "/").split(" ");
  return formattedDate;
}

export function dateToString(date: Date) {
  return date.toLocaleDateString("es").replaceAll("/", "-");
}

/**
 * Returns date in format DD/MM hh:mm
 * @param date
 */
export function dateToCustom(dateStr: string) {
  const [date, time] = dateStr.split(" ");
  const [day, month] = date.split("-");

  return `${day}/${month} ${time}`;
}
/**
 * Returns date in format DD/MM hh:mm
 * @param date
 */
export function dateToFullDateString(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
/**
 * Maps the string date received by the BE to the right string format received
 * by the DatePicker.
 * @param date The date received by the backend
 */
export function mapDateToRightFormat(date: string | null) {
  if (!date) return "";
  const newDate = new Date(Date.parse(date));

  return dateToString(newDate);
}

export function addYearToDate(date: Date, years: number) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export function mapDateToCardFormat(date: Date) {
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  return `${day} ${month}`;
}

export function mapDateToEventFormat(date: string) {
  return new Date(date).toLocaleDateString();
}

export function compareDates(dateA: Date, dateB: Date) {
  const parsedA = Date.parse(dateA.toString());
  const parsedB = Date.parse(dateB.toString());

  // Si A es anterior a B
  if (parsedA < parsedB) {
    return -1;
  }
  // Si B es anterior a A
  if (parsedA > parsedB) {
    return 1;
  }

  return 0;
}

export function get14YearsAgo() {
  return addYearToDate(new Date(), -14);
}

/**
 *
 * @param date the date we want to transform
 * @returns  date in the format YYYY-mm-dd
 */

function addLeadingZero(number: number): string {
  return number < 10 ? `0${number}` : `${number}`;
}

export function parseDate(_date: Date | string) {
  let date = typeof _date === "string" ? new Date(_date) : _date;

  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDay = addLeadingZero(day);
  const formattedMonth = addLeadingZero(month + 1);
  return `${year}-${formattedMonth}-${formattedDay}`;
}

export function formatShortDate(dateString: string | unknown) {
  if (typeof dateString !== "string" || !dateString) {
    return null;
  }

  return new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    timeZone: "UTC",
  }).format(new Date(dateString));
}

//Check age > 18
export function isLegalAge(bornDateString: string) {
  const today = new Date();
  const bornDate = new Date(bornDateString);
  var edad = today.getFullYear() - bornDate.getFullYear();
  if (edad > 18) {
    return true;
  } else if (edad === 18) {
    //Check month
    const bornMonth = bornDate.getMonth();
    const bornDay = bornDate.getDate();
    const actualMonth = today.getMonth();
    const actualDay = today.getDate();

    if (actualMonth > bornMonth) {
      return true;
    } else if (actualMonth === bornMonth) {
      if (actualDay >= bornDay) {
        return true;
      }
    }
  }

  return false;
}

//Para formatear fechas de los componentes DatePicker de MUI
export const formatMuiDate = (date: Date | null | undefined): string => {
  return date ? dayjs(date).format("YYYY/MM/DD") : "";
};

//Para formatear las horas de los componentes TimePicker de MUI
export const formatMuiTime = (date: string | null | undefined): string => {
  return date ? dayjs(date).format("HH:mm") : "";
};
