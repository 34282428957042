import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Fragment, MouseEvent, useState } from "react";

import { deleteUser } from "../../../context/forms/formsActions";
import { useAppDispatch } from "../../../context/hooks/hooks";
import useBoolean from "../../../utils/hooks/useBoolean";
import { TAdminSimple } from "../../../utils/schemas/admins";
import { FilterState } from "../../../utils/schemas/listFilters";
import { toastMessageError, toastMessageSuccess } from "../../common/toastMessage";
import CustomDialog from "../../customDialog/CustomDialog";
import "./admin-table.scss";

type Props = {
  admins: TAdminSimple[];
  total: number;
  page: number;
  perPage: number;
  handleFilterChange: (value: string | unknown, filterName: keyof FilterState) => void;
  refetch: () => void;
};

const AdminsTable = ({ admins, total, page, perPage, handleFilterChange, refetch }: Props) => {
  const [isOpenDelete, openDelete, closeDelete] = useBoolean(false);
  const [selectedUser, setSelectedUser] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEditButtonClick = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    navigate(`/gestor_admins/${id}`);
    event.stopPropagation();
  };

  const handleSeeAdminClick = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    navigate(`/admin/${id}`);
    event.stopPropagation();
  };

  const handleDeleteAdmin = async () => {
    const response = await dispatch(deleteUser(selectedUser));
    if (response.type.includes("fulfilled")) {
      toastMessageSuccess("Usuario eliminado correctamente");
      refetch();
    } else {
      toastMessageError("Error al eliminar usuario");
    }
    closeDelete();
  };

  const handleOpenDelete = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    openDelete();
    setSelectedUser(id);
  };

  return (
    <>
      <Grid container className="pagination-container">
        <Typography className="events-count">Admins totales: {total || 0}</Typography>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
          onRowsPerPageChange={(e) => {
            handleFilterChange(e.target.value, "perPage");
            handleFilterChange(1, "page");
          }}
          labelRowsPerPage="Admins por página"
        />
      </Grid>
      <Paper elevation={4} className="container-admin-list">
        <TableContainer>
          <Table>
            <TableHead className="admin-table-head">
              <TableRow className="admin-no-wrap">
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>CNR</TableCell>
                <TableCell>Pertenece a</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Teléfono</TableCell>
                <TableCell>Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins?.map((admin) => (
                <TableRow key={admin.id} className="admin-no-wrap">
                  <TableCell>{admin.name}</TableCell>
                  <TableCell>{admin.surname}</TableCell>
                  <TableCell>
                    {admin.roles && admin.roles.length > 0
                      ? admin.roles.map((element, index) => {
                          return (
                            <Fragment key={index}>
                              {element}
                              <br />
                            </Fragment>
                          );
                        })
                      : ""}
                  </TableCell>
                  <TableCell>{admin.cnr}</TableCell>
                  <TableCell>
                    {admin.belongsTo && admin.belongsTo.length > 0
                      ? admin.belongsTo.map((element, index) => {
                          return (
                            <Fragment key={index}>
                              {element}
                              <br />
                            </Fragment>
                          );
                        })
                      : ""}
                  </TableCell>
                  <TableCell>{admin.email}</TableCell>
                  <TableCell>{admin.phone}</TableCell>
                  <TableCell className="admin-no-wrap">
                    <Tooltip title="Ver administrador" placement="top" arrow>
                      <IconButton
                        className="admin-icon-button-accept"
                        sx={{ fontSize: "3rem" }}
                        onClick={(e) => handleSeeAdminClick(e, admin.id)}
                      >
                        <Visibility fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar administrador" placement="top" arrow>
                      <IconButton
                        className="admin-icon-button-accept"
                        onClick={(e) => handleEditButtonClick(e, admin.id)}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar administrador" placement="top" arrow>
                      <IconButton
                        className="admin-icon-button-cancel"
                        onClick={(e) => handleOpenDelete(e, admin.id)}
                      >
                        <Delete fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomDialog
        content="¿Estás seguro de que quieres eliminar este administrador?"
        title="Eliminar administrador"
        isOpen={isOpenDelete}
        onClose={closeDelete}
        onAccept={handleDeleteAdmin}
      />
    </>
  );
};

export default AdminsTable;
