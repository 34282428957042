import { toastMessageError } from "../components/common/toastMessage";
import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { TPutInscription } from "../utils/schemas/inscriptions";
import { TInscriptionFilters } from "../utils/schemas/listFilters";

export const getAllInscriptions = async ({
  search,
  page,
  perPage,
  eventId,
  status,
  genre,
  categoryCode,
  clubId,
  federationId,
}: TInscriptionFilters) => {
  try {
    const params = new URLSearchParams();
    if (search && search !== "") params.append("search", search.toString());
    if (page) params.append("page", page.toString());
    if (perPage) params.append("perPage", perPage.toString());
    if (eventId && eventId !== "") params.append("eventId", eventId.toString());
    if (status && status !== "") params.append("status", status.toString());
    if (genre && genre !== "") params.append("genre", genre.toString());
    if (categoryCode && categoryCode !== "") params.append("categoryCode", categoryCode.toString());
    if (clubId && clubId !== "") params.append("clubId", clubId.toString());
    if (federationId && federationId !== "") params.append("federationId", federationId.toString());

    const { data } = await http.get(`${API_URL}${SERVICE.INSCRIPTIONS}?${params}`);
    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const getInscriptionsByEvent = async (eventId: string) => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.INSCRIPTIONS}/${eventId}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const createInscription = async (inscription: any) => {
  try {
    const { data } = await http.post(`${API_URL}${SERVICE.INSCRIPTIONS}`, inscription);

    return data;
  } catch (error: any) {
    toastMessageError(error.response.data.error ?? "Error en servidor");
    throw new Error("Error en servidor");
  }
};

export const validateInscription = async ({ rol, body }: TPutInscription) => {
  try {
    const { data } = await http.put(`${API_URL}${SERVICE.INSCRIPTIONS}/validate/${rol}`, body);

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.error ?? "Error en servidor");
  }
};

export const rejectInscription = async ({ id, message }: { id: string; message: string }) => {
  const body = { message: message };
  try {
    const { data } = await http.put(`${API_URL}${SERVICE.INSCRIPTIONS}/reject/${id}`, body);

    return data;
  } catch (error: any) {
    toastMessageError(error.response.data.error ?? "Error en servidor");
    throw new Error("Error en servidor");
  }
};
