import { Container, Grid, Tab, TablePagination, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loader/Loading";
import UsersFilters from "../../components/users/usersFilters/UsersFilters";
import CreateUsersDialog from "../../components/usersCsv/usersDialog/CreateUsersDialog";
import UsersPending from "../../components/usersPending/UsersPending";
import UsersTable from "../../components/usersTable/UsersTable";
import { resetFilters, setFilter } from "../../context/filters/filterSlice";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import useQueryUsers from "../../utils/hooks/useQueryUsers";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { FilterState } from "../../utils/schemas/listFilters";
import "./users.scss";

const UsersManager = () => {
  useSetPageName([{ name: "Gestor de Usuarios", path: "/gestor_usuarios" }]);
  const [openMultiUserDialog, setOpenMultiUserDialog] = useState(false);
  const location = useLocation();

  //Secciones usuarios
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  //Filtros
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };
  //Limpiamos los filtros
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  //Datos de usuarios
  const {
    data: users,
    isLoading,
    isRefetching,
    refetch,
  } = useQueryUsers({ ...filterState, isActive: valueTab === 0 ? "true" : "false" });

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const club = searchParams.get("club");
      const federation = searchParams.get("federation");
      if (club) {
        handleFilterChange(club, "clubId");
        handleFilterChange("", "federationId");
      }
      if (federation) {
        handleFilterChange(federation, "federationId");
        handleFilterChange("", "clubId");
      }
    }
  }, [location]);

  return (
    <>
      <Tabs
        value={valueTab}
        onChange={handleChangeTab}
        allowScrollButtonsMobile
        className="users-tabs"
        TabIndicatorProps={{
          className: "users-tab-indicator",
        }}
      >
        <Tab className="users-tab" label={"Usuarios validados"} />
        <Tab className="users-tab" label={"Pendientes de validar"} />
      </Tabs>
      <Container className="users-container">
        <UsersFilters
          setOpenMultiUserDialog={setOpenMultiUserDialog}
          handleFilterChange={handleFilterChange}
          filterState={filterState}
        />

        {isLoading || isRefetching ? (
          <Loading />
        ) : users && users.data?.length > 0 ? (
          <>
            <Grid container className="pagination-container">
              <Typography className="events-count">Usuarios: {users?.total || 0}</Typography>
              {users && users.data?.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={users.total}
                  rowsPerPage={users.perPage}
                  page={users.page - 1}
                  onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
                  onRowsPerPageChange={(e) => {
                    handleFilterChange(e.target.value, "perPage");
                    handleFilterChange(1, "page");
                  }}
                  labelRowsPerPage="Usuarios por página"
                />
              )}
            </Grid>
            {valueTab === 0 ? (
              <UsersTable users={users.data} refetch={refetch} />
            ) : (
              <UsersPending users={users?.data} refetch={refetch} />
            )}
          </>
        ) : (
          <Typography>No hay usuarios disponibles</Typography>
        )}
      </Container>
      <CreateUsersDialog
        isOpen={openMultiUserDialog}
        onClose={() => setOpenMultiUserDialog(false)}
      />
    </>
  );
};

export default UsersManager;
