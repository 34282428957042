import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";
import Loading from "./components/loader/Loading";
import { setUser } from "./context/auth/authSlice";
import { getFormOptions } from "./context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "./context/hooks/hooks";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import ProtectedRoute from "./routes/protectedRoutes";
import routes from "./routes/routes";
import { getUser } from "./services/users";
import SetPassword from "./pages/verify/Verify";

function App() {
  const defaultTheme = createTheme();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  let user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const [isDispatchDone, setIsDispatchDone] = useState(false);

  useEffect(() => {
    if (user) {
      const dispatchUser = async (localUser: string) => {
        const userInfo = JSON.parse(localUser);
        //Info of the user
        const userData = await getUser(userInfo.id);
        try {
          dispatch(setUser({ userInfo, token, userData }));
        } catch (error) {
          console.error("Error al despachar usuario:", error);
        } finally {
          setIsDispatchDone(true);
        }
      };
      dispatchUser(user);
    } else {
      setIsDispatchDone(true);
    }
  }, [dispatch, user]);

  //Carga de datos para los formularios
  useEffect(() => {
    dispatch(getFormOptions());
  }, [dispatch]);

  if (!isDispatchDone) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: "100001" }}
      />
      <Routes>
        <Route
          path="/"
          element={<ProtectedRoute isAllowed={!!user} redirectTo="/login" component={<Layout />} />}
        >
          {routes.map((route, i) => {
            const isAuth = route.roles.some((role) => userInfo?.roles.includes(role));
            return (
              <Route
                index={i === 0}
                key={route.path}
                path={i === 0 ? "" : route.path}
                element={<ProtectedRoute isAllowed={isAuth} component={route.element} />}
              />
            );
          })}
        </Route>

        <Route path="/login" element={<ProtectedRoute isAllowed={!user} component={<Login />} />} />
        <Route
          path="/verify"
          element={<ProtectedRoute isAllowed={!user} component={<SetPassword />} />}
        />
        <Route
          path="/forget-password"
          element={<ProtectedRoute isAllowed={!user} component={<ForgetPassword />} />}
        />
        <Route
          path="/reset_password"
          element={<ProtectedRoute isAllowed={!user} component={<ResetPassword />} />}
        />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
