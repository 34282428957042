import { INSCRIPTION_STATUS } from "../../constants/constants";

export interface FilterState {
  fromDate?: Date | null;
  toDate?: Date | null;
  type?: string | unknown;
  categoryCode?: string | unknown;
  organizerId?: string | unknown;
  search?: string | unknown;
  page: number | unknown;
  perPage: number | unknown;
  belt?: string | unknown;
  cp?: string | unknown;
  city?: string | unknown;
  provinceCode?: string | unknown;
  federationId?: string | unknown;
  clubId?: string | unknown;
  role?: string | unknown;
  eventToInscribe?: string | unknown;
  eventId?: string | unknown;
  status?: string | unknown;
  autonomy?: string | unknown;
  province?: string | unknown;
  isActive?: string | unknown;
  organizatorId?: string | unknown;
  isSportilyActive?: string | unknown;
  checked?: string | unknown;
  duplicate?: string | unknown;
  subsanado?: string | unknown;
  dato_erroneo?: string | unknown;
  en_consentimientos?: string | unknown;
  rechazado?: string | unknown;
  order?: { asc: string[]; desc: string[]; [key: string]: string[] };
}

export const initialState: FilterState = {
  page: 1,
  fromDate: null,
  toDate: null,
  type: "",
  categoryCode: "",
  organizerId: "",
  search: "",
  perPage: 10,
  city: "",
  cp: "",
  federationId: "",
  clubId: "",
  role: "",
  eventToInscribe: "",
  eventId: "",
  status: "",
  provinceCode: "",
  autonomy: "",
  province: "",
  isActive: "",
  organizatorId: "",
  isSportilyActive: "",
  checked: "",
  duplicate:"",
  subsanado: "",
  dato_erroneo: "",
  en_consentimientos: "",
  rechazado: "",
  order: { asc: [], desc: [] },
};

//Inscriptions filtes
export interface TInscriptionFilters {
  categoryCode: string;
  clubId: string;
  federationId: string;
  eventId: string;
  genre: string;
  status: string;
  search: string;
  page: number;
  perPage: number;
}

export const initialPendingFilter: TInscriptionFilters = {
  categoryCode: "",
  clubId: "",
  federationId: "",
  eventId: "",
  genre: "",
  status: INSCRIPTION_STATUS.PENDING,
  search: "",
  page: 1,
  perPage: 10,
};

export const initialApprovedFilter: TInscriptionFilters = {
  categoryCode: "",
  clubId: "",
  federationId: "",
  eventId: "",
  genre: "",
  status: INSCRIPTION_STATUS.APPROVED_OR_RESERVED,
  search: "",
  page: 1,
  perPage: 10,
};

export const initialRejectedFilter: TInscriptionFilters = {
  categoryCode: "",
  clubId: "",
  federationId: "",
  eventId: "",
  genre: "",
  status: INSCRIPTION_STATUS.REJECTED,
  search: "",
  page: 1,
  perPage: 10,
};
