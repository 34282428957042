import { Controller, useFormContext } from "react-hook-form";
import { AddFederationSchemaType } from "../../../../utils/schemas/federations";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { useEffect } from "react";

type Props = {
  isDisabled?: boolean;
};

const ContactData = ({ isDisabled }: Props) => {
  const { register, formState, control, watch, getValues, setValue } =
    useFormContext<AddFederationSchemaType>();
  const { errors } = formState;

  //Data from address
  const { countries, autonomies, provinces, municipalities } = useAppSelector(
    (state) => state.formsOptions
  );

  //Set fiscal address same as address
  const sameAddress = watch("isFiscalAddressSameAsAddress");
  useEffect(() => {
    if (sameAddress) {
      const address = getValues("address");
      setValue("fiscalAddress", {
        ...address,
      });
    }
  }, [sameAddress]);

  return (
    <>
      <Box className="add-federation-section-container">
        <Typography className="add-federation-form-title">Datos de contacto</Typography>
        <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Dirección</Typography>
              <TextField
                className="input-text add-federation-text-field"
                variant="outlined"
                placeholder="Dirección*"
                required
                {...register("address.address")}
                error={!!errors?.address?.address}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.address?.address && (
                <Typography className="form-input-error">
                  {errors?.address.address?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">País</Typography>
              <Controller
                name="address.countryCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.address?.countryCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>País</em>
                    </MenuItem>
                    {countries?.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.address?.countryCode && (
                <Typography className="form-input-error">
                  {errors?.address?.countryCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Autonomía</Typography>
              <Controller
                name="address.autonomyCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.address?.autonomyCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Autonomía</em>
                    </MenuItem>
                    {autonomies?.map((autonomy) => (
                      <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                        {autonomy.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.address?.autonomyCode && (
                <Typography className="form-input-error">
                  {errors?.address?.autonomyCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Provincia</Typography>
              <Controller
                name="address.provinceCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.address?.provinceCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Provincia / Region</em>
                    </MenuItem>
                    {provinces
                      ?.filter(
                        (province) => province.autonomy_code === watch("address.autonomyCode")
                      )
                      .map((province) => (
                        <MenuItem key={province.province_code} value={province.province_code}>
                          {province.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors?.address?.provinceCode && (
                <Typography className="form-input-error">
                  {errors?.address.provinceCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Ciudad*</Typography>
              <Controller
                name="address.municipalityCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.address?.municipalityCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Ciudad</em>
                    </MenuItem>
                    {municipalities
                      ?.filter(
                        (municipality) =>
                          municipality.province_code === watch("address.provinceCode")
                      )
                      .map((municipality) => (
                        <MenuItem
                          key={municipality.municipality_code}
                          value={municipality.municipality_code}
                        >
                          {municipality.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors?.address?.municipalityCode && (
                <Typography className="form-input-error">
                  {errors?.address.municipalityCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">CP</Typography>
              <TextField
                className="input-text add-federation-text-field"
                variant="outlined"
                placeholder="CP"
                error={!!errors?.address?.postalCode}
                {...register("address.postalCode")}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.address?.postalCode && (
                <Typography className="form-input-error">
                  {errors?.address.postalCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Número de teléfono*</Typography>
              <TextField
                className="input-text add-federation-text-field"
                variant="outlined"
                placeholder="Número de teléfono*"
                required
                error={!!errors?.phone}
                {...register("phone")}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.phone && (
                <Typography className="form-input-error">{errors?.phone?.message}</Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Email*</Typography>
              <TextField
                className="input-text add-federation-text-field"
                variant="outlined"
                placeholder="Email*"
                required
                {...register("email")}
                error={!!errors?.email}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.email && (
                <Typography className="form-input-error">{errors?.email?.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <FormControlLabel
          className="add-federation-checkbox-container"
          control={
            <Checkbox
              {...register("isFiscalAddressSameAsAddress")}
              className="add-federation-checkbox"
              checked={sameAddress || false}
              disabled={isDisabled}
            />
          }
          label="Utilizar esta dirección como dirección fiscal"
        />
      </Box>
      <Box className="add-federation-section-container">
        <Typography className="add-federation-form-title">Dirección fiscal</Typography>
        <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Dirección</Typography>
              <TextField
                className="input-text add-federation-text-field"
                id="address"
                variant="outlined"
                placeholder="Dirección*"
                required
                error={!!errors?.fiscalAddress?.address}
                {...register("fiscalAddress.address")}
                InputProps={{
                  readOnly: isDisabled || sameAddress,
                }}
              />
              {errors?.fiscalAddress?.address && (
                <Typography className="form-input-error">
                  {errors?.fiscalAddress.address?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">País</Typography>
              <Controller
                name="fiscalAddress.countryCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.fiscalAddress?.countryCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled || sameAddress } }}
                  >
                    <MenuItem value={""}>
                      <em>País</em>
                    </MenuItem>
                    {countries?.map((country) => (
                      <MenuItem key={country.code + "fiscal"} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.fiscalAddress?.countryCode && (
                <Typography className="form-input-error">
                  {errors?.fiscalAddress?.countryCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Autonomía</Typography>
              <Controller
                name="fiscalAddress.autonomyCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.fiscalAddress?.autonomyCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled || sameAddress } }}
                  >
                    <MenuItem value={""}>
                      <em>Autonomía</em>
                    </MenuItem>
                    {autonomies?.map((autonomy) => (
                      <MenuItem
                        key={autonomy.autonomy_code + "fiscal"}
                        value={autonomy.autonomy_code}
                      >
                        {autonomy.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.fiscalAddress?.autonomyCode && (
                <Typography className="form-input-error">
                  {errors?.fiscalAddress?.autonomyCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Provincia</Typography>
              <Controller
                name="fiscalAddress.provinceCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.fiscalAddress?.provinceCode}
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    slotProps={{ input: { readOnly: isDisabled || sameAddress } }}
                  >
                    <MenuItem value={""}>
                      <em>Provincia / Region</em>
                    </MenuItem>
                    {provinces
                      ?.filter(
                        (province) =>
                          province.autonomy_code === watch("fiscalAddress.autonomyCode") ||
                          province.autonomy_code === watch("address.autonomyCode")
                      )
                      .map((province) => (
                        <MenuItem
                          key={province.province_code + "fiscal "}
                          value={province.province_code}
                        >
                          {province.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors?.fiscalAddress?.provinceCode && (
                <Typography className="form-input-error">
                  {errors?.fiscalAddress.provinceCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Ciudad*</Typography>
              <Controller
                name="fiscalAddress.municipalityCode"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select add-federation-select-field"
                    error={!!errors?.fiscalAddress?.municipalityCode}
                    {...field}
                    displayEmpty
                    slotProps={{ input: { readOnly: isDisabled || sameAddress } }}
                    defaultValue={""}
                  >
                    <MenuItem value={""}>
                      <em>Ciudad</em>
                    </MenuItem>
                    {municipalities
                      ?.filter(
                        (municipality) =>
                          municipality.province_code === watch("fiscalAddress.provinceCode") ||
                          municipality.province_code === watch("address.provinceCode")
                      )
                      .map((municipality) => (
                        <MenuItem
                          key={municipality.municipality_code + "fiscal"}
                          value={municipality.municipality_code}
                        >
                          {municipality.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
              {errors?.fiscalAddress?.municipalityCode && (
                <Typography className="form-input-error">
                  {errors?.fiscalAddress.municipalityCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">CP</Typography>
              <TextField
                className="input-text add-federation-text-field"
                id="cp"
                variant="outlined"
                placeholder="CP"
                error={!!errors?.fiscalAddress?.postalCode}
                {...register("fiscalAddress.postalCode")}
                InputProps={{
                  readOnly: isDisabled || sameAddress,
                }}
              />
              {errors?.fiscalAddress?.postalCode && (
                <Typography className="form-input-error">
                  {errors?.fiscalAddress?.postalCode?.message}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactData;
