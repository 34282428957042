import { ReactNode } from "react";
import {
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./nav-drawer.scss";

type Props = {
  open: boolean;
  routes: { path: string; id: string; icon?: ReactNode; dashboard?: boolean }[];
};

const NavDrawer = ({ open, routes }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavLinkClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <Container
        className="nav-drawer-logo"
        sx={{
          "&.nav-drawer-logo": {
            ...(!open && { backgroundImage: "none" }),
          },
        }}
      />
      <List component="nav">
        {routes
          .filter((route) => route.dashboard)
          .map((route) => (
            <ListItemButton
              className={`nav-drawer-list-item ${
                `/${location.pathname.split("/")[1]}` === route.path
                  ? "item-selected"
                  : ""
              }`}
              key={route.id}
              onClick={() => handleNavLinkClick(route.path)}
            >
              <ListItemIcon style={{ color: "white" }}>
                {route.icon}
              </ListItemIcon>

              <ListItemText
                primary={route.id}
                className="nav-drawer-item-text"
              />
            </ListItemButton>
          ))}
      </List>
    </>
  );
};

export default NavDrawer;
