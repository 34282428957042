import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getAllFederations } from "../../services/federations";
import { FederationsQuery } from "../schemas/federations";
import { FilterState } from "../schemas/listFilters";

export default function useQueryFederations({ page, perPage, search, autonomy }: FilterState) {
  return useQuery<FederationsQuery, Error>(
    [RQ_KEY.FEDERATIONS, page, perPage, search, autonomy],
    () => getAllFederations({ page, perPage, search, autonomy })
  );
}
