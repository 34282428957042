import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { formatMuiDate } from "../utils/date";
import { FilterState } from "../utils/schemas/listFilters";

export const getEvents = async ({
  type,
  categoryCode,
  organizatorId,
  fromDate,
  toDate,
  search,
  perPage,
  page,
  isActive,
  status,
}: FilterState) => {
  try {
    const params = new URLSearchParams();
    if (type && type !== "") params.append("type", type.toString());
    if (categoryCode && categoryCode !== "") params.append("categoryCode", categoryCode.toString());
    if (organizatorId && organizatorId !== "")
      params.append("organizatorId", organizatorId.toString());
    if (fromDate) params.append("fromDate", formatMuiDate(fromDate));
    if (toDate) params.append("toDate", formatMuiDate(toDate));
    if (search && search !== "") params.append("search", search.toString());
    if (perPage) params.append("perPage", perPage.toString());
    if (page) params.append("page", page.toString());
    if (status && status !== "") params.append("status", status.toString());
    const { data } = await http.get(`${API_URL}${SERVICE.EVENTS}?${params}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const getEventById = async (id: string) => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.EVENTS}/${id}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};
