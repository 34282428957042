import { useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

import { TClub } from "../../../../utils/schemas/club";

type Props = {
  isDisabled?: boolean;
};

const BillingData = ({ isDisabled }: Props) => {
  const { register, formState } = useFormContext<TClub>();
  const { errors } = formState;
  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Datos de facturación</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={9} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Denominación fiscal</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Denominación fiscal"
              InputProps={{
                readOnly: isDisabled,
              }}
              {...register("taxName")}
            />
            {errors?.taxName && (
              <Typography className="form-input-error">{errors?.taxName?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">CIF</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="CIF"
              InputProps={{
                readOnly: isDisabled,
              }}
              {...register("cif")}
            />
            {errors?.cif && (
              <Typography className="form-input-error">{errors?.cif?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">IBAN</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="IBAN"
              InputProps={{
                readOnly: isDisabled,
              }}
              {...register("iban")}
            />
            {errors?.iban && (
              <Typography className="form-input-error">{errors?.iban?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingData;
