import { useFormContext } from "react-hook-form";

import { Facebook, Instagram, Twitter, WhatsApp, YouTube } from "@mui/icons-material";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";

import { AddFederationSchemaType } from "../../../../utils/schemas/federations";
import TikTokIcon from "../../../icons/TikTokIcon";

import "./media-urls.scss";

type Props = {
  isDisabled?: boolean;
};

const MediaData = ({ isDisabled }: Props) => {
  const { register, formState } = useFormContext<AddFederationSchemaType>();
  const { errors } = formState;

  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Redes sociales</Typography>
      <Grid container columnSpacing={4} rowSpacing={3} sx={{ my: 2 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            width="100%"
          >
            <Box className="form-social-media-icon-container">
              <Instagram className="form-social-media-icon" />
            </Box>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Link"
              {...register("socialMedia.instagramUrl")}
              error={!!errors?.socialMedia?.instagramUrl}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </Stack>
          {errors?.socialMedia?.instagramUrl && (
            <Typography className="form-input-error">
              {errors?.socialMedia.instagramUrl.message}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            width="100%"
          >
            <Box className="form-social-media-icon-container">
              <Facebook className="form-social-media-icon" />
            </Box>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Link"
              {...register("socialMedia.facebookUrl")}
              error={!!errors?.socialMedia?.facebookUrl}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </Stack>
          {errors?.socialMedia?.facebookUrl && (
            <Typography className="form-input-error">
              {errors?.socialMedia.facebookUrl.message}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            width="100%"
          >
            <Box className="form-social-media-icon-container">
              <Twitter className="form-social-media-icon" />
            </Box>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Link"
              {...register("socialMedia.twitterUrl")}
              error={!!errors?.socialMedia?.twitterUrl}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </Stack>
          {errors?.socialMedia?.twitterUrl && (
            <Typography className="form-input-error">
              {errors?.socialMedia.twitterUrl.message}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            width="100%"
          >
            <Box className="form-social-media-icon-container">
              <WhatsApp className="form-social-media-icon" />
            </Box>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Link"
              {...register("socialMedia.whatsappUrl")}
              error={!!errors?.socialMedia?.whatsappUrl}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </Stack>
          {errors?.socialMedia?.whatsappUrl && (
            <Typography className="form-input-error">
              {errors?.socialMedia.whatsappUrl.message}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            width="100%"
          >
            <Box className="form-social-media-icon-container">
              <YouTube className="form-social-media-icon" />
            </Box>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Link"
              {...register("socialMedia.youtubeUrl")}
              error={!!errors?.socialMedia?.youtubeUrl}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </Stack>
          {errors?.socialMedia?.youtubeUrl && (
            <Typography className="form-input-error">
              {errors?.socialMedia.youtubeUrl.message}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={2}
            width="100%"
          >
            <Box className="form-social-media-icon-container">
              <TikTokIcon />
            </Box>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Link"
              {...register("socialMedia.tiktokUrl")}
              error={!!errors?.socialMedia?.tiktokUrl}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </Stack>
          {errors?.socialMedia?.tiktokUrl && (
            <Typography className="form-input-error">
              {errors?.socialMedia.tiktokUrl.message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MediaData;
