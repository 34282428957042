import { Box, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import ImageUpload from "../../../imageUpload/ImageUpload";
import { AddFederationSchemaType } from "../../../../utils/schemas/federations";
import { useFormContext } from "react-hook-form";

type Props = {
  isDisabled?: boolean;
};

const BasicData = ({ isDisabled }: Props) => {
  const { register, getValues, setValue, formState } = useFormContext<AddFederationSchemaType>();
  const { errors } = formState;

  return (
    <Box className="add-federation-section-container">
      <Typography className="add-federation-form-title">Datos de la Federación</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={4} xs>
          <ImageUpload
            url={getValues("logoUrl") || ""}
            multi={false}
            onImageSelect={(e) =>
              typeof e === "string" ? setValue("logoUrl", e) : setValue("logoUrl", "")
            }
            disabled={isDisabled}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Stack justifyContent="space-between" height="100%" spacing={3}>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Nombre*</Typography>
              <TextField
                className="input-text add-federation-text-field"
                id="address"
                variant="outlined"
                placeholder="Nombre*"
                required
                error={!!errors?.name}
                {...register("name")}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.name && (
                <Typography className="form-input-error">{errors?.name?.message}</Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">Código*</Typography>
              <TextField
                className="input-text add-federation-text-field"
                id="address"
                variant="outlined"
                placeholder="Código*"
                required
                error={!!errors?.shortCode}
                {...register("shortCode")}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.shortCode && (
                <Typography className="form-input-error">{errors?.shortCode?.message}</Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Typography className="add-federation-input-label">
                Detalles de la historia
              </Typography>
              <TextField
                className="input-text "
                id="address"
                variant="outlined"
                placeholder="Descripción*"
                multiline
                rows={6}
                {...register("description")}
                error={!!errors?.description}
                InputProps={{
                  readOnly: isDisabled,
                }}
              />
              {errors?.description && (
                <Typography className="form-input-error">{errors?.description?.message}</Typography>
              )}
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicData;
