import { Box, CircularProgress } from "@mui/material";

import "./loading.scss";

const Loading = () => {
  return (
    <Box className="circular-container">
      <CircularProgress className="circular-progress" size="3rem" />
    </Box>
  );
};

export default Loading;
