import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getClub } from "../../services/clubs";
import { TClub } from "../schemas/club";
import { useState } from "react";

export default function useQueryClub(clubId: string) {
  const [isActive, setIsActive] = useState(false);

  const clubQuery = useQuery<TClub, Error>([RQ_KEY.CLUB], () => getClub(clubId), {
    enabled: isActive && clubId !== null,
  });

  const activate = () => {
    setIsActive(true);
  };

  return {
    clubQuery,
    activate,
  };
}
