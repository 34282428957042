import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { CATEGORY_NAMES } from "../../../constants/constants";
import { useAppSelector } from "../../../context/hooks/hooks";
import { generateRanking } from "../../../services/rankings";
import Button from "../../button/Button";
import { toastMessageSuccess } from "../../common/toastMessage";
import SelectInput from "../../selectInput/SelectInput";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

function GenerateDialog({ isOpen, onClose, refetch }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSport, setSelectedSport] = useState<string>("");

  const { sports } = useAppSelector((state) => state.formsOptions);

  const today = new Date();

  const handleGenerateRanking = async () => {
    setIsLoading(true);
    const bodyToSend = {
      toDate: today.toISOString().slice(0, 10),
      categoryCode: selectedCategory,
      sportCode: selectedSport,
    };
    const generateResponse = await generateRanking(bodyToSend);
    if (generateResponse) {
      toastMessageSuccess("Ranking generado correctamente");
      refetch();
      onClose();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setSelectedCategory("");
      setSelectedSport("");
    };
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="dialog-title">Generar ranking</DialogTitle>
      <DialogContent>
        <Typography mb={2}>Categoría:</Typography>
        <SelectInput
          rounded="md"
          defaultValue=""
          title="Categoría:"
          options={[
            ...CATEGORY_NAMES.map((category) => ({
              value: category,
              label: category,
            })),
          ]}
          onChange={(e) => setSelectedCategory(e.target.value as string)}
          value={selectedCategory}
        />
        <Typography mb={2} mt={2}>
          Deporte:
        </Typography>
        <SelectInput
          rounded="md"
          defaultValue=""
          title="Deporte:"
          options={
            sports && sports.length > 0
              ? sports.map((sport) => ({
                  value: sport.code,
                  label: sport.name,
                }))
              : []
          }
          onChange={(e) => setSelectedSport(e.target.value as string)}
          value={selectedSport}
        />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button type="submit" className="button-cancel" onClick={onClose}>
          CANCELAR
        </Button>
        <Button
          type="submit"
          className="button-accept"
          onClick={handleGenerateRanking}
          disabled={isLoading || !selectedCategory || !selectedSport}
        >
          {isLoading ? "Cargando..." : "GENERAR"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateDialog;
