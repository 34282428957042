import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { FilterState } from "../utils/schemas/listFilters";

export const getAllFederations = async ({ page, perPage, search, autonomy }: FilterState) => {
  try {
    const params = new URLSearchParams();
    if (page) params.append("page", page.toString());
    if (perPage) params.append("perPage", perPage.toString());
    if (search && search !== "") params.append("search", search.toString());
    if (autonomy && autonomy !== "") params.append("autonomy", autonomy.toString());

    const { data } = await http.get(`${API_URL}${SERVICE.FEDERATIONS}?${params}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const getFederationId = async (id: string) => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.FEDERATIONS}/${id}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const getAllFederationsList = async () => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.FEDERATIONS}/list`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export const checkShortCodeFederation = async (shortCode: string) => {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.FEDERATIONS}/short-code-available/${shortCode}`
    );

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};
