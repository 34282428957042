import "./events-manager.scss";

import { useLocation } from "react-router-dom";

import { Add, Search } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, TablePagination, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import DateRangePicker from "../../components/dateFilter/DateRange";
import EventCard from "../../components/eventCard/EventCard";
import TypeDialog from "../../components/events/typeDialog/TypeDialog";
import Loading from "../../components/loader/Loading";
import SelectInput from "../../components/selectInput/SelectInput";
import TextInput from "../../components/textInput/TextInput";
import { CATEGORY_NAMES, EVENT_STATUS } from "../../constants/constants";
import { resetFilters, setFilter } from "../../context/filters/filterSlice";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import useDebounce from "../../utils/hooks/useDebouncing";
import useQueryEvents from "../../utils/hooks/useQueryEvents";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { FilterState } from "../../utils/schemas/listFilters";

const EventsManager = () => {
  useSetPageName([{ name: "Gestor de eventos", path: "/gestor_eventos" }]);
  const { eventTypes } = useAppSelector((state) => state.formsOptions);
  const [openTypeDialog, setOpenTypeDialog] = useState(false);
  const location = useLocation();

  const handleOpenTypeDialog = () => {
    setOpenTypeDialog(true);
  };

  //Filtros
  const dispatch = useAppDispatch();
  const filterState = useAppSelector((state) => state.filters);
  const handleFilterChange = (value: string | unknown, filterName: keyof FilterState) => {
    dispatch(setFilter({ filterName, payload: value }));
  };
  //Limpiamos los filtros
  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  //Optimización de la búsqueda
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  const { data, isLoading, isRefetching, refetch } = useQueryEvents(filterState);

  const eventOrganizers = data?.data?.map((event) => {
    return {
      value: event.organizatorId,
      label: event.organizatorName,
    };
  });
  const uniqueOrganizers = new Set(eventOrganizers?.map((organizer) => organizer.value));
  const filteredOrganizers = eventOrganizers?.filter((organizer) => {
    if (uniqueOrganizers.has(organizer.label)) {
      return false;
    } else {
      uniqueOrganizers.add(organizer.label);
      return true;
    }
  });

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const club = searchParams.get("club");
      const federation = searchParams.get("federation");
      const pending = searchParams.get("pending");
      if (club) {
        handleFilterChange(club, "organizatorId");
      }
      if (federation) {
        handleFilterChange(federation, "organizatorId");
      }
      if (pending) {
        handleFilterChange("pending", "status");
      }
    }
  }, [location]);

  return (
    <Container className="events-container">
      <Box className="events-header">
        <Box className="events-header-searchInput-container">
          <IconButton>
            <Search className="events-header-searchInput-icon" />
          </IconButton>
          <TextInput
            placeholder="Buscar"
            minWidth="full-width"
            onChange={(e) => debounceSearch(e.target.value)}
          />
        </Box>
        <Box className="events-header-actions-container">
          <Button
            startIcon={<Add />}
            rounded="md"
            width="fit-content"
            className="events-header-actions-button"
            onClick={handleOpenTypeDialog}
          >
            AGREGAR EVENTO
          </Button>
        </Box>
      </Box>
      <Box className="events-filters">
        <SelectInput
          rounded="md"
          title="Tipo de evento: Todos"
          options={[
            { value: "", label: "Todos los eventos" },
            ...(eventTypes && eventTypes.length > 0
              ? eventTypes.map((eventType) => ({
                  value: eventType.code,
                  label: eventType.name,
                }))
              : []),
          ]}
          onChange={(e) => handleFilterChange(e.target.value, "type")}
          value={filterState.type}
        />
        <SelectInput
          rounded="md"
          title="Categoría: Todas"
          options={[
            { value: "", label: "Todas las categorías" },
            ...CATEGORY_NAMES.map((category) => ({
              value: category,
              label: category,
            })),
          ]}
          onChange={(e) => handleFilterChange(e.target.value, "categoryCode")}
          value={filterState.categoryCode}
        />
        <SelectInput
          rounded="md"
          title="Organizador: Todos"
          options={[
            { value: "", label: "Todos los organizadores" },
            ...(filteredOrganizers && filteredOrganizers.length > 0
              ? filteredOrganizers.map((organizer) => ({
                  value: organizer.value,
                  label: organizer.label,
                }))
              : []),
          ]}
          onChange={(e) => handleFilterChange(e.target.value, "organizatorId")}
          value={filterState.organizerId}
        />
        <SelectInput
          rounded="md"
          title="Estado: Todos"
          options={[
            { value: "", label: "Todos los estados" },
            ...EVENT_STATUS.map((status) => ({
              value: status.value,
              label: status.label,
            })),
          ]}
          onChange={(e) => handleFilterChange(e.target.value, "status")}
          value={filterState.status}
        />
        <DateRangePicker
          fromDate={filterState.fromDate}
          toDate={filterState.toDate}
          setFromDate={(date) => handleFilterChange(date, "fromDate")}
          setToDate={(date) => handleFilterChange(date, "toDate")}
        />
      </Box>
      <Grid container className="pagination-container">
        {data && data.data?.length > 0 && (
          <>
            <Typography className="events-count">Eventos: {data?.total || 0}</Typography>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={data.total}
              rowsPerPage={data.perPage}
              page={data.page - 1}
              onPageChange={(_, newPage) => handleFilterChange(newPage + 1, "page")}
              onRowsPerPageChange={(e) => {
                handleFilterChange(e.target.value, "perPage");
                handleFilterChange(1, "page");
              }}
              labelRowsPerPage="Eventos por página"
            />
          </>
        )}
      </Grid>
      {isLoading || isRefetching ? (
        <Loading />
      ) : data && data.data?.length > 0 ? (
        <Box className="events-list">
          {data?.data?.map((event, index) => {
            return <EventCard key={index} event={event} refetch={refetch} />;
          })}
        </Box>
      ) : (
        <Typography className="events-empty">No hay eventos disponibles</Typography>
      )}
      <TypeDialog isOpen={openTypeDialog} onClose={() => setOpenTypeDialog(false)} />
    </Container>
  );
};

export default EventsManager;
