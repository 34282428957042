import { Scale } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { INSCRIPTION_STATUS } from "../../../../constants/constants";
import { isLegalAge, mapDateToRightFormat } from "../../../../utils/date";
import { TEventStock } from "../../../../utils/schemas/event";
import { TInscription } from "../../../../utils/schemas/inscriptions";
import ChangeCategory from "../../changeCategoryDialog/ChangeCategoryDialog";
import RejectDialog from "../../rejectInsctiptionDialog/RejectInscriptionDialog";

type Props = {
  inscriptions: TInscription[];
  refetchInscriptions: () => void;
  stockCategories: TEventStock[] | undefined | null;
  changeDateLimit?: string | Date;
};

const ValidatedList = ({
  inscriptions,
  refetchInscriptions,
  stockCategories,
  changeDateLimit,
}: Props) => {
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [selectedInscription, setSelectedInscription] = useState<TInscription | null>(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [inscriptionToChange, setInscriptionToChange] = useState<TInscription>();

  const handleOpenRejectDialog = (_: any, inscription: TInscription) => {
    setSelectedInscription(inscription);
    setOpenRejectDialog(true);
  };

  const handleOpenChangeCategory = (_: any, inscription: TInscription) => {
    setInscriptionToChange(inscription);
    setOpenCategoryDialog(true);
  };

  const canChangeWeight = () => {
    return dayjs().isBefore(dayjs(changeDateLimit));
  };

  return (
    <Paper elevation={4} className="container-inscription-list">
      <TableContainer>
        <Table>
          <TableHead className="table-head">
            <TableRow className="no-wrap">
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>Genero</TableCell>
              <TableCell>Peso kg</TableCell>
              <TableCell>Estado inscripción</TableCell>
              <TableCell>Validación Club</TableCell>
              <TableCell>Validación Federación</TableCell>
              <TableCell>Validación del Menor</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inscriptions && inscriptions?.length > 0
              ? inscriptions?.map((inscription) => (
                  <TableRow key={inscription.id} className="no-wrap">
                    <TableCell>{inscription.name}</TableCell>
                    <TableCell>{inscription.surname}</TableCell>
                    <TableCell>{inscription.genre}</TableCell>
                    <TableCell>{inscription.categoryWeight}</TableCell>
                    <TableCell>
                      {inscription.status === INSCRIPTION_STATUS.APPROVED
                        ? "aprobada"
                        : inscription.status}
                    </TableCell>
                    <TableCell>
                      {inscription.clubValidation
                        ? mapDateToRightFormat(inscription.clubValidation)
                        : "pendiente"}
                    </TableCell>
                    <TableCell>
                      {inscription.federationValidation
                        ? mapDateToRightFormat(inscription.federationValidation)
                        : "pendiente"}
                    </TableCell>
                    <TableCell>
                      {inscription.bornAt
                        ? isLegalAge(inscription.bornAt)
                          ? "mayor de edad"
                          : inscription.minorValidation
                          ? mapDateToRightFormat(inscription.minorValidation)
                          : "pendiente"
                        : null}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Anular inscripción" placement="top" arrow>
                        <IconButton
                          className="icon-button-cancel"
                          onClick={(_) => handleOpenRejectDialog(_, inscription)}
                        >
                          <HighlightOffIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      {canChangeWeight() && (
                        <Tooltip title={"Cambiar de categoría"} placement="top" arrow>
                          <span>
                            <IconButton
                              className="icon-button-accept"
                              sx={{ fontSize: "3rem" }}
                              onClick={(e) => handleOpenChangeCategory(e, inscription)}
                            >
                              <Scale fontSize="inherit" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <ChangeCategory
        isOpen={openCategoryDialog}
        onClose={() => setOpenCategoryDialog(false)}
        inscription={inscriptionToChange}
        stockCategories={stockCategories}
        refetchInscriptions={refetchInscriptions}
      />
      <RejectDialog
        isOpen={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        inscriptionId={selectedInscription?.id || ""}
        refetchInscriptions={refetchInscriptions}
      />
    </Paper>
  );
};

export default ValidatedList;
