import "./layout.scss";

import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import { Box, Container, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

import { useAppSelector } from "../../context/hooks/hooks";
import routes from "../../routes/routes";
import Drawer from "../drawer/Drawer";
import AppBar from "./header/Header";
import NavDrawer from "./navDrawer/NavDrawer";

const Dashboard = () => {
  const matches = useMediaQuery("(max-width:769px)");
  const [open, setOpen] = useState(!matches);
  const { userInfo } = useAppSelector((state) => state.auth);

  const toggleNavDrawer = () => {
    setOpen(!open);
  };

  // Esto es una prueba para la autorización
  const routesMemo = useMemo(
    () => routes.filter((route) => route.roles.some((role) => userInfo?.roles.includes(role))),
    [userInfo]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar isDrawerOpen={open} toggleNavDrawer={toggleNavDrawer} />
      <Drawer
        toggleDrawer={toggleNavDrawer}
        variant={matches ? "temporary" : "permanent"}
        open={open}
      >
        <NavDrawer open={open} routes={routesMemo} />
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar
          sx={(theme) => ({
            height: theme.spacing(9),
          })}
        />
        <Container maxWidth={false} sx={{ p: 0 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
