import { Controller, useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";

import { ORGANIZATOR_TYPES } from "../../../../constants/constants";
import { useAppSelector } from "../../../../context/hooks/hooks";
import useQueryClubsList from "../../../../utils/hooks/useQueryClubList";
import useQueryFederationsList from "../../../../utils/hooks/useQueryFederationsList";
import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};

const Organizer = ({ isDisabled }: Props) => {
  const { register, formState, control, watch, setValue } = useFormContext<TEvent>();
  const { errors } = formState;

  const { countries, autonomies, provinces, municipalities } = useAppSelector(
    (state) => state.formsOptions
  );

  //Datos de filtros
  const { data: clubs } = useQueryClubsList("");
  const { data: federations } = useQueryFederationsList();

  const province = watch("address.provinceCode");
  const organizatorType = watch("organizatorType");

  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Organizador</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Tipo Organizador*</Typography>
            <Controller
              name="organizatorType"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  className="input-select form-select-field"
                  error={!!errors.organizatorType}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Tipo Organizador</em>
                  </MenuItem>
                  {ORGANIZATOR_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.organizatorType && (
              <Typography className="form-input-error">
                {errors?.organizatorType?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Nombre Organizador*</Typography>
            {organizatorType === "club" ? (
              <Controller
                name="organizatorId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setValue(
                        "organizatorName",
                        clubs?.find((club) => club.id === e.target.value)?.name
                      );
                      setValue("organizatorId", e.target.value);
                    }}
                    displayEmpty
                    className="input-select form-select-field"
                    error={!!errors.organizatorId}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Club</em>
                    </MenuItem>
                    {clubs?.map((club) => (
                      <MenuItem key={club.id} value={club.id}>
                        {club.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            ) : organizatorType === "federation" ? (
              <Controller
                name="organizatorId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setValue(
                        "organizatorName",
                        federations?.find((federation) => federation.id === e.target.value)?.name
                      );
                      setValue("organizatorId", e.target.value);
                    }}
                    displayEmpty
                    className="input-select form-select-field"
                    error={!!errors.organizatorId}
                    slotProps={{ input: { readOnly: isDisabled } }}
                  >
                    <MenuItem value={""}>
                      <em>Federación</em>
                    </MenuItem>
                    {federations?.map((federation) => (
                      <MenuItem key={federation.id} value={federation.id}>
                        {federation.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            ) : (
              <TextField
                className="input-text form-text-field"
                variant="outlined"
                placeholder="Nombre*"
                error={!!errors?.organizatorName}
                {...register("organizatorName")}
                InputProps={{ readOnly: isDisabled }}
              />
            )}
            {errors?.organizatorName && (
              <Typography className="form-input-error">
                {errors?.organizatorName?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">CIF*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="CIF*"
              error={!!errors?.cif}
              {...register("cif")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.cif && (
              <Typography className="form-input-error">{errors?.cif?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Cuenta bancaria para pagos*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="IBAN*"
              error={!!errors?.iban}
              {...register("iban")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.iban && (
              <Typography className="form-input-error">{errors?.iban?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Dirección*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Dirección*"
              error={!!errors?.address?.address}
              {...register("address.address")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.address?.address && (
              <Typography className="form-input-error">
                {errors?.address?.address.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">País*</Typography>
            <Controller
              name="address.countryCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.countryCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>País</em>
                  </MenuItem>
                  {countries?.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.countryCode && (
              <Typography className="form-input-error">
                {errors?.address?.countryCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Autonomía</Typography>
            <Controller
              name="address.autonomyCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.autonomyCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Autonomía</em>
                  </MenuItem>
                  {autonomies?.map((autonomy) => (
                    <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                      {autonomy.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.autonomyCode && (
              <Typography className="form-input-error">
                {errors?.address?.autonomyCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Provincia*</Typography>
            <Controller
              name="address.provinceCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.provinceCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Provincia / Region</em>
                  </MenuItem>
                  {provinces
                    ?.filter((province) => province.autonomy_code === watch("address.autonomyCode"))
                    .map((province) => (
                      <MenuItem key={province.province_code} value={province.province_code}>
                        {province.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.provinceCode && (
              <Typography className="form-input-error">
                {errors?.address?.provinceCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Ciudad*</Typography>
            <Controller
              name="address.municipalityCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.municipalityCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Ciudad</em>
                  </MenuItem>
                  {municipalities
                    ?.filter((municipality) => municipality.province_code === province)
                    .map((municipality) => (
                      <MenuItem
                        key={municipality.municipality_code}
                        value={municipality.municipality_code}
                      >
                        {municipality.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.municipalityCode && (
              <Typography className="form-input-error">
                {errors?.address.municipalityCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">CP*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="CP*"
              error={!!errors?.address?.postalCode}
              {...register("address.postalCode")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.address?.postalCode && (
              <Typography className="form-input-error">
                {errors?.address.postalCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        {/* <Grid item md={6} xs={12}></Grid> */}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Teléfono*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Teléfono*"
              error={!!errors?.phoneNumber}
              {...register("phoneNumber")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.phoneNumber && (
              <Typography className="form-input-error">{errors?.phoneNumber?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Fax*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Fax*"
              error={!!errors?.faxNumber}
              {...register("faxNumber")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.faxNumber && (
              <Typography className="form-input-error">{errors?.faxNumber?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Correo*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="Email*"
              error={!!errors?.email}
              {...register("email")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.email && (
              <Typography className="form-input-error">{errors?.email?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Web*</Typography>
            <TextField
              className="input-text form-text-field"
              variant="outlined"
              placeholder="URL*"
              error={!!errors?.web}
              {...register("web")}
              InputProps={{ readOnly: isDisabled }}
            />
            {errors?.web && (
              <Typography className="form-input-error">{errors?.web?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Organizer;
