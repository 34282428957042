import { FormControl, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useMutation } from "react-query";
import { rejectInscription } from "../../../services/inscriptions";
import Button from "../../button/Button";
import { toastMessageError, toastMessageSuccess } from "../../common/toastMessage";
import "./reject-dialog.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  inscriptionId: string;
  refetchInscriptions: () => void;
};

function RejectDialog({ isOpen, onClose, inscriptionId, refetchInscriptions }: Props) {
  const [message, setNewMessage] = useState<string>("");

  const rejectMutation = useMutation(rejectInscription);

  const handleRejectInscription = async () => {
    try {
      await rejectMutation.mutateAsync({ id: inscriptionId, message: message });
      toastMessageSuccess("Inscripción anulada correctamente");
    } catch (error) {
      toastMessageError("Error al anular la inscripción");
    } finally {
      refetchInscriptions();
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="reject-dialog-title">Anular inscripción</DialogTitle>
      <DialogContent>
        <DialogContentText className="reject-dialog-text">
          ¿Estas seguro que deseas anular la inscripción? <br /> Por favor indica el motivo para
          continuar
        </DialogContentText>
        <Grid container className="rejected-textfield-container">
          <FormControl fullWidth>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              multiline
              label="Motivo"
              value={message}
              onChange={(e) => setNewMessage(e.target.value)}
            />
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions className="reject-dialog-actions">
        <Button type="submit" className="reject-button-cancel" onClick={() => onClose()}>
          CANCELAR
        </Button>
        <Button
          type="submit"
          className="reject-button-accept"
          onClick={handleRejectInscription}
          disabled={message === ""}
        >
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RejectDialog;
