import { Add, AssignmentInd, Delete, Edit, Remove, Visibility } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TUserSimple } from "../../utils/schemas/user";
import "./users-table.scss";
import { useAppDispatch } from "../../context/hooks/hooks";
import { toastMessageError, toastMessageSuccess } from "../common/toastMessage";
import { deleteUser, inactiveUser } from "../../context/forms/formsActions";
import CustomDialog from "../customDialog/CustomDialog";

export type UserCardProps = {
  users: TUserSimple[];
  refetch: () => void;
};

const UsersTable = ({ users, refetch }: UserCardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [openInactive, setOpenInactive] = useState<boolean>(false);
  const [openActive, setOpenActive] = useState<boolean>(false);

  const handleOpenDelete = (event: MouseEvent, id: string) => {
    setOpenDelete(true);
    setSelectedUser(id);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedUser("");
  };

  const handleEditButtonClick = (event: MouseEvent, id: string) => {
    navigate(`/users/${id}`);
    event.stopPropagation();
  };

  const handleSeeButtonClick = (event: MouseEvent, id: string) => {
    navigate(`/user/${id}`);
    event.stopPropagation();
  };

  const handleDeleteButtonClick = async () => {
    const response = await dispatch(deleteUser(selectedUser));
    if (response.type.includes("fulfilled")) {
      toastMessageSuccess("Usuario eliminado correctamente");
      refetch();
    } else {
      toastMessageError("Error al eliminar usuario");
    }
  };

  const handleSeeSportCard = (event: MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/users/sport-card/${id}`);
  };

  const handleInactiveUser = async () => {
    const response = await dispatch(inactiveUser({ id: selectedUser, value: false }));
    if (response.type.includes("fulfilled")) {
      toastMessageSuccess(`Usuario desactivado correctamente`);
      refetch();
    } else {
      toastMessageError("Error al desactivar usuario");
    }
  };
  const handleSendActive = async () => {
    const response = await dispatch(inactiveUser({ id: selectedUser, value: true }));
    if (response.type.includes("fulfilled")) {
      toastMessageSuccess(`Usuario activado correctamente`);
      refetch();
    } else {
      toastMessageError("Error al desactivar usuario");
    }
  };

  const handleOpen = (event: MouseEvent, id: string, value: boolean) => {
    event.stopPropagation();
    setSelectedUser(id);
    if (value) {
      setOpenActive(true);
    } else {
      setOpenInactive(true);
    }
  };

  return (
    <Paper elevation={4} className="container-user-list">
      <TableContainer>
        <Table>
          <TableHead className="user-user-head">
            <TableRow className="user-no-wrap">
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>CNR</TableCell>
              <TableCell>Estado deportivo</TableCell>
              <TableCell>Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user.id} className="user-no-wrap">
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.surname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.cnr}</TableCell>
                <TableCell>{user.isSportilyActive === 1 ? "Activo" : "Inactivo"}</TableCell>
                <TableCell className="user-no-wrap">
                  <Tooltip title="Ver ficha deportista" placement="top" arrow>
                    <IconButton
                      className="user-icon-button-accept"
                      onClick={(e) => handleSeeSportCard(e, user.id)}
                    >
                      <AssignmentInd fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver Usuario" placement="top" arrow>
                    <IconButton
                      className="user-icon-button-accept"
                      onClick={(e) => handleSeeButtonClick(e, user.id)}
                    >
                      <Visibility fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar Usuario" placement="top" arrow>
                    <IconButton
                      className="user-icon-button-accept"
                      onClick={(e) => handleEditButtonClick(e, user.id)}
                    >
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={user.isSportilyActive ? "Desactivar Usuario" : "Activar Usuario"}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      className={
                        user.isSportilyActive
                          ? "user-icon-button-cancel"
                          : "user-icon-button-accept"
                      }
                      onClick={(e) => handleOpen(e, user.id, user.isSportilyActive ? false : true)}
                    >
                      {user.isSportilyActive ? (
                        <Remove fontSize="inherit" />
                      ) : (
                        <Add fontSize="inherit" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar Usuario" placement="top" arrow>
                    <IconButton
                      className="user-icon-button-cancel"
                      onClick={(e) => handleOpenDelete(e, user.id)}
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDialog
        title="Eliminar Usuario"
        content="¿Estás seguro que deseas eliminar este usuario?"
        onAccept={handleDeleteButtonClick}
        isOpen={openDelete}
        onClose={handleCloseDelete}
      />
      <CustomDialog
        title="Desactivar Usuario"
        content="¿Estás seguro que deseas desactivar este usuario?"
        onAccept={handleInactiveUser}
        isOpen={openInactive}
        onClose={() => setOpenInactive(false)}
      />
      <CustomDialog
        title="Activar Usuario"
        content="¿Estás seguro que deseas activar este usuario?"
        onAccept={handleSendActive}
        isOpen={openActive}
        onClose={() => setOpenActive(false)}
      />
    </Paper>
  );
};

export default UsersTable;
