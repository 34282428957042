import { Add, Search } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { MouseEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROLES_OPTIONS } from "../../../constants/constants";
import useDebounce from "../../../utils/hooks/useDebouncing";
import useQueryClubsList from "../../../utils/hooks/useQueryClubList";
import useQueryFederationsList from "../../../utils/hooks/useQueryFederationsList";
import { type FilterState } from "../../../utils/schemas/listFilters";
import Button from "../../button/Button";
import SelectInput from "../../selectInput/SelectInput";
import TextInput from "../../textInput/TextInput";
import "./admin-filters.scss";

type Props = {
  handleFilterChange: (value: string | unknown, filterName: keyof FilterState) => void;
  filterState: FilterState;
};

const AdminFilters = ({ handleFilterChange, filterState }: Props) => {
  const navigate = useNavigate();
  //Datos de filtros
  const { data: clubs } = useQueryClubsList((filterState.federationId as string) || "");
  const { data: federations } = useQueryFederationsList();

  //Optimización de la búsqueda
  const debounceSearch = useDebounce((value) => handleFilterChange(value, "search"), 500);

  const handleAddAdmin = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate("/gestor_admins/agregar");
  };

  return (
    <>
      <Box className="admins-header">
        {/* SEARCH BAR */}
        <Box className="admins-header-searchInput-container">
          <IconButton>
            <Search className="admins-header-searchInput-icon" />
          </IconButton>
          <TextInput
            placeholder="Buscar"
            minWidth="full-width"
            onChange={(e) => debounceSearch(e.target.value)}
          />
        </Box>
        <Box className="admins-header-actions-container">
          {}
          <Link to="/admins/agregar" className="admins-header-actions-button">
            <Button
              startIcon={<Add />}
              rounded="md"
              width="fit-content"
              className="admins-header-actions-button"
              onClick={handleAddAdmin}
            >
              AGREGAR ADMIN
            </Button>
          </Link>
        </Box>
      </Box>
      <Box className="admins-header">
        <Box className="admins-filters">
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Federaciones: Todas"
            options={[
              { value: "", label: "Todas las federaciones" },
              ...(federations && federations.length > 0
                ? federations.map((federation) => ({
                    value: federation.id,
                    label: federation.name,
                  }))
                : []),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "federationId")}
            value={filterState.federationId}
          />
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Clubs: Todos"
            options={[
              { value: "", label: "Todos los Clubs" },
              ...(clubs && clubs.length > 0
                ? clubs.map((club) => ({
                    value: club.id,
                    label: club.name,
                  }))
                : []),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "clubId")}
            value={filterState.clubId}
          />
          <SelectInput
            rounded="md"
            defaultValue=""
            title="Roles: Todos"
            options={[
              ...ROLES_OPTIONS.map((rol) => ({
                value: rol.value,
                label: rol.label,
              })),
            ]}
            onChange={(e) => handleFilterChange(e.target.value, "role")}
            value={filterState.role}
          />
        </Box>
      </Box>
    </>
  );
};

export default AdminFilters;
