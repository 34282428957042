import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};

const ResponsableContact = ({ isDisabled }: Props) => {
  const { register, formState, setValue, watch, clearErrors } = useFormContext<TEvent>();
  const { errors } = formState;

  const responsables = watch("hasResponsables");
  const responsableMembers = watch("responsables");

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("hasResponsables", e.target.checked);
    } else {
      setValue("hasResponsables", e.target.checked);
      setValue("responsables", []);
      clearErrors("responsables");
    }
  };

  const handleAddResponsable = () => {
    if (responsableMembers) {
      setValue("responsables", [
        ...responsableMembers,
        {
          name: "",
          surname: "",
          phone: "",
          familiarRelationship: "",
        },
      ]);
    } else {
      setValue("responsables", [
        {
          name: "",
          surname: "",
          phone: "",
          familiarRelationship: "",
        },
      ]);
    }
  };

  return (
    <Box className="form-section-container">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography className="form-section-title">Contactos de responsables</Typography>
        <Switch
          onChange={handleSwitchChange}
          className="form-switch"
          checked={responsables || false}
          disabled={isDisabled}
        />
      </Stack>
      {responsables && (
        <>
          {responsableMembers?.map((_, index) => (
            <Grid container sx={{ mb: 8 }} key={`responsable - ${index}`}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">
                  <IconButton
                    className="form-section-remove-button"
                    onClick={() => {
                      setValue(
                        "responsables",
                        responsableMembers.filter((_, i) => i !== index)
                      );
                    }}
                    disabled={isDisabled}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Nombre*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Nombre*"
                      error={!!errors?.responsables?.[index]?.name}
                      {...register(`responsables.${index}.name`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsables?.[index]?.name && (
                      <Typography className="form-input-error">
                        {errors?.responsables?.[index]?.name?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Apellido*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Apellido*"
                      error={!!errors?.responsables?.[index]?.surname}
                      {...register(`responsables.${index}.surname`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsables?.[index]?.surname && (
                      <Typography className="form-input-error">
                        {errors?.responsables?.[index]?.surname?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={8}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Numero de teléfono*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Numero de teléfono*"
                      error={!!errors?.responsables?.[index]?.phone}
                      {...register(`responsables.${index}.phone`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsables?.[index]?.phone && (
                      <Typography className="form-input-error">
                        {errors?.responsables?.[index]?.phone?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <Typography className="form-input-label">Responsabilidad*</Typography>
                    <TextField
                      className="input-text form-text-field"
                      variant="outlined"
                      placeholder="Responsabilidad*"
                      error={!!errors?.responsables?.[index]?.familiarRelationship}
                      {...register(`responsables.${index}.familiarRelationship`)}
                      InputProps={{ readOnly: isDisabled }}
                    />
                    {errors?.responsables?.[index]?.familiarRelationship && (
                      <Typography className="form-input-error">
                        {errors?.responsables?.[index]?.familiarRelationship?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Stack direction="row" justifyContent="flex-start">
            <ButtonBase
              onClick={handleAddResponsable}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                width: "150px",
              }}
              disabled={isDisabled}
            >
              <AddCircleOutline /> Añadir responsable
            </ButtonBase>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default ResponsableContact;
