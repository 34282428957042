import { Drawer, IconButton } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer";
import { DRAWERWIDTH } from "../../constants/layout";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import "./drawer.scss";

type Props = DrawerProps & {
  open: boolean;
  toggleDrawer: () => void;
  size?: "sm" | "md" | "xl";
};

const CustomDrawer = ({
  open,
  toggleDrawer,
  size = "sm",
  children,
  ...props
}: Props) => {
  return (
    <Drawer
      open={open}
      {...props}
      className="drawer-container"
      sx={(theme) => ({
        "& .MuiDrawer-paper": {
          width: DRAWERWIDTH[size],
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: "hidden",
          ...(!open && {
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
              width: theme.spacing(9),
            },
          }),
        },
      })}
    >
      <IconButton
        onClick={toggleDrawer}
        className={`drawer-chevron-container ${props.anchor}`}
      >
        {open ? (
          <ChevronLeft className="drawer-chevron" />
        ) : (
          <ChevronRight className="drawer-chevron" />
        )}
      </IconButton>
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
