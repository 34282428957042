import { useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

import { TClub } from "../../../../utils/schemas/club";
import ImageUpload from "../../../imageUpload/ImageUpload";

type Props = {
  isDisabled?: boolean;
};

const ClubData = ({ isDisabled }: Props) => {
  const { register, formState, getValues, setValue } = useFormContext<TClub>();
  const { errors } = formState;
  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">Datos del club</Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={3.5} xs>
          <Typography className="form-input-label">Imagen/Logo</Typography>
          <ImageUpload
            disabled={isDisabled}
            url={getValues("logoUrl") || ""}
            multi={false}
            onImageSelect={(e) =>
              typeof e === "string" ? setValue("logoUrl", e) : setValue("logoUrl", "")
            }
          />
        </Grid>
        <Grid item md={8.5} xs={12}>
          <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">Nombre*</Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Nombre"
                  required
                  InputProps={{
                    readOnly: isDisabled,
                  }}
                  error={!!errors?.name}
                  {...register("name")}
                />
                {errors?.name && (
                  <Typography className="form-input-error">{errors?.name?.message}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <Typography className="form-input-label">
                  Abreviación debe ser 3 o 4 siglas*
                </Typography>
                <TextField
                  className="input-text form-text-field"
                  variant="outlined"
                  placeholder="Abreviación"
                  InputProps={{
                    readOnly: isDisabled,
                  }}
                  error={!!errors?.shortCode}
                  {...register("shortCode")}
                />
                {errors?.shortCode && (
                  <Typography className="form-input-error">{errors?.shortCode?.message}</Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth>
            <Typography className="form-input-label">Detalles de la historia</Typography>
            <TextField
              className="input-text"
              variant="outlined"
              placeholder="Descripción"
              multiline
              rows={6}
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.description}
              {...register("description")}
            />
            {errors?.description && (
              <Typography className="form-input-error">{errors?.description?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClubData;
