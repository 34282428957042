import { createSlice } from "@reduxjs/toolkit";
import { TUserSimple } from "../../utils/schemas/user";
import { TUserInfo } from "../../utils/schemas/userDashboard";
import { loginUser } from "./authActions";

type InitialStateType = {
  loading: boolean;
  userInfo: TUserInfo;
  userData: TUserSimple | null | undefined;
  userToken: string | null;
  error: string | null;
  success: boolean;
};

const initialState: InitialStateType = {
  loading: false,
  userInfo: null,
  userData: null,
  userToken: null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.userData = action.payload.userData;
      state.userToken = action.payload.token;
      state.success = true;
    },
    removeUser: () => ({ ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload.userInfo;
        state.userData = payload.userData;
        state.userToken = payload.token;
        state.success = true;
      })
      .addCase(loginUser.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message ?? null;
      });
  },
});

export const { setUser, removeUser } = authSlice.actions;

export default authSlice.reducer;
