export const INPUTMINWIDTH = {
  sm: "100px",
  md: "200px",
  "full-width": "100%",
};
export const INPUTROUNDED = {
  none: 0,
  sm: 1,
  md: 3,
  xl: 25,
};
