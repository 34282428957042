import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { isLegalAge, mapDateToRightFormat } from "../../../../utils/date";
import { TInscription } from "../../../../utils/schemas/inscriptions";

type Props = {
  inscriptions: TInscription[];
};

const RejectedList = ({ inscriptions }: Props) => {
  return (
    <Paper elevation={4} className="container-inscription-list">
      <TableContainer>
        <Table>
          <TableHead className="table-head">
            <TableRow className="no-wrap">
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>Genero</TableCell>
              <TableCell>Peso kg</TableCell>
              <TableCell>Validación Club</TableCell>
              <TableCell>Validación Federación</TableCell>
              <TableCell>Validación del Menor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inscriptions && inscriptions?.length > 0
              ? inscriptions?.map((inscription) => (
                  <TableRow key={inscription.id} className="no-wrap">
                    <TableCell>{inscription.name}</TableCell>
                    <TableCell>{inscription.surname}</TableCell>
                    <TableCell>{inscription.genre}</TableCell>
                    <TableCell>{inscription.categoryWeight}</TableCell>
                    <TableCell>
                      {inscription.clubValidation
                        ? mapDateToRightFormat(inscription.clubValidation)
                        : "pendiente"}
                    </TableCell>
                    <TableCell>
                      {inscription.federationValidation
                        ? mapDateToRightFormat(inscription.federationValidation)
                        : "pendiente"}
                    </TableCell>
                    <TableCell>
                      {inscription.bornAt
                        ? isLegalAge(inscription.bornAt)
                          ? "mayor de edad"
                          : inscription.minorValidation
                          ? mapDateToRightFormat(inscription.minorValidation)
                          : "pendiente"
                        : null}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RejectedList;
