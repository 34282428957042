import { useQuery } from "react-query";
import { INSCRIPTION_STATUS } from "../../constants/constants";
import { RQ_KEY } from "../../constants/query";
import { getAllInscriptions } from "../../services/inscriptions";
import { TQueryInscription } from "../schemas/inscriptions";
import { TInscriptionFilters } from "../schemas/listFilters";

export function useQueryInscriptionsPending({
  search,
  page,
  perPage,
  eventId,
  genre,
  categoryCode,
  clubId,
  federationId,
}: TInscriptionFilters) {
  return useQuery<TQueryInscription, Error>(
    [
      RQ_KEY.ALL_INSCRIPTIONS_PENDING,
      search,
      page,
      perPage,
      eventId,
      genre,
      categoryCode,
      clubId,
      federationId,
    ],
    () =>
      getAllInscriptions({
        search,
        page,
        perPage,
        eventId,
        genre,
        categoryCode,
        clubId,
        federationId,
        status: INSCRIPTION_STATUS.PENDING,
      })
  );
}

export function useQueryInscriptionsValidated({
  search,
  page,
  perPage,
  eventId,
  genre,
  categoryCode,
  clubId,
  federationId,
}: TInscriptionFilters) {
  return useQuery<TQueryInscription, Error>(
    [
      RQ_KEY.ALL_INSCRIPTIONS_VALIDATED,
      search,
      page,
      perPage,
      eventId,
      genre,
      categoryCode,
      clubId,
      federationId,
    ],
    () =>
      getAllInscriptions({
        search,
        page,
        perPage,
        eventId,
        status: INSCRIPTION_STATUS.APPROVED_OR_RESERVED,
        genre,
        categoryCode,
        clubId,
        federationId,
      }),
    { enabled: eventId !== "" }
  );
}

export function useQueryInscriptionsRejected({
  search,
  page,
  perPage,
  eventId,
  genre,
  categoryCode,
  clubId,
  federationId,
}: TInscriptionFilters) {
  return useQuery<TQueryInscription, Error>(
    [
      RQ_KEY.ALL_INSCRIPTIONS_REJECTED,
      search,
      page,
      perPage,
      eventId,
      genre,
      categoryCode,
      clubId,
      federationId,
    ],
    () =>
      getAllInscriptions({
        search,
        page,
        perPage,
        eventId,
        status: INSCRIPTION_STATUS.REJECTED,
        genre,
        categoryCode,
        clubId,
        federationId,
      })
  );
}
