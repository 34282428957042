import { FormControl, Grid, IconButton, SelectChangeEvent } from "@mui/material";
import { GENRES_OPTIONS, INSCRIPTIONS_ROLES, ROLES } from "../../../../constants/constants";
import { useAppSelector } from "../../../../context/hooks/hooks";
import SelectInput from "../../../selectInput/SelectInput";

import { Search } from "@mui/icons-material";
import useDebounce from "../../../../utils/hooks/useDebouncing";
import { TClubList } from "../../../../utils/schemas/club";
import { TFederationsList } from "../../../../utils/schemas/federations";
import { TInscriptionFilters } from "../../../../utils/schemas/listFilters";
import TextInput from "../../../textInput/TextInput";
import "./inscriptions-filters.scss";

type Props = {
  selectedRole: string;
  setSelectedRole: (role: string) => void;
  updatePendingFilter: (key: keyof TInscriptionFilters, value: string) => void;
  pendingFilters: TInscriptionFilters;
  clubs: TClubList[] | undefined;
  federations: TFederationsList[] | undefined;
};

const PendingFilters = ({
  selectedRole,
  setSelectedRole,
  updatePendingFilter,
  pendingFilters,
  clubs,
  federations,
}: Props) => {
  const { userInfo } = useAppSelector((state) => state.auth);

  const handleChangeRol = (e: SelectChangeEvent<unknown>) => {
    setSelectedRole(e.target.value as string);
  };

  //Search optimization
  const debounceSearch = useDebounce((value) => updatePendingFilter("search", value), 500);

  return (
    <Grid
      item
      container
      className="inscriptions-filters-container"
      columnSpacing={2}
      rowSpacing={2}
      xs={12}
    >
      <Grid className="search-box" item>
        <IconButton>
          <Search className="inscriptions-header-searchInput-icon" />
        </IconButton>
        <TextInput
          placeholder="Buscar"
          minWidth="full-width"
          onChange={(e) => debounceSearch(e.target.value)}
        />
      </Grid>
      {(userInfo?.roles && userInfo?.roles?.length > 1) ||
      userInfo?.roles.includes(ROLES.SUPER_ADMIN) ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={selectedRole}
              rounded="md"
              onChange={handleChangeRol}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Rol para inscribir" },
                ...INSCRIPTIONS_ROLES.map((role) => ({
                  value: role,
                  label: role,
                })),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      {federations && federations.length > 1 ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={pendingFilters.federationId}
              rounded="md"
              onChange={(e) => updatePendingFilter("federationId", e.target.value as string)}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Federación" },
                ...(federations?.map((federation) => ({
                  value: federation.id,
                  label: federation.name,
                })) || []),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      {clubs && clubs.length > 1 ? (
        <Grid item>
          <FormControl>
            <SelectInput
              value={pendingFilters.clubId}
              rounded="md"
              onChange={(e) => updatePendingFilter("clubId", e.target.value as string)}
              displayEmpty
              defaultValue=""
              options={[
                { value: "", label: "Club" },
                ...(clubs?.map((club) => ({
                  value: club.id,
                  label: club.name,
                })) || []),
              ]}
            ></SelectInput>
          </FormControl>
        </Grid>
      ) : null}
      <Grid item>
        <FormControl>
          <SelectInput
            value={pendingFilters.genre}
            rounded="md"
            onChange={(e) => updatePendingFilter("genre", e.target.value as string)}
            displayEmpty
            defaultValue=""
            options={GENRES_OPTIONS}
          ></SelectInput>
        </FormControl>
      </Grid>
      {/* filtros para añadir:
             categoryCode: "",
      */}
    </Grid>
  );
};

export default PendingFilters;
