import { ChangeEvent, DragEventHandler, useEffect, useState } from "react";

import { Close, CloudUpload } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import Button from "../button/Button";
import "./pdf-upload.scss";

type PdfUploadType = {
  onChange: (value: string) => void;
  value: string | null;
  disabled?: boolean;
};

let idCounter = 0;

const PdfUpload = ({ onChange, value, disabled }: PdfUploadType) => {
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [inputId, setInputId] = useState("");

  useEffect(() => {
    setInputId(`pdfInput-${idCounter++}`);
    const pdfData = value?.startsWith("data:application/pdf;base64,");
    const newValue = pdfData ? value : value ? `data:application/pdf;base64,${value || ""}` : null;
    setSelectedPdf(newValue);
  }, [value]);

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handlePdfUpload(files);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handlePdfUpload(files);
  };

  const handlePdfUpload = (files: FileList | null) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const pdfDataUrl = reader.result as string;
      setSelectedPdf(pdfDataUrl);
      onChange && onChange(pdfDataUrl);
    };
    reader.readAsDataURL(file);
  };

  const handleDelete = () => {
    setSelectedPdf(null);
    onChange && onChange("");
  };

  return (
    <Grid
      container
      className={`pdfContainer  dropZonePdf`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
    >
      {!selectedPdf ? (
        <>
          {disabled ? (
            <Box
              component="img"
              alt="Upload"
              src={selectedPdf ? selectedPdf : "/media/upload-pdf.svg"}
              sx={{ height: 120, width: 170, mt: 2 }}
              className="cloudIcon"
            />
          ) : selectedPdf ? (
            <>
              <Box component="img" alt="Upload" src={selectedPdf} className="uploadedPdf" />
              <IconButton
                sx={{ mt: 1, width: 50, height: 50, textAlign: "center" }}
                onClick={handleDelete}
              >
                <Close fontSize="small" />
              </IconButton>
            </>
          ) : (
            <Grid>
              <CloudUpload sx={{ fontSize: 40 }} />
              <p>Arrastre y suelte para subir</p>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Box
            component="embed"
            src={selectedPdf}
            type="application/pdf"
            sx={{ width: "100%", height: "100%" }}
            className="uploadedPdf"
          />{" "}
          {!disabled && (
            <IconButton
              sx={{ mt: 1, width: 50, height: 50, textAlign: "center" }}
              onClick={handleDelete}
            >
              <Close fontSize="small" />
            </IconButton>
          )}
        </>
      )}
      <input
        type="file"
        accept="application/pdf"
        id={inputId}
        style={{ display: "none" }}
        onChange={handleFileInput}
      />
      <label htmlFor={inputId} style={{ width: "100%" }}>
        {!disabled && (
          <Button
            component="span"
            width="full-width"
            variant="contained"
            sx={{ mt: 3, mb: 2, px: 3, fontSize: "15px", borderRadius: 3 }}
          >
            SUBIR PDF
          </Button>
        )}
      </label>
    </Grid>
  );
};

export default PdfUpload;
