import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { AddFederationSchemaType } from "../schemas/federations";
import { getFederationId } from "../../services/federations";
import { useState } from "react";

export default function useQueryFederationId(id: string) {
  const [isActive, setIsActive] = useState(false);

  const federationData = useQuery<AddFederationSchemaType, Error>(
    [RQ_KEY.FEDERATION_ID, id],
    () => getFederationId(id),
    {
      enabled: isActive && id !== null,
    }
  );

  const activate = () => {
    setIsActive(true);
  };

  return {
    federationData,
    activate,
  };
}
