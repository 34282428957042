import "./ranking.scss";

import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { MouseEvent, useState } from "react";
import Loading from "../../components/loader/Loading";
import RankingFilters from "../../components/rankings/RankingFilters";
import RankingHeader from "../../components/rankings/RankingHeader";
import { GENRES, ROLES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { TRanking, TRankingFilters } from "../../utils/schemas/rankings";
import useDebounce from "../../utils/hooks/useDebouncing";
import useQueryRankings from "../../utils/hooks/useQueryRankings";
import Button from "../../components/button/Button";
import GenerateDialog from "../../components/rankings/dialog/GenerateDialog";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { Link, useNavigate } from "react-router-dom";
import { AssignmentInd } from "@mui/icons-material";

const Ranking = () => {
  const navigate = useNavigate();
  useSetPageName([{ name: "Rankings", path: "/ranking" }]);
  const { categories } = useAppSelector((state) => state.formsOptions);
  const { userInfo } = useAppSelector((state) => state.auth);
  const canGenerateRanking =
    userInfo?.roles?.includes(ROLES.SUPER_ADMIN) || userInfo?.roles?.includes(ROLES.REFEJYDA_IT);

  const [rankingFilters, setRankingFilters] = useState<TRankingFilters>({
    date: dayjs().toISOString(),
    weight: "",
    search: "",
    sportCode: "",
    genre: "",
    page: 1,
    perPage: 10,
    category: "",
  });
  const [selectedCategory, setSelectedCategory] = useState<string | null>("");

  const { data, isLoading, isRefetching, refetch } = useQueryRankings(rankingFilters);

  const handleFilterChange = (
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => {
    setRankingFilters((prev) => ({
      ...prev,
      [filter]: value,
    }));
  };

  const handleChangeCategory = (id: string) => {
    setSelectedCategory(id);
    const category = categories?.find((category) => category.id === id);
    handleFilterChange("genre", category?.genre);
    handleFilterChange("weight", category?.weight);
  };

  //Search optimization
  const searchFunction = (searchTerm: string) => {
    handleFilterChange("search", searchTerm);
  };

  const debouncedSearch = useDebounce(searchFunction, 500);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  //Dialog
  const [openGenerate, setOpenGenerate] = useState(false);

  const handleOpenGenerate = () => {
    setOpenGenerate(true);
  };

  const handleSeeSportCard = (event: MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/users/sport-card/${id}`);
  };

  return (
    <Container className="ranking-container">
      {canGenerateRanking && (
        <Grid container justifyContent="flex-end">
          <Button rounded="md" width="fit-content" onClick={handleOpenGenerate}>
            Generar ranking
          </Button>
        </Grid>
      )}
      <RankingHeader
        handleFilterChange={handleFilterChange}
        data={data}
        handleSearchChange={handleSearchChange}
      />
      <Box className="ranking-table-top">
        <Box className="ranking-table-top-title">
          <Typography component="h4" className="ranking-title">
            Ranking{" "}
            {rankingFilters.genre === GENRES.MEN
              ? "Hombres"
              : rankingFilters.genre === GENRES.WOMEN
              ? "Mujeres"
              : ""}
          </Typography>
          <Typography component="h4" className="ranking-date">
            Version: {dayjs(rankingFilters.date).format("DD/MM/YYYY").toString()}
          </Typography>
        </Box>
        <RankingFilters
          handleChangeCategory={handleChangeCategory}
          handleFilterChange={handleFilterChange}
          rankingFilters={rankingFilters}
          selectedCategory={selectedCategory}
        />
      </Box>

      {isLoading || isRefetching ? (
        <Loading />
      ) : data && data.data.length > 0 ? (
        <Box className="ranking-body" component={Paper} elevation={4}>
          <Box className="ranking-card-table-container">
            <TableContainer>
              <Table className="ranking-card-table" size="small">
                {
                  <TableHead className="ranking-card-table-head">
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">Peso</TableCell>
                      <TableCell align="center">Imagen</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                      <TableCell align="center">Apellidos</TableCell>
                      <TableCell align="center">Club</TableCell>
                      <TableCell align="center">Federación</TableCell>
                      <TableCell align="center">Puntuación</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                }
                <TableBody>
                  {data.data.map((row: TRanking, index: number) => (
                    <TableRow
                      hover
                      key={row.name + "-" + index}
                      className="ranking-card-table-row"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        {row.weight}
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        <Avatar src={row.avatarUrl} />
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        {row.name}
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        {row.surname}
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        <Link
                          to={`/clubs/${row.clubId}/ver`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {row.clubShortCode}
                        </Link>
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        <Link
                          to={`/federacion/${row.federationId}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {row.federationShortCode}
                        </Link>
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        {row.score} pts
                      </TableCell>
                      <TableCell className="ranking-card-table-cell" size="small" align="center">
                        {" "}
                        <Tooltip title="Ver ficha deportista" placement="top" arrow>
                          <IconButton
                            className="user-icon-button-accept"
                            onClick={(e) => handleSeeSportCard(e, row.id)}
                          >
                            <AssignmentInd fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Typography>No hay rankings disponibles</Typography>
      )}
      <GenerateDialog
        isOpen={openGenerate}
        onClose={() => setOpenGenerate(false)}
        refetch={refetch}
      />
    </Container>
  );
};

export default Ranking;
