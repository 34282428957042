import { useState } from "react";
import {
  TInscriptionFilters,
  initialApprovedFilter,
  initialPendingFilter,
  initialRejectedFilter,
} from "../schemas/listFilters";

function useInscriptionFilters() {
  const [pendingFilters, setPendingFilter] = useState(initialPendingFilter);
  const [approvedFilters, setApprovedFilter] = useState(initialApprovedFilter);
  const [rejectedFilters, setRejectedFilter] = useState(initialRejectedFilter);

  const updatePendingFilter = (newFilter: keyof TInscriptionFilters, value: string | number) => {
    setPendingFilter((prev) => ({ ...prev, [newFilter]: value }));
  };

  const updateApprovedFilter = (newFilter: keyof TInscriptionFilters, value: string | number) => {
    setApprovedFilter((prev) => ({ ...prev, [newFilter]: value }));
  };

  const updateRejectedFilter = (newFilter: keyof TInscriptionFilters, value: string | number) => {
    setRejectedFilter((prev) => ({ ...prev, [newFilter]: value }));
  };

  return {
    pendingFilters,
    updatePendingFilter,
    approvedFilters,
    updateApprovedFilter,
    rejectedFilters,
    updateRejectedFilter,
  };
}

export default useInscriptionFilters;
