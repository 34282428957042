import { z } from "zod";
import { MESSAGE } from "../../constants/errorMessages";

export type FederationsQuery = {
  data: Array<Federation>;
  total: number;
  page: number;
  perPage: number;
};

export type TFederationsList = {
  id: string;
  name: string;
};

export type TFederationSimple = {
  id: string;
  name: string;
  email: string;
  phone: string;
  federationId: string;
  description: string;
  website: string;
  logoUrl: string;
  isActive: boolean;
  city: string;
  players: number;
  trainers: number;
  events: number;
  clubs: number;
  pendingEvents: number;
};

export type Federation = {
  id: string;
  name: string;
  cityName: string;
  responsibleName: string;
  email: string;
  clubs: number;
  deportistas: number;
  entrenadores: number;
  eventos: number;
  eventosPendientes: number;
  admins: number;
};

export const addFederationSchema = z.object({
  shortCode: z.string().min(1, { message: MESSAGE.REQUIRED }).max(5, { message: MESSAGE.MAX_5 }),
  name: z.string().min(1, { message: MESSAGE.REQUIRED }).max(255, { message: MESSAGE.MAX_255 }),
  logoUrl: z.string().optional(),
  description: z.string().optional(),
  address: z.object({
    address: z
      .string()
      .min(1, { message: MESSAGE.REQUIRED })
      .max(255, { message: MESSAGE.MAX_255 }),
    countryCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    autonomyCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    provinceCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    municipalityCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
    postalCode: z.string().min(1, { message: MESSAGE.REQUIRED }),
  }),
  phone: z.string().optional(),
  email: z.string().email({ message: MESSAGE.EMAIL }).optional(),
  isFiscalAddressSameAsAddress: z.boolean().optional(),
  fiscalAddress: z.object({
    address: z.string().optional(),
    countryCode: z.string().optional(),
    autonomyCode: z.string().optional(),
    provinceCode: z.string().optional(),
    municipalityCode: z.string().optional(),
    postalCode: z.string().optional(),
  }),
  fiscalName: z.string().optional(),
  iban: z.string().optional(),
  cif: z.string().optional(),

  //Step 2
  timetable: z.union([
    z.record(z.array(z.object({ start: z.string(), end: z.string() }))).optional(),
    z.string(),
  ]),
  mapsUrl: z.string().optional().nullable(),
  socialMedia: z.object({
    instagramUrl: z.string().optional().nullable().default(null),
    twitterUrl: z.string().optional().nullable().default(null),
    whatsappUrl: z.string().optional().nullable().default(null),
    youtubeUrl: z.string().optional().nullable().default(null),
    tiktokUrl: z.string().optional().nullable().default(null),
    facebookUrl: z.string().optional().nullable().default(null),
    id: z.string().optional(),
  }),
  latitude: z.number().optional(),
  longitude: z.number().optional(),

  //MIRAR QUE HACEMOS CON ESTO
  contactId: z.string().optional().default("637e9fe5-f42d-44a2-85d7-85553f3ae9e7"),
});

export type AddFederationSchemaType = z.infer<typeof addFederationSchema>;
