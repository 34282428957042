import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ButtonGroup, IconButton } from "@mui/material";
import { setFilter } from "../../../context/filters/filterSlice";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import { FilterState } from "../../../utils/schemas/listFilters";
import "./arrow-buttons.scss";

type Props = {
  id: string;
  filterState: FilterState;
  handleFilterChange: (value: string | unknown, filterName: keyof FilterState) => void;
};

const ArrowButtons = ({ id, filterState, handleFilterChange }: Props) => {
  const order = { ...filterState.order } || { asc: [], desc: [] };

  //filtros
  const handleSortRefunds = (id: string, orderType: string) => {
    const prevOrder = { ...order };
    const oppositeOrderType = orderType === "asc" ? "desc" : "asc";
    const newOrder = {
      ...prevOrder,
      [orderType]: prevOrder[orderType].includes(id)
        ? prevOrder[orderType].filter((columnId) => columnId !== id)
        : [...prevOrder[orderType], id],
      [oppositeOrderType]: prevOrder[oppositeOrderType]?.filter((columnId) => columnId !== id),
    };

    handleFilterChange(newOrder, "order");
  };

  return (
    <ButtonGroup sx={{ fontSize: "5px", gap: "2px" }}>
      <IconButton
        aria-label="sort"
        size="small"
        name={"asc"}
        onClick={() => handleSortRefunds(id, "asc")}
        className={order["asc"]?.includes(id) ? "btn-check-validated" : "btn-check"}
        sx={{ height: "20px", width: "20px" }}
      >
        <ExpandLess />
      </IconButton>
      <IconButton
        aria-label="sort"
        size="small"
        name={"desc"}
        onClick={() => handleSortRefunds(id, "desc")}
        className={order["desc"]?.includes(id) ? "btn-check-validated" : "btn-check"}
        sx={{ height: "20px", width: "20px" }}
      >
        <ExpandMore />
      </IconButton>
    </ButtonGroup>
  );
};

export default ArrowButtons;
