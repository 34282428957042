import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import bcrypt from "bcryptjs";
import { API_URL, SALT, SERVICE } from "../../constants/api";
import http, { setHttpBearerToken } from "../../constants/axios";
import { getUser } from "../../services/users";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      //encode con el salt
      data.password = bcrypt.hashSync(data.password, SALT);

      //solicitud
      const request = await http.post(`${API_URL}${SERVICE.AUTH}`, data);

      const response = await request.data.token;

      //decodifico el token
      const userInfo = { ...decodeJWT(response) };

      localStorage.setItem("user", JSON.stringify(userInfo));
      localStorage.setItem("token", response);
      setHttpBearerToken(response);

      //Info of the user
      const userData = await getUser(userInfo.id);

      return { userInfo, token: response, userData };
    } catch (error) {
      return rejectWithValue("Email / Contraseña incorrectas");
    }
  }
);

function decodeJWT(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (data: { email: string; site_verification_url: string }, { rejectWithValue }) => {
    try {
      const request = await axios.post(`${API_URL}${SERVICE.FORGET_PASSWORD}`, data);
      return request.data;
    } catch (error) {
      console.log("Error forget password", error);
      return rejectWithValue("Email no encontrado");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: { password: string; token: string }, { rejectWithValue }) => {
    try {
      const request = await axios.post(`${API_URL}${SERVICE.RESET_PASSWORD}`, data);
      return request.data;
    } catch (error) {
      console.log("Error reset password", error);
      return rejectWithValue("Error al cambiar la contraseña");
    }
  }
);
