import { useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";

import { TEvent } from "../../../../utils/schemas/event";

type Props = {
  isDisabled?: boolean;
};
const DataAndAuth = ({ isDisabled }: Props) => {
  const { register, formState } = useFormContext<TEvent>();
  const { errors } = formState;

  return (
    <Box className="form-section-container">
      <Typography className="form-section-title">
        Gestión de datos y Autorización Paterna
      </Typography>
      <Grid container columnSpacing={4} rowGap={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Descripción*</Typography>
            <TextField
              className="input-text"
              variant="outlined"
              placeholder="Descripción*"
              multiline
              rows={4}
              error={!!errors?.dataAndParentalDescription}
              {...register("dataAndParentalDescription")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.dataAndParentalDescription && (
              <Typography className="form-input-error">
                {errors?.dataAndParentalDescription?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataAndAuth;
