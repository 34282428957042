import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Button from "../../button/Button";

import { CloseOutlined, Download } from "@mui/icons-material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createSomeUsers } from "../../../context/forms/formsActions";
import { useAppDispatch } from "../../../context/hooks/hooks";
import { toastMessageError, toastMessageSuccess } from "../../common/toastMessage";
import "./createUsersDialog.scss";
import CsvReader from "./csvReader/CsvReader";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateUsersDialog = ({ isOpen, onClose }: Props) => {
  const [isFile, setIsFile] = useState<boolean>(false);
  const [csvData, setCSVData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [creationProblems, setCreationProblems] = useState<any[]>([]);
  const [showProblems, setShowProblems] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleDownloadCSV = () => {
    const csv = "Nombre,Apellidos,Email,Telefono,FechaNacimiento\n";
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "AltaUsuarios.csv";
    hiddenElement.click();
  };

  const handleSendUsers = async () => {
    setIsLoading(true);
    const dataToSend = csvData.map((user: any) => {
      return {
        name: user.Nombre,
        surname: user.Apellidos,
        email: user.Email,
        bornAt: dayjs(user.FechaNacimiento).toDate(),
      };
    });
    const body = {
      usersData: dataToSend,
    };
    const response = await dispatch(createSomeUsers(body));
    if (response.type.includes("fulfilled")) {
      if (response.payload.creationProblems.length > 0) {
        setCreationProblems(response.payload.creationProblems);
        setShowProblems(response.payload.creationProblems.length > 0);
      } else {
        toastMessageSuccess("Usuarios creados correctamente");
        onClose();
      }
    } else if (response.type.includes("rejected")) {
      toastMessageError("Error al enviar los usuarios");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      setIsFile(false);
      setCSVData([]);
      setIsLoading(false);
      setCreationProblems([]);
      setShowProblems(false);
    };
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="users-dialog-title">
        <Typography className="users-dialog-title-text">Crear usuarios </Typography>

        <IconButton onClick={onClose}>
          <CloseOutlined className="users-dialog-title-icon" />
        </IconButton>
      </DialogTitle>
      {!showProblems ? (
        <DialogContent>
          <Grid container className="users-dialog-container">
            <Grid item xs={6} className="users-dialog-content">
              <Typography>
                Suba la lista de deportistas inscritos de su club, a su derecha se encuentra el
                archivo con el csv para rellenar con los datos de los usuarios. <br /> La fecha debe
                estar en formato <strong>dd/mm/yyyy</strong>
              </Typography>
              <CsvReader setIsFile={setIsFile} setCSVData={setCSVData} />
            </Grid>
            <Grid item xs={6} className="users-dialog-content">
              <Typography className="user-dialog-content-text">Vista previa:</Typography>
              <img
                src="/images/examples/users-csv.svg"
                alt="csv preview"
                className="csv-preview-image"
              />
              <Button
                className="button-accepts"
                startIcon={<Download />}
                onClick={handleDownloadCSV}
              >
                Descargar CSV
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <Grid>
          <List>
            <ListItem>
              <ListItemText primary="Problemas en la creación de usuarios:" />
            </ListItem>
            {creationProblems.map((problem, index) => (
              <ListItem key={`${index}-${problem}`}>
                <ListItemText primary={problem} />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      <DialogActions className="dialog-actions">
        {!showProblems ? (
          <>
            <Button type="submit" className="button-cancel" onClick={onClose}>
              CANCELAR
            </Button>
            <Button
              type="submit"
              className="button-accept"
              onClick={handleSendUsers}
              disabled={!isFile || isLoading}
            >
              {isLoading ? "Cargando..." : "CONFIRMAR"}
            </Button>
          </>
        ) : (
          <Button type="submit" className="button-accept" onClick={onClose}>
            Cerrar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateUsersDialog;
