import { Cancel } from "@mui/icons-material";
import { FormControl, Grid, SelectChangeEvent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import { TEventStock } from "../../../utils/schemas/event";
import { TInscription } from "../../../utils/schemas/inscriptions";
import Button from "../../button/Button";
import SelectInput from "../../selectInput/SelectInput";
import "./change-category.scss";
import { changeCategory } from "../../../context/forms/inscriptionActions";
import { toastMessageError, toastMessageSuccess } from "../../common/toastMessage";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  inscription: TInscription | undefined;
  stockCategories: TEventStock[] | undefined | null;
  refetchInscriptions: () => void;
};

function ChangeCategory({
  isOpen,
  onClose,
  inscription,
  stockCategories,
  refetchInscriptions,
}: Props) {
  const { categories } = useAppSelector((state) => state.formsOptions);
  const [newCategory, setNewCategory] = useState<string>("");
  const [isCategoryFull, setIsCategoryFull] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleSubmitWeight = async () => {
    const changeResponse = await dispatch(
      changeCategory({
        data: { newCategoryId: newCategory },
        id: inscription?.id || "",
      })
    );
    if (changeResponse.payload === "success") {
      onClose();
      setNewCategory("");
      refetchInscriptions();
      toastMessageSuccess("Categoría cambiada correctamente");
    } else {
      toastMessageError(changeResponse.payload ?? "Error al cambiar la categoría");
    }
  };

  const handleChangeCategory = (event: SelectChangeEvent<unknown>) => {
    setNewCategory(event.target.value as string);
    stockCategories?.forEach((stock) => {
      if (stock.categoryId === event.target.value && stock.stock === 0) {
        setIsCategoryFull(true);
      }
    });
  };

  //Filter categories by event stock
  const filteredCategories =
    categories?.filter((category) =>
      stockCategories?.find((stock) => stock.categoryId === category.id)
    ) || [];

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
        setNewCategory("");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="category-dialog-title">Cambio de categoría</DialogTitle>
      <DialogContent>
        <DialogContentText className="category-dialog-text">
          ¿Estas seguro que deseas cambiar de categoría ?
        </DialogContentText>
        <Grid container columnSpacing={4} whiteSpace="nowrap" paddingInline={4}>
          <Grid item xs={6} className="actual-category-container">
            <Typography>Categoría actual: {inscription?.categoryWeight}</Typography>
          </Grid>
          <Grid item xs={6} className="new-category-container">
            <Typography>Nueva categoría:</Typography>
            <FormControl>
              <SelectInput
                value={newCategory}
                rounded="md"
                minWidth="sm"
                onChange={handleChangeCategory}
                options={[
                  ...filteredCategories
                    .filter((category) => category.genre === inscription?.genre)
                    .map((category) => ({
                      value: category.id,
                      label: category.weight,
                    })),
                ]}
              ></SelectInput>
            </FormControl>
          </Grid>
          {isCategoryFull && (
            <Grid xs={12} item className="reserve-text-container">
              <Typography className="reserve-text">
                <Cancel />
                La categoría que ha seleccionado esta completa, pasara a una lista de reserva y en
                el caso de que se libere una plaza se adjudicara de manera automática y recibirá una
                notificación.
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className="category-dialog-actions">
        <Button
          type="submit"
          className="category-button-cancel"
          onClick={() => {
            onClose();
            setNewCategory("");
          }}
        >
          CANCELAR
        </Button>
        <Button
          type="submit"
          className="category-button-accept"
          onClick={handleSubmitWeight}
          disabled={newCategory === ""}
        >
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeCategory;
